import React, { useState, useEffect, useRef, useContext } from "react";
import "./CustomSelect.css"; // Import the CSS file for styling
import { UserContext } from "context";
import { RxCross2 } from "react-icons/rx";
import { IoPencilSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";

const CustomSelect = ({
  name,
  options,
  onSelect,
  placeholder,
  value,
  error,
  focus,
  error_class = "",
  id = Math.random() * 1000,
  label,
  flotingLabel,
  disabled,
  isAddButton,
  addButtonAction,
  dynamicIndex,
  tableIndex,
  field,
  isDropdownTop,
  openList = true,
  disableSearch = false,
  labelRedStar = false,
  isClearOption = false,
  isClearHandleChange,
  onEdit,
  component_class = "",
  enableInfiniteScroll = false,
  fetchMoreData,
  hasMore,
  errorShow=true
}) => {
  const { masterData } = useContext(UserContext);
  const invalid = !!(focus && error);
  const valid = !!Boolean(focus && !error);
  const [searchQuery, setSearchQuery] = useState(
    value ? value : options[0]?.label || ""
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedValue, setSelectedValue] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
    setSearchQuery("");
  }, [value]);

  useEffect(() => {
    setFilteredOptions(
      options?.filter(
        (option) =>
          typeof searchQuery === "string" && // Check if searchQuery is a string
          option?.label?.toLowerCase()?.includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toString(); // Convert input value to string
    setSearchQuery(inputValue);
    setShowDropdown(true);
  };

  const handleOptionClick = (option, index, label, tableIndex, field) => {
    onSelect(option, index, label, tableIndex, field);
    // setSelectedValue(option);
    setSearchQuery("");
    setShowDropdown(false);
  };

  const handleRestectChange = (e) => {
    setSelectedValue(selectedValue);
  };

  const handleaddButtonAction = (e) => {
    setShowDropdown(false);
    addButtonAction(error_class);
  };

  useEffect(() => {
    if (id) {
      const element = document && document?.getElementById(id);

      const handleMouseDown = (e) => {
        if (e?.detail > 1) {
          // f
          e?.preventDefault();
        }
      };

      if (element) {
        element.addEventListener("mousedown", handleMouseDown);
      }

      return () => {
        if (element) {
          element.removeEventListener("mousedown", handleMouseDown);
        }
      };
    }
  }, [id]);

  const reqfetchMoreData = () => {
    console.log("is working")
    fetchMoreData()
  }

  return (
    <div
      className={
        error
          ? "custom-select-container custom-select_eror"
          : "custom-select-container"
      }
      ref={dropdownRef}
    >
      <div className="input-wrapper">
        <input
          id={id}
          type="text"
          placeholder={placeholder}
          value={selectedValue?.label}
          name={name}
          onChange={handleRestectChange}
          readOnly={true}
          disabled={disabled}
          index={dynamicIndex}
          onClick={() => setShowDropdown(!showDropdown)}
        />
        {isClearOption && selectedValue?.label != "All" && (
          <span className="clear-icon" onClick={isClearHandleChange}>
            <RxCross2 size={20} color="black" />
          </span>
        )}
      </div>
      <label className="_fltLabel">
        <span>
          {label ? label : flotingLabel}
          {labelRedStar && <span className="requiredLable"> * </span>}
        </span>
      </label>
      {/* Dropdown Start */}
      {showDropdown && (
        <React.Fragment>
          {openList && (
            <ul
              className={
                isDropdownTop
                  ? "custom-dropdown-menu cus-topDrop"
                  : "custom-dropdown-menu"
              }
            >
              {!disableSearch && (
                <li onClick={() => setShowDropdown(!showDropdown)}>
                  <div
                    className="dropSearch_bx"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={handleInputChange}
                      autoFocus={true}
                    />
                  </div>
                </li>
              )}
              <li className="mapList_views">

                {enableInfiniteScroll ? (
                  <InfiniteScroll
                    dataLength={filteredOptions.length}
                    next={reqfetchMoreData}
                    hasMore={hasMore}
                    loader={<p className="text-center">Loading...</p>}
                    // height={230}
                    // height={filteredOptions.length>4?230:230/filteredOptions.length}
                    // height={Math.min(filteredOptions.length * 46, 230)}
                    height={filteredOptions.length < 4 ? filteredOptions.length * 46 : 230}
                    // endMessage={<p style={{ textAlign: 'center' }}>No More</p>}
                  >
                    <ul>
                      {filteredOptions?.length > 0 ? (
                        filteredOptions?.map((option, index) => (
                          <>
                            <li
                              className={`_anvRo_locateBx ${selectedValue?.label === option.label
                                ? "_vhlActive"
                                : ""
                                } ${(selectedValue?.value === option.value && component_class === "vehicle_number_trip") ? '_vhlActive' : ''
                                }`}
                              key={`${option.value}_${index}`}
                              onClick={() =>
                                handleOptionClick(
                                  option,
                                  dynamicIndex,
                                  label,
                                  tableIndex,
                                  field
                                )
                              }
                            >
                              {label === "Vehicle Number" ? (
                                <React.Fragment>
                                  <div className="anvLrc ">
                                    <div className="_vhlNumber">{option.label}</div>
                                    <div className="_vhlOwnType">
                                      <span
                                        className={
                                          option?.vehicle_ownership == 1
                                            ? "_trkMark _lrDelivered_status"
                                            : option?.vehicle_ownership == 2
                                              ? "_trkMark _lrPartyPoint_status"
                                              : "_trkMark _lrInTransit_status"
                                        }
                                      >
                                        {
                                          masterData?.vehicle_ownership[
                                          option?.vehicle_ownership
                                          ]
                                        }
                                      </span>
                                      {option.lorry_type_id?.lorry_type && option.vehicle_type_id_1?.name
  ? ` | ${option.lorry_type_id.lorry_type} ${option.vehicle_type_id_1.name}`
  : ''}
                                    </div>
                                  </div>
                                  <div className="_vhlStatus">
                                    <span className="available_status"></span>

                                    {`${masterData?.vehicle_status[
                                      option?.vehicle_status
                                    ]
                                      }`}
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div className="anvLrc_edit">
                                  <a>{option.label}</a>

                                  {onEdit && <div className="po_button_align">
                                    <button
                                      className="__edit_btn_po"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onEdit(option)
                                      }}
                                    >
                                      <FiEdit />
                                    </button>
                                  </div>}
                                </div>
                              )}
                            </li>
                          </>
                        ))
                      ) : (
                        <li
                          className="_anvRo_locateBx"
                          style={{ fontSize: "13px" }}
                        >
                          No Result Found
                        </li>
                      )}
                    </ul>
                  </InfiniteScroll>) :
                  <ul>
                    {filteredOptions?.length > 0 ? (
                      filteredOptions?.map((option, index) => (
                        <>
                          <li
                            className={`_anvRo_locateBx ${selectedValue?.label === option.label
                              ? "_vhlActive"
                              : ""
                              } ${(selectedValue?.value === option.value && component_class === "vehicle_number_trip") ? '_vhlActive' : ''
                              }`}
                            key={`${option.value}_${index}`}
                            onClick={() =>
                              handleOptionClick(
                                option,
                                dynamicIndex,
                                label,
                                tableIndex,
                                field
                              )
                            }
                          >
                            {label === "Vehicle Number" ? (
                              <React.Fragment>
                                <div className="anvLrc ">
                                  <div className="_vhlNumber">{option.label}</div>
                                  <div className="_vhlOwnType">
                                    <span
                                      className={
                                        option?.vehicle_ownership == 1
                                          ? "_trkMark _lrDelivered_status"
                                          : option?.vehicle_ownership == 2
                                            ? "_trkMark _lrPartyPoint_status"
                                            : "_trkMark _lrInTransit_status"
                                      }
                                    >
                                      {
                                        masterData?.vehicle_ownership[
                                        option?.vehicle_ownership
                                        ]
                                      }
                                    </span>
                                    {` | ${option.lorry_type_id?.lorry_type} ${option.vehicle_type_id_1?.name}`}
                                  </div>
                                </div>
                                <div className="_vhlStatus">
                                  <span className="available_status"></span>

                                  {`${masterData?.vehicle_status[
                                    option?.vehicle_status
                                  ]
                                    }`}
                                </div>
                              </React.Fragment>
                            ) : (
                              <div className="anvLrc_edit">
                                <a>{option.label}</a>

                                {onEdit && <div className="po_button_align">
                                  <button
                                    className="__edit_btn_po"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onEdit(option)
                                    }}
                                  >
                                    <FiEdit />
                                  </button>
                                </div>}
                              </div>
                            )}
                          </li>
                        </>
                      ))
                    ) : (
                      <li
                        className="_anvRo_locateBx"
                        style={{ fontSize: "13px" }}
                      >
                        No Result Found
                      </li>
                    )}
                  </ul>}


              </li>
              {isAddButton ? (
                <li className="mt-1 _lineDrp anvLrc">
                  <button
                    className="_addNew_cdmBtn"
                    onClick={handleaddButtonAction}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                        fill="#1956F6"
                      />
                    </svg>
                    Add New
                  </button>
                </li>
              ) : (
                <></>
              )}
            </ul>
          )}
        </React.Fragment>
      )}
      {/* Dropdown End */}
     {errorShow && <>
     {error && (
        <span className={"input_drop_error" + error_class}>{error}</span>
      )}
     </>}
    </div>
  );
};

export default CustomSelect;
