import {
  addIndentApi,
  closePlanApi,
  deletePlanApi,
  getIndentDetailsApi,
  getMarketVisiblityApi,
  getMaterialApi,
  handleKeyDown,
  indentUpdateApi,
} from "_services";
import { getLorryTypeList } from "_services/accountsServices/lrServices";
import {
  getBranchDropdownListData,
  getComapnyUser,
  getConsignorDropdownListData,
  getPlanResponseApi,
} from "_services/dropdownServices/dropdownService";
import { CommonDatePicker, CommonInput, CustomSelect } from "components";
import { UserContext } from "context";
import { State } from "country-state-city";
import { errorSchema, utils } from "helper";
import AddPoModal from "modals/AddPoModal";
import ConfirmModalFor from "modals/ConfirmModalFor";
import { Checkbox } from "pages/User/AddUser";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit } from "react-icons/fi";
import StorageService from "utils/StorageServices/StorageServices";

const AddIndentModal = (props) => {
  const initialForm = {
    company_id: StorageService.getCompanyId(),
    indent_number: "",
    indent_date: new Date(),
    indent_expiry_date: new Date().setDate(new Date().getDate() + 2),
    consignor_id: "",
    lorry_type_id: "",
    vehicle_type_id: "",
    weight: "",
    material_type_id: "",
    from_location: [],
    to_location: [],
    freight_type: 4,
    freight_rate: "",
    freight_amount: "",
    payment_terms: 1,
    payment_percentage: "",
    branch_id: "",
    assign_to: "",
    is_allow_visibility: 0,
    connection_id: "",
    is_allow_calls: 0,
    responses: 1,
  };

  const initialFrom = {
    from_city: "",
    from_state: "",
    lat: "",
    long: "",
  };
  const initialTo = {
    to_city: "",
    to_state: "",
    lat: "",
    long: "",
    po_list: [],
  };
  const { masterData, cities, modalZIndex } = useContext(UserContext);
  const [showAddPo, setShowAddPo] = useState({
    title: "",
    open: false,
  });
  const { setModalZIndex } = useContext(UserContext);
  const [form, setForm] = useState(initialForm);
  const [fromLoaction, setFromLocation] = useState([initialFrom]);
  const [toLoaction, setToLocation] = useState([initialTo]);
  const [formError, setFormError] = useState("");
  const [toIndex, setToIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState({
    consignorList: [],
    lorryTypeList: [],
    vehicleTypeList: [],
    freightTypeList: [],
    branchList: [],
    userList: [],
    marketVisiList: [],
    materialList: [],
    responseList: [],
  });
  const [poDetails, setPoDetails] = useState({
    mainIn: "",
    innerIn: "",
    details: "",
  });
  const [disableValue, setDisableValue] = useState(true)
  const [showBackModal, setShowBackModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [planStatus, setPlanStatus] = useState("")
  const [isProgrammaticUpdate, setIsProgrammaticUpdate] = useState(false);
  const paymentTermsList = [
    {
      label: "Advance",
      value: 1,
    },
    {
      label: "To Pay",
      value: 2,
    },
  ];

  const paymentPer = [
    { label: "90%", value: 90 },
    { label: "80%", value: 80 },
    { label: "70%", value: 70 },
  ];

  let { isOpen, onClose, id } = props;

  const handleAddPo = (index) => {
    setShowAddPo({
      ...showAddPo,
      title: "add",
      open: true,
    });
    setModalZIndex(999);
    setToIndex(index);
  };

  useEffect(() => {
    if (id) {
      fetchData();
     
    } else {
      fetchData();
    }
  }, [isOpen]);

  useEffect(()=>{
    if(listData && id){
      getIndentDetails(id);
    }
   
  },[listData])



  useEffect(() => {
    if (!showAddPo) {
      setModalZIndex("");
    }
  }, [showAddPo]);

  useEffect(() => {
    if (form?.is_allow_visibility !== undefined) {
      handleOnChange(
        "connection_id",
        form?.is_allow_visibility === 0
          ? ""
          : listData?.marketVisiList[0]?.value
      );
    }

    if (form?.payment_terms !== undefined && form?.payment_terms != 1) {
      handleOnChange("payment_percentage", "");
    }

    if (form?.freight_type !== undefined) {
      if (form?.freight_type == 4) {
        handleOnChange("freight_rate", "");
      } else {
        handleOnChange("freight_amount", "");
      }
    }

    if (form?.indent_date) {
      handleOnChange("indent_expiry_date", addDays(form?.indent_date, 2));
    }
  }, [
    form?.is_allow_visibility,
    form?.payment_terms,
    form?.freight_type,
    form?.indent_date,
  ]);

  const fetchData = async () => {
    try {
      // setLoading(true);
      const [
        consignorRes,
        lorryTypeRes,
        branchRes,
        userRes,
        marketRes,
        materialRes,
      ] = await Promise.all([
        getDropdownDataConsignorList(),
        getDataLorryTypeList(),
        getBranchList(),
        getComapnyUserList(),
        getMarketList(),
        getMaterialList(),
      ]);

      const consignorList = formatList(
        consignorRes?.data?.consignor_list,
        "name",
        "_id",
        "Self"
      );
      const lorryTypeList = formatList(
        lorryTypeRes?.data?.lorry_type,
        "lorry_type",
        "_id"
      );
      const branchList = formatList(
        branchRes?.data?.branch_list,
        "branch",
        "_id"
      );
      const userList = formatList(userRes, "label", "value");
      const marketVisiList = formatList(
        marketRes?.data,
        "name",
        "_id",
        "All Transport 360 Members"
      );
      const materialList = formatList(materialRes?.data, "name", "_id");

      setListData({
        consignorList,
        lorryTypeList,
        vehicleTypeList: [],
        freightTypeList: formatList(
          masterData?.freight_type_list,
          "name",
          "id"
        ).filter((item) => [6, 4].includes(item.value)),
        branchList,
        userList,
        marketVisiList,
        materialList,
        responseList: formatList(masterData?.plan_responses_list, "name", "id"),
      });

      if (!id) {
        setInitialFormValues(
          lorryTypeList,
          branchList,
          userList,
          consignorList,
          materialList
        );
      }


      // setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const formatList = (list, labelKey, valueKey, defaultOption = null) => {
    const formattedList = list?.map((item) => ({
      ...item,
      label: item[labelKey],
      value: item[valueKey],
    }));
    return defaultOption
      ? [{ label: defaultOption, value: "" }, ...formattedList]
      : formattedList;
  };

  const setInitialFormValues = (
    lorryTypeList,
    branchList,
    userList,
    consignorList,
    materialList
  ) => {
    handleOnChange("lorry_type_id", lorryTypeList?.[0]?.value || "");
    handleOnChange("branch_id", branchList?.[0]?.value || "");
    handleOnChange("assign_to", userList?.[0]?.value || "");
    handleOnChange("consignor_id", consignorList?.[0]?.value || "");
    handleOnChange("material_type_id", materialList?.[0]?.value || "");
  };

  useEffect(() => {
    if (form?.lorry_type_id) {
      const findLorry = listData.lorryTypeList?.find(
        (i) => i.value === form.lorry_type_id
      );
      if (findLorry) {
        const vehicleTypeList = formatList(
          findLorry.props[0]?.values,
          "name",
          "_id"
        );
        setListData((prev) => ({ ...prev, vehicleTypeList }));
        handleOnChange("vehicle_type_id", vehicleTypeList?.[0]?.value || "");
      }
    }
  }, [form?.lorry_type_id, listData.lorryTypeList]);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const commonFetchFuction = async (
    apiMethod,
    payload = {},
    transformer = (res) => res
  ) => {
    try {
      const res = await apiMethod(payload);
      if (res?.responseCode === 200) {
        return transformer(res);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  
  const getIndentDetails = async (id) => {
    try {
      setIsProgrammaticUpdate(true)
      const payload = {
        company_id: StorageService?.getCompanyId(),
        plan_id: id,
      };

      const res = await commonFetchFuction(getIndentDetailsApi, payload);

      if (res?.responseCode === 200) {
        const details = res?.data?.plan;

        handleOnChange("lorry_type_id", details?.lorry_type_id?._id || "");
        setPlanStatus(details?.plan_status)

      

        setTimeout(() => {
          const fields = {
            company_id: details?.company_id,
            indent_date: new Date(details?.indent_date) || new Date(),
            indent_expiry_date: new Date(details?.indent_expiry_date) || new Date(),
            indent_number: details?.indent_number || "",
            consignor_id: details?.consignor_id?._id || "",
            vehicle_type_id: details?.vehicle_type_id?._id || "",
            weight: details?.weight || "",
            material_type_id: details?.material_type_id?._id || "",
            freight_type: details?.freight_type || "",
            freight_amount: details?.freight_amount || "",
            freight_rate: details?.freight_rate || "",
            payment_terms: details?.payment_terms ? details?.payment_terms : 1,
            payment_percentage: details?.payment_percentage || "",
            branch_id: details?.branch_id?._id || "",
            assign_to: details?.assign_to?._id || "",
            is_allow_visibility: details?.is_allow_visibility ? details?.is_allow_visibility : 0,
            connection_id: details?.connection_id || "",
            is_allow_calls: details?.is_allow_calls ? details?.is_allow_calls : 0,
            responses: details?.responses || "",
          };

          Object.entries(fields).forEach(([key, value]) =>
            handleOnChange(key, value)
          );

          setFromLocation(details?.from_location?.length ? details?.from_location : [initialFrom]);

          setToLocation(
            details?.to_location?.map((i) => ({
              ...i,
              po_list: i.po_list?.map((k) => ({
                ...k,
                from_location: k?.from_location,
                from_state: k?.from_state,
                po_date: k?.po_date,
                po_number: k?.po_number,
                to_location: k?.to_location,
                to_state: k?.to_state,
                consignee_id: k?.consignee_id?._id,
                unit_id: k?.unit_id?._id,
                unit_details: k?.unit_id,
                consignee_detials: k?.consignee_id,
              })),
            }))
          );
        }, 0);
        setIsProgrammaticUpdate(false)
      }
    } catch (error) {
      console.error("Error fetching indent details:", error);
    }
  };
  const getDropdownDataConsignorList = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };
    return commonFetchFuction(getConsignorDropdownListData, payload);
  };

  const getDataLorryTypeList = async () => {
    return commonFetchFuction(getLorryTypeList);
  };

  const getMarketList = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      keyword: "",
    };
    return commonFetchFuction(getMarketVisiblityApi, payload);
  };

  const getMaterialList = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
    };
    return commonFetchFuction(getMaterialApi, payload);
  };

  const getBranchList = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };
    return commonFetchFuction(getBranchDropdownListData, payload);
  };

  const getComapnyUserList = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };
    const transformer = (res) =>
      res?.data?.user_list?.map((el) => ({
        ...el,
        label: el?.company_user_name,
        value: el?.user_id?._id,
      }));

    return commonFetchFuction(getComapnyUser, payload, transformer);
  };

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
              toLoction: toLoaction,
              fromLoction: fromLoaction,
            },
            errorSchema.addPostIndentSchima
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleInputChange = async (value, index, label, tableIndex) => {
    if (label === "From City") {
      const stateDetails = State.getStateByCodeAndCountry(
        value?.stateCode,
        value?.countryCode
      );
      const NewFromLocation = [...fromLoaction];

      NewFromLocation[index].from_city = value.name;
      NewFromLocation[index].from_state = stateDetails.name;
      NewFromLocation[index].lat = value?.latitude;
      NewFromLocation[index].long = value?.longitude;

      setFromLocation(NewFromLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...form, toLoction: toLoaction, fromLoction: NewFromLocation },
          errorSchema.addPostIndentSchima
        );

        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }

    if (label === "To City") {
      const stateDetails = State.getStateByCodeAndCountry(
        value?.stateCode,
        value?.countryCode
      );
      const NewToLocation = [...toLoaction];

      NewToLocation[index].to_city = value.name;
      NewToLocation[index].to_state = stateDetails.name;
      NewToLocation[index].lat = value?.latitude;
      NewToLocation[index].long = value?.longitude;

      setToLocation(NewToLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...form, toLoction: NewToLocation, fromLoction: fromLoaction },
          errorSchema.addPostIndentSchima
        );

        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }
  };

  const addFromInput = (e) => {
    setFromLocation((preState) => {
      return [...preState, initialFrom];
    });
  };

  const addToInput = () => {
    setToLocation((preState) => {
      return [...preState, initialTo];
    });
  };

  const callBackPo = async (poData) => {
    if (showAddPo?.title == "add") {
      const updatedLocations = [...toLoaction];
      updatedLocations[toIndex].po_list.push(poData);
      setToLocation(updatedLocations);
    } else {

      const updatedLocations = [...toLoaction];
      updatedLocations[poDetails?.mainIn].po_list[poDetails?.innerIn] = poData;
      setToLocation(updatedLocations);
      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...form, toLoction: updatedLocations, fromLoction: fromLoaction },
          errorSchema.addPostIndentSchima
        );

        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }
  };

  const handleDeletePo = (index, poIndex) => {
    const updatedLocations = [...toLoaction];
    updatedLocations[index]?.po_list?.splice(poIndex, 1);
    setToLocation(updatedLocations);
  };

  const handleRemovePickup = (index) => {
    const updatedLocations = [...fromLoaction];
    updatedLocations.splice(index, 1);
    setFromLocation(updatedLocations);
  };

  const handleRemoveDrop = (index) => {
    const updatedLocations = [...toLoaction];
    updatedLocations.splice(index, 1);
    setToLocation(updatedLocations);
  };

  const handleEditPo = (po, index, poIndex) => {
    setPoDetails({
      ...poDetails,
      mainIn: index,
      innerIn: poIndex,
      details: po,
    });

    setShowAddPo({
      ...showAddPo,
      title: "edit",
      open: true,
    });
  };

  const clearAll = () => {
    setForm(initialForm);
    setDisableValue(true)

  };

  const submitLeaveModalProps = () => {
    clearAll()
    onClose(!isOpen);
  };

  let addPoModal = {
    isPoShow: showAddPo,
    closeModal: (e) =>
      setShowAddPo({
        ...showAddPo,
        open: false,
      }),
    modalMainClass: "_dashCommon_Modal _poModal ",
    modalTitle: showAddPo?.title == "add" ? "Add PO" : "Edit PO",
    footerPart: true,
    submitTitle: showAddPo?.title == "add" ? "Add PO" : "Update PO",
    callBackPo: callBackPo,
    fromLoaction: fromLoaction,
    toLoaction: toLoaction,
    toIndex: toIndex,
    modalZIndex: modalZIndex,
    poDetails: poDetails,
  };



  const handleSubimitPost = async () => {
    const removePoList = toLoaction?.map(({ po_list, ...rest }) => rest);
    const listOfloaction = [...fromLoaction, ...removePoList]?.map((i) => {
      return {
        city: i?.from_city ? i?.from_city : i?.to_city,
        state: i?.from_state ? i?.from_state : i?.to_state,
        lat: i?.lat,
        long: i?.long,
      };
    });

    const updatedList = toLoaction?.map(({ _id, consignee_code, ...item }) => ({
      ...item,
      po_list: item.po_list?.map((po) => {
        const { unit_details, consignee_detials, ...rest } = po;
        return rest;
      }),
    }));

    const updatedTolocation = toLoaction?.map(({ _id, consignee_code, ...item }) => ({
      ...item,
      id: id,
    }));

    const payload = {
      ...form,
      indent_date:utils.convertToISO(form?.indent_date,true),
      indent_expiry_date:utils.convertToISO(form?.indent_expiry_date,true),
      from_location: fromLoaction,
      to_location: updatedList,
      // location: listOfloaction,
      // indent_expiry_date: new Date(form?.indent_date),
      ...(id && { plan_id: id })
    };

    const validationResult = await utils.checkFormError(
      {
        ...form,

        toLoction: id ? updatedTolocation : toLoaction,
        fromLoction: fromLoaction,
      },
      errorSchema.addPostIndentSchima,

    );
// console.log(validationResult)
    if (validationResult == true) {
      setLoading(true);
      try {
        const res = id ? await indentUpdateApi(payload) : await addIndentApi(payload);


        if (res?.responseCode == 200) {
          setLoading(true);
          onClose(!isOpen);
          clearAll();
          props.callList();
          setModalZIndex('')
        } else {
          setLoading(false);
          toast.error(res?.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    } else {
      setFormError(validationResult);
    }
  };

  const handleEditIndet = () => {
    setDisableValue(false)
  }

  const handleCloseIndent = async () => {
    setLoading(true)
    try {
      const res = await closePlanApi({ plan_id: showCloseModal });

      if (res?.responseCode == 200) {
        setLoading(false);
        onClose(!isOpen);
        clearAll();
        props.callList();
        toast.success(res?.responseMessage);
      } else {
        setLoading(false);
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  }

  const handleDeleteIndent = async () => {
    setLoading(true)
    try {
      const res = await deletePlanApi({ company_id:StorageService.getCompanyId(),plan_id: showCloseModal });

      if (res?.responseCode == 200) {
        setLoading(false);
        onClose(!isOpen);
        clearAll();
        props.callList();
        toast.success(res?.responseMessage);
      } else {
        setLoading(false);
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  }

  const backModalProps = {
    show: showBackModal,
    closeModal: (e) => setShowBackModal(false),
    title: "Cnacle Edit",
    submitTitle: "Confirm",
    onSubmit: submitLeaveModalProps,
    confirmModal: "confirmModal",
  };

  const closeModalProps = {
    show: showCloseModal,
    closeModal: (e) => setShowCloseModal(false),
    title: "Close Indent",
    submitTitle: "Confirm",
    onSubmit: handleCloseIndent,
    confirmModal: "confirmModal",
    disabled: loading,
    isLoadding: loading
  };

  const deleteModalProps = {
    show: showDeleteModal,
    closeModal: (e) => setShowDeleteModal(false),
    title: "Delete Indent",
    submitTitle: "Confirm",
    onSubmit: handleDeleteIndent,
    confirmModal: "confirmModal",
    disabled: loading,
    isLoadding: loading
  };

  return (
    <>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Add Indent</h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            onClose(!isOpen);
            clearAll();
            setModalZIndex('')
          }}
        ></button>
      </div>

      <div className="offcanvas-body">
        <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
          <div className="_lrdMax">
            <div className="flxInr">
              <div className="halfDiv">
                <CommonDatePicker
                  name="indent_date"
                  label="Indent Date"
                  startDate={form?.indent_date}
                  setStartDate={(data) => {
                    handleOnChange("indent_date", data);
                    // setStartDate(data);
                  }}
                  error={formError.indent_date}
                  value={form.indent_date}
                  disabled={id ? disableValue : false}
                />
              </div>

              <div className="halfDiv">
                <CommonDatePicker
                  name="indent_expiry_date"
                  label="Indent Expiry Date"
                  startDate={form?.indent_expiry_date}
                  setStartDate={(data) => {
                    handleOnChange("indent_expiry_date", data);
                    // setStartDate(data);
                  }}
                  error={formError.indent_expiry_date}
                  value={form.indent_expiry_date}
                  minDate={form?.indent_expiry_date}
                  disabled={id ? disableValue : false}
                />
              </div>

              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Indent Number"
                  placeholder="Indent Number"
                  name="indent_number"
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  error={formError.indent_number}
                  value={form.indent_number}
                  disabled={id ? disableValue : false}
                />
                <p className="_bottomPLine">Leave Blank to Auto Generate</p>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.consignorList}
                  label="Select Party"
                  placeholder="Select Party"
                  onSelect={(data) => {
                    handleOnChange("consignor_id", data?.value);
                  }}
                  error={formError.consignor_id}
                  value={listData?.consignorList?.find(
                    (i) => i?.value == form.consignor_id
                  )}
                  disabled={id ? disableValue : false}
                />
                <p className="_bottomPLine">
                  Visible Internally. Not visible to Others
                </p>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.lorryTypeList}
                  label="Select Fleet"
                  placeholder="Select Fleet"
                  onSelect={(data) => {
                    handleOnChange("lorry_type_id", data?.value);
                  }}
                  error={formError.lorry_type_id}
                  value={listData?.lorryTypeList?.find(
                    (i) => i?.value == form.lorry_type_id
                  )}
                  disabled={id ? disableValue : false}
                />
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.vehicleTypeList}
                  label="Fleet Type"
                  placeholder="Select Fleet Type"
                  onSelect={(data) => {
                    handleOnChange("vehicle_type_id", data?.value);
                  }}
                  error={formError.vehicle_type_id}
                  value={listData?.vehicleTypeList?.find(
                    (i) => i?.value == form.vehicle_type_id
                  )}
                  disabled={id ? disableValue : false}
                />
              </div>

              <div className="halfDiv _relatePos">
                <CommonInput
                  type="text"
                  label="Weight"
                  placeholder="Weight"
                  name="weight"
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  value={form?.weight}
                  onKeyDown={handleKeyDown}
                  error={formError.weight}
                  disabled={id ? disableValue : false}
                />

                <span className='_weightSpan' > Ton </span>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.materialList}
                  label="Material"
                  placeholder="Select Material"
                  name="material_type_id"
                  onSelect={(data) => {
                    handleOnChange("material_type_id", data?.value);
                  }}
                  value={listData?.materialList?.find(
                    (i) => i?.value == form?.material_type_id
                  )}
                  error={formError?.material_type_id}
                  disabled={id ? disableValue : false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="_sltIndent_bx" style={{ marginBottom: "9px" }}>
          <div className="_sltHead">
            <h4>Select Location</h4>
          </div>

          <div className="container-fluid _verLine_cts">
            <div
              className="row _btmRow_cts mt-0 pb-0 "
              style={{ zIndex: "10" }}
            >
              {fromLoaction?.map((data, index1) => {
                return (
                  <div className="col-md-12">
                    <div className="_addRouts_flex">
                      <div className="_cityInput_bx">
                        <CustomSelect
                          label="From City"
                          component_class="vehicle_number_trip"
                          options={cities}
                          dynamicIndex={index1}
                          onSelect={handleInputChange}
                          value={{
                            label: data?.from_city
                              ? `${data?.from_city}, ${data?.from_state}`
                              : "",
                            value: `${data?.from_city}`,
                          }}
                          error={
                            `${formError[`fromLoction[${index1}].from_city`]
                              }` !== "undefined"
                              ? `${formError[`fromLoction[${index1}].from_city`]
                              }`
                              : ""
                          }
                          disabled={id ? disableValue : false}
                        />
                      </div>

                      <div className="_addDel_bx">
                        {!fromLoaction[index1]?.from_city ? (
                          <button className="_pickN_dropBtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                fill="#1956F6"
                              ></path>
                            </svg>{" "}
                            <span>Add Pickup</span>
                          </button>
                        ) : (
                          <button
                            className="_pickN_dropBtn"
                            onClick={addFromInput}
                            disabled={id ? disableValue : false || fromLoaction.some(
                              (item) => item.from_city == ""
                            )}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                fill="#1956F6"
                              />
                            </svg>{" "}
                            <span>Add Pickup</span>
                          </button>
                        )}
                        {fromLoaction?.length > 1 && (
                          <button
                            className="_deleteBtn"
                            onClick={() => handleRemovePickup(index1)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                fill="#C41F1F"
                              ></path>
                            </svg>
                            <span>Delete</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {toLoaction?.map((data, index) => {
                return (
                  <>
                    <div className="col-md-12">
                      <div className="_addRouts_flex">
                        <div className="_cityInput_bx">
                          <CustomSelect
                            label="To City"
                            component_class="vehicle_number_trip"
                            options={cities}
                            dynamicIndex={index}
                            onSelect={handleInputChange}
                            value={{
                              label: data?.to_city
                                ? `${data?.to_city}, ${data?.to_state}`
                                : "",
                              value: `${data?.to_city}`,
                            }}
                            error={
                              `${formError[`toLoction[${index}].to_city`]}` !==
                                "undefined"
                                ? `${formError[`toLoction[${index}].to_city`]}`
                                : ""
                            }
                            disabled={id ? disableValue : false}
                          />
                        </div>

                        <div className="_addDel_bx">
                          {!toLoaction[index]?.to_city ? (
                            <button className="_pickN_dropBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                  fill="#1956F6"
                                />
                              </svg>{" "}
                              <span>Add Drop</span>
                            </button>
                          ) : (
                            <button
                              className="_pickN_dropBtn"
                              onClick={addToInput}
                              disabled={id ? disableValue : false || toLoaction.some(
                                (item) => item.to_city === ""
                              )}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                  fill="#1956F6"
                                />
                              </svg>{" "}
                              <span>Add Drop</span>
                            </button>
                          )}

                          {toLoaction?.length > 1 && (
                            <button
                              className="_deleteBtn"
                              onClick={() => handleRemoveDrop(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                  fill="#C41F1F"
                                ></path>
                              </svg>
                              <span>Delete</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {data?.po_list?.map((po, poIndex) => {
                        return (
                          <>
                            <div className="_poDetail_bx mb-2">
                              <ul>
                                <li>
                                  <div className="__pdxTx">PO Date</div>{" "}
                                  <div className="__pdseTx">
                                    {utils?.formateDateCalander(po?.po_date)}
                                  </div>
                                </li>
                                <li>
                                  <div className="__pdxTx">PO Number</div>{" "}
                                  <div className="__pdseTx">{po?.po_number}</div>
                                </li>
                                <li>
                                  <div className="__pdxTx">Pickup Location</div>{" "}
                                  <div>
                                    <span className="_fstAddress_tx">
                                      {po?.from_location}
                                    </span>{" "}
                                    <span className="_scdAddress_tx">
                                      {po?.unit_details
                                        ? `${po?.unit_details?.code} , ${po?.unit_details?.name}`
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="__pdxTx">Drop Location</div>{" "}
                                  <div>
                                    <span className="_fstAddress_tx">
                                      {po?.to_location}
                                    </span>{" "}
                                    <span className="_scdAddress_tx">
                                      {po?.consignee_detials
                                        ? `${po?.consignee_detials?.code} , ${po?.consignee_detials?.name}`
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                              <div>
                                <button
                                  className="__edit_btn"
                                  onClick={() => handleEditPo(po, index, poIndex)}
                                  disabled={id ? disableValue : false}
                                >
                                  <FiEdit />
                                </button>
                                <button
                                  className="__delM_btn"
                                  onClick={() => handleDeletePo(index, poIndex)}
                                  disabled={id ? disableValue : false}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                  >
                                    <path
                                      d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                      fill="#C41F1F"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <span className={"input_drop_error"}>
                              {formError[`toLoction[${index}].po_list[${poIndex}].to_location`]}
                            </span>
                          </>
                        );
                      })}

                      <div className="_addDel_bx bg-white pt-2">
                        <button
                          className="_pickN_dropBtn"
                          onClick={() => handleAddPo(index)}
                          disabled={id ? disableValue : false ||
                            !toLoaction[index].to_city ||
                            fromLoaction.some((item) => item.from_city === "")
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                              fill="#1956F6"
                            ></path>
                          </svg>{" "}
                          <span>Add PO & Location</span>
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className="pr_procssBx">
          <div className="_lrdMax">
            <div className="flxInr">
              <div className="__flxseBx">
                <h6>Freight Type</h6>

                <div className="_flxOtr">
                  {listData?.freightTypeList?.map((freight) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={freight?.value}
                        type="radio"
                        name="freight_type"
                        value={freight?.value}
                        checked={form?.freight_type == freight?.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, value)
                        }
                        disabled={id ? disableValue : false}
                      />
                      <label
                        htmlFor={freight?.value}
                        className="form-check-label"
                      >
                        {freight?.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="_cityInput_bx">
                  {form?.freight_type == 4 ? (
                    <CommonInput
                      type="text"
                      label="Freight Amount"
                      placeholder="Freight Amount"
                      name="freight_amount"
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      onKeyDown={handleKeyDown}
                      value={form?.freight_amount}
                      error={formError.freight_amount}
                      disabled={id ? disableValue : false}
                    />
                  ) : (
                    <CommonInput
                      type="text"
                      label="Freight Rate"
                      placeholder="Freight Rate"
                      name="freight_rate"
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={form?.freight_rate}
                      onKeyDown={handleKeyDown}
                      error={formError.freight_rate}
                      disabled={id ? disableValue : false}
                    />
                  )}
                </div>
              </div>

              <div className="__flxseBx">
                <h6>Payment Terms</h6>

                <div className="_flxOtr">
                  {paymentTermsList?.map((payment) => {
                    return (
                      <div className="_dualCheckr_bx">
                        <input
                          className="form-check-input"
                          id={payment?.value}
                          type="radio"
                          name="payment_terms"
                          value={payment?.value}
                          checked={form?.payment_terms == payment?.value}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          disabled={id ? disableValue : false}
                        />
                        <label
                          htmlFor={payment?.value}
                          className="form-check-label"
                        >
                          {payment?.label}
                        </label>
                      </div>
                    );
                  })}

                  {form?.payment_terms == 1 && (
                    <div className="__numPercen_btBx">
                      {paymentPer?.map((i) => (
                        <button
                          value={i?.value}
                          name="payment_percentage"
                          onClick={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          disabled={id ? disableValue : false}
                        >
                          {i?.label}
                        </button>
                      ))}
                      <>
                        <input
                          type="text"
                          name="payment_percentage"
                          className={
                            formError?.payment_percentage ? "anvInputError" : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          value={form?.payment_percentage}
                          error={formError?.payment_percentage}
                          disabled={id ? disableValue : false}
                        />
                        <div>%</div>
                        {formError?.payment_percentage && (
                          <span className={"input_drop_error"}>
                            {formError?.payment_percentage}
                          </span>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>

              <div className="_cityInput_bx">
                <CustomSelect
                  label="Branch"
                  options={listData?.branchList}
                  placeholder="Select Branch"
                  onSelect={(data) => {
                    handleOnChange("branch_id", data?.value);
                  }}
                  value={listData?.branchList?.find(
                    (i) => i?.value == form?.branch_id
                  )}
                  error={formError?.branch_id}
                  disabled={id ? disableValue : false}
                />
              </div>

              <div className="_cityInput_bx">
                <CustomSelect
                  label="Assign to"
                  options={listData?.userList}
                  onSelect={(data) => {
                    handleOnChange("assign_to", data?.value);
                  }}
                  value={listData?.userList?.find(
                    (i) => i?.value == form?.assign_to
                  )}
                  placeholder="Select"
                  error={formError?.assign_to}
                  disabled={id ? disableValue : false}
                />
              </div>

              <div className=" _cityInput_bx">
                <div className="_flxOtr d-flex justify-content-between align-items-center">
                  <h6 style={{ margin: "0" }}>Market Visiblity / Allow Bids</h6>

                  <div
                    className="__numPercen_btBx"
                    style={{ flex: "0 0 111px", margin: "0" }}
                  >
                    <button
                      className={form?.is_allow_visibility == 1 ? "active" : ""}
                      name="is_allow_visibility"
                      value={1}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                      disabled={id ? disableValue : false}
                    >
                      Yes
                    </button>
                    <button
                      className={form?.is_allow_visibility == 0 ? "active" : ""}
                      name="is_allow_visibility"
                      value={0}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                      disabled={id ? disableValue : false}
                    >
                      No
                    </button>
                  </div>
                </div>
                {form?.is_allow_visibility == 1 && (
                  <CustomSelect
                    label="Set Market Visiblity"
                    options={listData?.marketVisiList}
                    placeholder="Select Connection"
                    onSelect={(data) => {
                      handleOnChange("connection_id", data?.value);
                    }}
                    error={formError?.connection_id}
                    value={listData?.marketVisiList?.find(
                      (i) => i?.value == form?.connection_id
                    )}
                    disabled={id ? disableValue : false}
                  />
                )}
              </div>

              <div className=" _cityInput_bx">
                <div className="_flxOtr d-flex justify-content-between align-items-center">
                  <h6 style={{ margin: "0" }}>Allow Calls</h6>

                  <div
                    className="__numPercen_btBx"
                    style={{ flex: "0 0 111px", margin: "0" }}
                  >
                    <button
                      className={form?.is_allow_calls == 1 ? "active" : ""}
                      name="is_allow_calls"
                      value={1}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                      disabled={id ? disableValue : false}
                    >
                      Yes
                    </button>
                    <button
                      className={form?.is_allow_calls == 0 ? "active" : ""}
                      name="is_allow_calls"
                      value={0}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                      disabled={id ? disableValue : false}
                    >
                      No
                    </button>
                  </div>
                </div>
                <CustomSelect
                  label="Response"
                  options={listData?.responseList}
                  placeholder="Select Response"
                  isDropdownTop={true}
                  onSelect={(data) => {
                    handleOnChange("responses", data?.value);
                  }}
                  value={listData?.responseList?.find(
                    (i) => i?.value == form?.responses
                  )}
                  error={formError?.responses}
                  disabled={id ? disableValue : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="offcanvas-footer">
        {!id ? <button
          className="_addComm_btn _sameWid"
          disabled={loading}
          onClick={handleSubimitPost}
        >
          {" "}
          {!loading ? "Post Indent" : "Creating..."}{" "}
        </button> : <>
       <div></div>
          <button
            className="_addComm_btn _sameWid"
            disabled={disableValue?isProgrammaticUpdate:loading }
            onClick={() => {
              if (disableValue) {
                handleEditIndet()
              } else {
                handleSubimitPost()
              }
            }}
          >
            {" "}
            {!loading ? disableValue ? "Edit" : "Save" : "Creating..."}{" "}
          </button>
          {planStatus == 1 && disableValue ? <button
            className="_addComm_btn _sameWid"
            disabled={loading}
            onClick={() => {
              if (disableValue || planStatus == 1) {
                setShowCloseModal(id)

              } else {
                setShowBackModal(true)
              }

            }}
          >
            {" "}
            {!loading ? "Close Indent" : "Closing..."}{" "}
          </button> : <button
            className="_addComm_btn _sameWid"
            disabled={loading}
            onClick={() => {
              setShowBackModal(true)
            }}
          >
            {" "}
            Cancel
          </button>}
          {planStatus == 1 && <button
            className="_addComm_btn _sameWid"
            disabled={loading}
            onClick={() => {
              setShowDeleteModal(true)
              setShowCloseModal(id)
            }}
          >
            {" "}
           Delete 
          </button>}
        </>
        }
      </div>


      {showAddPo?.open && <AddPoModal {...addPoModal} />}
      {showBackModal && (
        <ConfirmModalFor {...backModalProps}>
          <>
            <p className="_confirmPrx"> Do you really want exit edit indent </p>
          </>
        </ConfirmModalFor>
      )}
      {showCloseModal && (
        <ConfirmModalFor {...closeModalProps}>
          <>
            <p className="_confirmPrx"> Do you really want Close indent </p>
          </>
        </ConfirmModalFor>
      )}
      {showDeleteModal && (
        <ConfirmModalFor {...deleteModalProps}>
          <>
            <p className="_confirmPrx"> Do you really want Delete indent </p>
          </>
        </ConfirmModalFor>
      )}
    </>
  );
};

export default AddIndentModal;
