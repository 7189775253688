import { ButtonHeading } from 'components'
import { AddFastTagModal, AddFuelCardModal } from 'modals'
import React, { useContext, useState } from 'react'
import Tab from 'components/common/Tab';
import { constant } from 'constants/constant';
import OwnDriver from '../OwnDriver'
import MarketDriver from '../MarketDriver'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { FaRegTrashCan } from 'react-icons/fa6';
import { UserContext } from 'context';

const FastTag = () => {
    const { mastersChild } = useContext(UserContext);
    const masterChildFasttag = mastersChild?.find((i) => i?.label === "Fasttag");
    const [showExpModal, setShowExpModal] = useState(false)
    const [activeTab, setActiveTab] = useState(1);
    // const [showOffcanvas, setShowOffcanvas] = useState(false);

    // const toggleOffcanvas = () => {
    //     setShowOffcanvas(!showOffcanvas);
    //     setEditFuelStationData(null)
    //   };


    let AddFastTagModals = {
        isOpen: showExpModal,
        closeModal: (e) => setShowExpModal(false),
        modalMainClass: "_dashCommon_Modal ",
        modalTitle: "Add Fast Tag",
        // headerPart: true,
        footerPart: true,
        submitTitle: "Add Fast Tag",
        // getAccountListHandler: getAccountListHandler
    }

    const tabProps = {
        tabList: constant.fuel_cardTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    };

    return (
        <div>
            <ButtonHeading
                title={"Fast Tag"}
                onClick={() => setShowExpModal(!showExpModal)}
                buttonTitle={masterChildFasttag?.permissions?.create === 1 && "Add Fast Tag"}
                showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
            // expenseOnClick={() => setShowExpModal(!showExpModal)}
            // Expenses={true}
            // onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
            // filter={filter}
            // setFilter={setFilter}
            />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <div className='_mainFc_hv'>
                        <div className="accordion _fuelCard_acc" id="quickUploadAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <div className='_msxFlx'>
                                            <p>BPCL | FA2001025533</p>
                                            <span>1</span>
                                        </div>
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#quickUploadAccordion">
                                    <div className="accordion-body">
                                        <table className='_trpTable_xd'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="signDropdown">
                                                            <button className="dropbtn11">
                                                                <BsThreeDotsVertical />
                                                            </button>
                                                            <div className="dropdownContent">
                                                                {masterChildFasttag?.permissions?.view === 1 && <Link to="/" className='_avPointer'
                                                                    style={{ textDecoration: 'none' }}
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='viewIcon_avt'>
                                                                            <AiOutlineEye />
                                                                        </span>View
                                                                    </span>
                                                                </Link>
                                                                }

                                                                {masterChildFasttag?.permissions?.edit === 1 && <div
                                                                    // onClick={(e) => editHandler(item)}
                                                                    className='_avPointer'
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='editIcon_avt'>
                                                                            <FiEdit />
                                                                        </span>
                                                                        Edit
                                                                    </span>
                                                                </div>
                                                                }
                                                                {masterChildFasttag?.permissions?.delete === 1 &&
                                                                    <div
                                                                        // onClick={(e) => deleteHandler(item)}
                                                                        className='_avPointer'
                                                                    >
                                                                        <span className='_samAnchor'>
                                                                            <span className='deleteIcon_avt'>
                                                                                <FaRegTrashCan color='red' />
                                                                            </span>
                                                                            Delete
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <b>FC0366090180</b>
                                                            <span class="textBottom">NL01AE <span class="blue"> 7413</span></span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <b>BUKHARI PETROLEUM BHARAT PETROLEUM DEALERS, MUNGELI</b>
                                                            <span class="textBottom">07 April 22 08:12PM</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <span class="textBottom">Balance</span>
                                                            <span>Rs. 5000.00</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <div className='_msxFlx'>
                                            <p>BPCL | FA2001025533</p>
                                            <span>1</span>
                                        </div>
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#quickUploadAccordion">
                                    <div className="accordion-body">
                                        <table className='_trpTable_xd'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="signDropdown">
                                                            <button className="dropbtn11">
                                                                <BsThreeDotsVertical />
                                                            </button>
                                                            <div className="dropdownContent">
                                                                <Link to="/" className='_avPointer'
                                                                    style={{ textDecoration: 'none' }}
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='viewIcon_avt'>
                                                                            <AiOutlineEye />
                                                                        </span>View
                                                                    </span>
                                                                </Link>

                                                                <div
                                                                    // onClick={(e) => editHandler(item)}
                                                                    className='_avPointer'
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='editIcon_avt'>
                                                                            <FiEdit />
                                                                        </span>
                                                                        Edit
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    // onClick={(e) => deleteHandler(item)}
                                                                    className='_avPointer'
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='deleteIcon_avt'>
                                                                            <FaRegTrashCan color='red' />
                                                                        </span>
                                                                        Delete
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <b>FC0366090180</b>
                                                            <span class="textBottom">NL01AE <span class="blue"> 7413</span></span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <b>BUKHARI PETROLEUM BHARAT PETROLEUM DEALERS, MUNGELI</b>
                                                            <span class="textBottom">07 April 22 08:12PM</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <span class="textBottom">Balance</span>
                                                            <span>Rs. 5000.00</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <div className='_msxFlx'>
                                            <p>BPCL | FA2001025533</p>
                                            <span>1</span>
                                        </div>
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#quickUploadAccordion">
                                    <div className="accordion-body">
                                        <table className='_trpTable_xd'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="signDropdown">
                                                            <button className="dropbtn11">
                                                                <BsThreeDotsVertical />
                                                            </button>
                                                            <div className="dropdownContent">
                                                                <Link to="/" className='_avPointer'
                                                                    style={{ textDecoration: 'none' }}
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='viewIcon_avt'>
                                                                            <AiOutlineEye />
                                                                        </span>View
                                                                    </span>
                                                                </Link>

                                                                <div
                                                                    // onClick={(e) => editHandler(item)}
                                                                    className='_avPointer'
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='editIcon_avt'>
                                                                            <FiEdit />
                                                                        </span>
                                                                        Edit
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    // onClick={(e) => deleteHandler(item)}
                                                                    className='_avPointer'
                                                                >
                                                                    <span className='_samAnchor'>
                                                                        <span className='deleteIcon_avt'>
                                                                            <FaRegTrashCan color='red' />
                                                                        </span>
                                                                        Delete
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <b>FC0366090180</b>
                                                            <span class="textBottom">NL01AE <span class="blue"> 7413</span></span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <b>BUKHARI PETROLEUM BHARAT PETROLEUM DEALERS, MUNGELI</b>
                                                            <span class="textBottom">07 April 22 08:12PM</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <span class="textBottom">Balance</span>
                                                            <span>Rs. 5000.00</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeTab === 2 && (
                    <div>

                        <ul className='_tapUpper_tab'>
                            <li className="active">
                                <span>All</span>
                            </li>
                            <li className="">
                                <span>Assigned 0</span>
                            </li>
                            <li className="">
                                <span>Unassigned 0</span>
                            </li>
                            <li className="">
                                <span>Omitted 0</span>
                            </li>
                        </ul>

                        <div className="_indentTable _disPatchView _fuelCrd__table">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>VEHICLE NUMBER</th>
                                        <th>TOLL PLAZA</th>
                                        <th>AMOUNT</th>
                                        <th>TRIP RELATION</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="signDropdown">
                                                <button className="dropbtn11">
                                                    <BsThreeDotsVertical />
                                                </button>
                                                <div className="dropdownContent">
                                                    {masterChildFasttag?.permissions?.view === 1 &&
                                                        <Link to="/" className='_avPointer'
                                                            style={{ textDecoration: 'none' }}
                                                        >
                                                            <span className='_samAnchor'>
                                                                <span className='viewIcon_avt'>
                                                                    <AiOutlineEye />
                                                                </span>View
                                                            </span>
                                                        </Link>
                                                    }
                                                    {masterChildFasttag?.permissions?.edit === 1 &&
                                                        <div
                                                            // onClick={(e) => editHandler(item)}
                                                            className='_avPointer'
                                                        >
                                                            <span className='_samAnchor'>
                                                                <span className='editIcon_avt'>
                                                                    <FiEdit />
                                                                </span>
                                                                Edit
                                                            </span>
                                                        </div>
                                                    }
                                                    {masterChildFasttag?.permissions?.delete === 1 &&
                                                        <div
                                                            // onClick={(e) => deleteHandler(item)}
                                                            className='_avPointer'
                                                        >
                                                            <span className='_samAnchor'>
                                                                <span className='deleteIcon_avt'>
                                                                    <FaRegTrashCan color='red' />
                                                                </span>
                                                                Delete
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="_dateNtime _pDate">
                                                <p className="_tblText_inDent">
                                                    MH40BG8355
                                                </p>
                                                <p className="_lgtText_inDent">
                                                    TXN000009794689
                                                </p>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="_dateNtime _pDate">
                                                <p className="_tblText_inDent">
                                                    Zuber
                                                </p>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="__locationWid_bx">
                                                <p className="_lgtText_inDent">
                                                    Charges
                                                </p>
                                                <p className="_tblText_inDent">
                                                    Rs. 22000.00
                                                </p>
                                            </div>
                                        </td>

                                        <td>
                                            <div>
                                                <button className='_addComm_btn'>Add Relation</button>
                                                <button className='_addComm_btn ms-1'>OMIT</button>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>


            {showExpModal
                && (
                    <AddFastTagModal {...AddFastTagModals} />
                )
            }
        </div>
    )
}

export default FastTag