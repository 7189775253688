import { getAllLrApi } from "_services";
import { CommonDateRange, CommonInput, CommonModal, CustomPagination, CustomSelect } from "components";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import StorageService from "utils/StorageServices/StorageServices";
import moment from "moment";
import AddEditLrModal from "modals/AddEditLrModal";
import AddLrModal from "modals/AddLrModal";
import { UserContext } from "context";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const SelectLrModal = (props) => {
  const {
    indexofTable,
    listOfLr,
    callbackSelectLr,
    selectedLr,
    isOpen,
    branchList,
    consignorList,
    closeModal,
    setSelectParty,
  } = props;
  const { masterData, setIsMaterialIndex } = useContext(UserContext)
  const [selectedValue, setSelectedValue] = useState([]);
  const [lrListFlag, setLrListFlag] = useState(false);
  const [lrList, setLrList] = useState([]);
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(32, "days"),
    endDate: moment(),
  });

  const [filter, setFilter] = useState({
    date: moment(new Date()).format("YYYY-MM-DD"),
    start_date:"",
    end_date:"",
    branch_id: "",
    consignor_id: "",
    keyword: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
 
    if (selectedLr[0].list?.length) {
      const newarray = selectedLr.map((i) => [...i?.list]);
      const mergedArray1 = newarray.flat();

      if (mergedArray1.length) {
        setSelectedValue(
          mergedArray1?.map((item) => ({
            consignor_id: item.consignor_id?._id,
            lr_id: item._id,
          }))
        );
      }
    } else {
      setSelectedValue([
        {
          consignor_id: "",
          lr_id: "",
        },
      ]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedValue.length) {
     
      getLrList(selectedValue[0]?.consignor_id ? selectedValue : []);
    }
  }, [selectedValue, filter, currentPage,selectedRange]);

    useEffect(() => {
      setCurrentPage(1)
    }, [filter]);

    const handleApply = (newRange) => {
      setSelectedRange(newRange);
      // setFilter({
      //   start_date:moment(selectedRange.startDate).format("YYYY-MM-DD"),
      //   end_date:moment(selectedRange.endDate).format("YYYY-MM-DD"),
      // })
    };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getLrList = async (selectId) => {
   
    setLrListFlag(true);
    const res = await getAllLrApi({
      company_id: StorageService.getCompanyId(),
      select_lr_ids: selectId,
      lr_status: 1,
      branch_id: filter?.branch_id,
      consignor_id: filter?.consignor_id,
      search: filter?.keyword,
      limit: itemsPerPage,
      offset: currentPage,
      start_date: moment(selectedRange.startDate).format("YYYY-MM-DD"),
      end_date: moment(selectedRange.endDate).format("YYYY-MM-DD")
    });
    if (res?.responseCode === 200) {
      setLrList(res?.data?.lr_list);
      if (res?.data?.lr_list.length) {
        setTotalItems(res?.data?.total_record);
        setLrListFlag(false);
      } else {
        setLrListFlag(false);
      }
    } else {
      toast.error(res?.responseMessage);
    }
  };

  const handleSelect = (item, index) => {
    if (listOfLr?.length) {
      const filterList = listOfLr?.some((lr) => lr?._id === item?._id); //[1]
      if (!filterList) {
        callbackSelectLr(item);
        let newLrList = [...lrList];
        newLrList.splice(index, 1);
        setLrList(newLrList);
        toast.success("LR Added");
      } else {
        toast.error("LR Already Exist");
      }
    } else if (!listOfLr?.length) {
      // const newList = [...listOfLr];
      // console.log("newList", newList)
      // newList[indexofTable].List.push(item)
      // newList[indexofTable] = [{ ...listOfLr, item }]
      // setListOfLr(newList)
      // const newLrCommList = [...commondataa.selectedLr]
      // // newLrCommList[indexofTable] = [{ ...commondataa.selectedLr, item }]
      // setCommonDataa(newLrCommList)
      // // setCommonDataa({ ...commondataa, selectedLr: [...commondataa.selectedLr, item] });
      // let newLrList = [...commondata?.LRList]
      // // newLrList.splice(index, 1, { ...item, isSelected: true })
      // newLrList.splice(index, 1)
      // setCommonData({ ...commondata, LRList: newLrList })
      // toast.success("LR Added 123")
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...filter,
      [name]: value,
    };
    setFilter(stateObj);
  };


  return (
    <div>
      <CommonModal {...props}>
        <div className="_selectLrBody">
          <div className="_sercFilts_bx">
            <div className="plan_search_header">
              <div className="_anvPlan_srOuter_bx">
                {/* <CommonInput
                  type="date"
                  label="Date"
                  name="txn_date"
                  value={filter?.date}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                /> */}
                <CommonDateRange
              initialStartDate={selectedRange.startDate}
              initialEndDate={selectedRange.endDate}
              onApply={handleApply}
            />
              </div>

              <div className="_anvPlan_srOuter_bx">
                <CustomSelect
                  label="Branch Name"
                  placeholder="Select Branch"
                  options={branchList}
                  name="branch_id"
                  //   value={filter?.branch_id}
                  value={
                    filter?.branch_id
                      ? branchList?.find((i) => i.value == filter?.branch_id)
                      : { label: "", value: "" }
                  }
                  onSelect={(data) => {
                    handleOnChange("branch_id", data.value);
                  }}
                />
              </div>

              <div className="_anvPlan_srOuter_bx">
                <CustomSelect
                  label="Consignor"
                  placeholder="Select Consignor"
                  options={consignorList}
                  name="consignor_id"
                  //   value={filter?.consignor_id}
                  value={
                    filter?.consignor_id
                      ? consignorList?.find(
                          (i) => i.value == filter?.consignor_id
                        )
                      : { label: "", value: "" }
                  }
                  onSelect={(data) => {
                    handleOnChange("consignor_id", data.value);
                  }}
                />
              </div>

              <div className="_anvPlan_srOuter_bx">
                <CommonInput
                  type="text"
                  label="Search field"
                  name="keyword"
                  value={filter?.keyword}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />
              </div>
            </div>

            <div>
              <button
                className="_addComm_btn"
                onClick={() => {
                  setSelectParty(true);
                  closeModal();
                }}
              >
                Add New LR
              </button>
            </div>
          </div>
          <div className="_selectLr_table">
            <table>
              <thead>
                <tr>
                  <th>Invoice Date</th>
                  <th>Branch</th>
                  <th>Consignor</th>
                  <th>From Station</th>
                  <th>To Station</th>
                  <th>MLR/ LR Number</th>
                  <th>Invoice/ PO Number</th>
                  <th>Qty</th>
                  <th>Weight</th>
                  <th>KOT</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              {lrList && lrList.length > 0 ? (
                <tbody>
                  {lrList.map((i, index) => {
                  
                    return (
                      <>
                        <tr key={`lrList_${index}`}>
                          <td>
                            {moment(i?.invoice_date).format(" D  MMM YYYY")}
                          </td>

                          <td>{i?.branch_id?.branch}</td>

                          <td>{i?.consignor_id?.name}</td>

                          <td>
                            <span className="_anv_lrLocation_span">
                              {i?.from_location}
                            </span>
                            <br />
                            <span className="_anvPlist_stBot">
                              {i?.unit_id?.unit_code
                                ? i?.unit_id?.unit_code
                                : " -"}
                              {i?.unit_id?.name ? i?.unit_id?.name : " -"}
                            </span>
                          </td>

                          <td>
                            <span className="_anv_lrLocation_span">
                              {i?.to_location}
                            </span>
                            <br />
                            <span className="_anvPlist_stBot">
                              {i?.consignee_id?.name
                                ? i?.consignee_id?.name
                                : "--"}
                            </span>
                          </td>

                          <td>{i?.mlr_number ? i?.mlr_number : "-"}</td>

                          <td>
                            {i?.invoice_number ? i?.invoice_number : "-"} <br />{" "}
                            {i?.po_number ? i?.po_number : "-"}
                          </td>

                          <td>{i?.total_qty}</td>

                          <td>{i?.total_weight}</td>

                          <td>{i?.total_kot}</td>

                          <td>  <span className={masterData?.lr_status[i?.lr_status] === 'Pending' ? '_lrPending_status' : (masterData?.lr_status[i?.lr_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.lr_status[i?.lr_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.lr_status[i?.lr_status] === 'Delivered' ? '_lrDelivered_status' : (masterData?.lr_status[i?.lr_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}> {masterData?.lr_status[i?.lr_status] ? masterData?.lr_status[i?.lr_status] : ''} </span></td>

                          <td>
                            <button
                              onClick={() => handleSelect(i, index)}
                              className="_addComm_btn"
                            >
                              Select
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              ) : lrListFlag ? (
                lrListFlag && (
                  <tbody>
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  </tbody>
                )
              ) : (
                <tbody>
                  <tr>
                    <td>No Data</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
           {lrList.length > 0  && <div className="paginationFt_avt ">
            <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
            </div>}
        </div>
      </CommonModal>
    </div>
  );
};

export default SelectLrModal;
