import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ButtonHeading, CommonInput, CustomSelect } from 'components'
import { AddEditLrModal, AddItemGroupModal, AddItemModal, AddLrModal, AddNewSeriesModal, LrDetailModal, LrSeriesModal } from 'modals'
import { getLrCountData, getMainLrListData, updateUserLrColumns } from '_services/accountsServices/lrServices'
import StorageService from 'utils/StorageServices/StorageServices'
import { CustomTableNew } from 'components/CustomTable'
import moment from 'moment';
import AddEditConsignor from 'modals/AddEditConsignor/AddEditConsignor'
import { UserContext } from 'context'
import { BillSummaryDetails } from '.'
import { useParams } from 'react-router-dom'
import { getDetailsInvoiceBillRaise, getDetailsOfSummaryData, getInvoiceBillRaise } from '_services/invoiceBillRaiseServices'
import Loading from 'pages/Loading'
import { getConsignorDropdownListData, getLrTemplateDropdownListData } from '_services/dropdownServices/dropdownService'

const BillRaiseDetails = () => {
  const { id } = useParams();
  const { masterData, setIsMaterialIndex } = useContext(UserContext)
  const [billRaiseDetailsData, setBillRaiseDetailsData] = useState({});
  const [isDropDownFilter, setIsDropDownFilter] = useState({
    consignor_id: '',
    template_id: '',
  })

  const [lrTemplateList, setLrTemplateList] = useState([])
  const [consignorList, setConsignorList] = useState([]);
  // Lr table start 

  const [columns, setColumns] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [filterableColumns, setFilterableColumns] = useState([]);
  const [isMasterFilter, setIsMasterFilter] = useState(true);
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeStatus, setActiveStatus] = useState(0);
  const [isTableHide, setIsTableHide] = useState(true);

  // const handleDetailsAction = (data) => {
  //   setShowLrDetail(data);
  // }

  const transformData = (data) => {
    return data.map(item => ({
      lr_number: item?.lr_number ? <div className='_tblText _lrNums' > <a className='_avPointer'> {item?.lr_number} </a> </div> : '-',
      trip_id: item?.trip_id?.trip_number ? <div className='_tblText'>{item?.trip_id?.trip_number} </div> : '-',
      plan_id: item?.plan_id?.indent_number ? <div className='_tblText'>{item?.plan_id?.indent_number} </div> : '-',
      invoice_date: item?.invoice_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.invoice_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.invoice_date).format('hh:mm A')}</span>
      </div> : '-',
      dispatch_date: item?.trip_id?.dispatch_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.trip_id?.dispatch_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.trip_id?.dispatch_date).format('hh:mm A')}</span>
      </div> : '-',
      consignor_name: item?.consignor_id?.name ? <div className='_tblText _oneLine_tx'>{item?.consignor_id?.name}</div> : '-',
      branch_name: item?.branch_id?.branch ? <div className='_tblText _oneLine_tx'>{item?.branch_id.branch}</div> : '-',
      // from_location: item?.from_location ? item?.from_location : '-',
      from_location: item?.from_location ? (
        <div className='_locationWid_bx' >
          <p className="_tblText">{item.from_location}</p>
          {((item.consignee_id && item.consignee_id.consignee_code) || (item.consignee_id && item.consignee_id.name)) && <p className="_lgtText">{item.consignee_id && item.consignee_id.consignee_code && item.consignee_id.consignee_code} |{item.consignee_id && item.consignee_id.name && item.consignee_id.name} </p>}
        </div>
      ) : '-',
      // to_location: item?.to_location ? item?.to_location : '-',
      to_location: item?.to_location ? (
        <div className='_locationWid_bx'>
          <p className="_tblText">{item.to_location}</p>
          {((item.unit_id && item.unit_id.unit_code) || (item.unit_id && item.unit_id.name)) && <p className="_lgtText">{item.unit_id && item.unit_id.unit_code && item.unit_id.unit_code} |{item.unit_id && item.unit_id.name && item.unit_id.name} </p>}
        </div>
      ) : '-',
      invoice_number: item?.invoice_number ? <div className='_tblText'>{item?.invoice_number}</div> : '-',
      sap_invoice_number: item?.sap_invoice_number ? <div className='_tblText'>{item?.sap_invoice_number}</div> : '-',
      gst_number: item?.gst_number ? <div className='_tblText'>{item?.gst_number}</div> : '-',
      po_number: item?.po_number ? <div className='_tblText'>{item?.po_number}</div> : '-',
      mlr_number: item?.mlr_number ? <div className='_tblText'>{item?.mlr_number}</div> : '-',
      // lr_status: item?.lr_status ? item?.lr_status : '-',
      lr_status: item?.lr_status ? (
        // <span className="bg-success"> {item.lr_status} </span>
        <span className={masterData?.lr_status[item?.lr_status] === 'Pending' ? '_lrPending_status' : (masterData?.lr_status[item?.lr_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.lr_status[item?.lr_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.lr_status[item?.lr_status] === 'Delivered' ? '_lrDelivered_status' : (masterData?.lr_status[item?.lr_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}> {masterData?.lr_status[item?.lr_status] ? masterData?.lr_status[item?.lr_status] : ''} </span>
      ) : '-',
      pod_status: item?.pod_status ? <span className={masterData?.pod_status[item?.pod_status] === 'Pending' ? '_lrPending_status' : (masterData?.pod_status[item?.pod_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.pod_status[item?.pod_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.pod_status[item?.pod_status] === 'Received' ? '_lrDelivered_status' : (masterData?.pod_status[item?.pod_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}> {masterData?.pod_status[item?.pod_status] ? masterData?.pod_status[item?.pod_status] : ''} </span>
        : '-',
      created_by: item?.created_by ? <div className='_tblText'>{item?.created_by}</div> : '-',
      eway_bill_number: item?.eway_bill_number ? <div className='_tblText'>{item?.eway_bill_number}</div> : '-',
      eway_bill_date: item?.eway_bill_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.eway_bill_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.eway_bill_date).format('hh:mm A')}</span>
      </div> : '-',
      eway_expiry_date: item?.eway_expiry_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.eway_expiry_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.eway_expiry_date).format('hh:mm A')}</span>
      </div> : '-',
      eway_bill_status: item?.eway_bill_status ? <div className='_tblText'>{item?.eway_bill_status}</div> : '-',
      total_qty: item?.total_qty ? <div className='_tblText'>{item?.total_qty}</div> : '-',
      total_kot: item?.total_kot ? <div className='_tblText'>{item?.total_kot}</div> : '-',
      total_freight: item?.total_freight ? <div className='_tblText'>{item?.total_freight}</div> : '-',
      dispatch_date: item?.dispatch_date ? <div className='_tblText'>{moment(item?.dispatch_date).format('DD MMM YYYY')}</div> : '-',
      eway_bill_status: item?.eway_bill_status === 0 ? <span className='_lrPending_status'>  Pending </span> : '-',
      vehicle_no: item?.vehicle_no ? <div className='_tblText'>{item?.vehicle_no}</div> : '-',
      vehicle_ownership: item?.vehicle_ownership ? <div className='_tblText'>{masterData?.vehicle_ownership[item?.vehicle_ownership]}</div> : '-',
      lorry_type: item?.lorry_type ? <div className='_tblText'>{item?.lorry_type}</div> : '-',
      supplier_name: item?.supplier_name ? <div className='_tblText'>{item?.supplier_name}</div> : '-',
      vehicle_type: item?.vehicle_type ? <div className='_tblText'>{item?.vehicle_type}</div> : '-',
      indent_number: item?.indent_number ? <div className='_tblText'>{item?.indent_number}</div> : '-',
      pod_date: item?.pod_date ? <div className='_tblText'>{moment(item?.pod_date).format('DD MMM YYYY')}</div> : '-',
      pod_file: item?.pod_file ? <div className='_tblText'>{item?.pod_file?.length ? item?.pod_file?.length : 0}</div> : '-',
      trip_number: item?.trip_number ? <div className='_tblText'>{item?.trip_number} </div> : '-',
      driver_name: item?.driver_name ? <div className='_tblText'>{item?.driver_name}</div> : '-',
      total_weight: item?.total_weight ?
        <div className='_tblText'>{item?.total_weight}</div>
        : '-'
    }));
  };

  const getData = async (page, lrStatus) => {
    try {
      let postData = {
        company_id: StorageService.getCompanyId(),
        billraise_id: id,
        consignor_id: isDropDownFilter?.consignor_id?.value,
        template_id: isDropDownFilter?.template_id?.value,
        limit: pageLimit,
        offset: currentPage,
        search: '',
      }
      const response = await getDetailsInvoiceBillRaise(postData);
      setColumns(response?.data?.lr_columns);
      setApiData(transformData(response?.data?.lr_list));
      setFilterableColumns(response?.data?.lr_filter_columns);
      setIsMasterFilter(true);
      setTotalPages(Math.ceil(response?.data?.total_record / pageLimit));
      // setFilterableColumns(response.filterableColumns);
      // setIsMasterFilter(response.isMasterFilter);
    } catch (error) {
      console.error('Error fetching master data:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const updateLrColumns = async (data) => {
    let postData = {
      company_id: StorageService.getCompanyId(),
      lr_columns: data
    }
    try {
      const response = await getDetailsInvoiceBillRaise(postData);
      setColumns(response?.data?.lr_columns);
      setApiData(transformData(response?.data?.lr_list));
      setFilterableColumns(response?.data?.lr_filter_columns);
      setIsMasterFilter(true);
      setTotalPages(Math.ceil(response?.data?.total_record / pageLimit));
      // setFilterableColumns(response.filterableColumns);
      // setIsMasterFilter(response.isMasterFilter);
    } catch (error) {
      console.error('Error fetching master data:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (consignorList?.length > 0 && lrTemplateList?.length > 0) {
      getData(currentPage, activeStatus);
    }
    setIsMaterialIndex('');
  }, [masterData, currentPage, activeStatus, isDropDownFilter, isTableHide])

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  // Lr table end

  const handleStatusChange = async (lrStatus) => {
    setIsTableHide(false);
    if (lrStatus !== activeStatus) { // Check if status actually changes
      setActiveStatus(lrStatus); // Update activeStatus
      setCurrentPage(1); // Reset page to 1 when status changes

      // Debounce the API call to prevent multiple rapid calls
      await new Promise(resolve => setTimeout(resolve, 300)); // Adjust debounce time as needed

      getData(1, lrStatus);
    }
  };

  const handleComponentChange = (e) => {
    setIsTableHide(true)
  }

  const renderStatusFilters = () => (
    <div className='_tabUr_bx _minTab_ur'>
      <button className={`tabCst_btn ${isTableHide ? '_btnActive' : ''}`} onClick={(e) => handleComponentChange(e)}> <span > Summary </span> </button>
      <button className={`tabCst_btn ${!isTableHide ? '_btnActive' : ''}`} onClick={() => handleStatusChange(0)}> <span > LR </span> </button>
    </div>
  );

  const getTemplateLrList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        search: "",
      }
      const res = await getLrTemplateDropdownListData(object);
      if (res) {
        let responseData = res?.data?.list?.map((el) => {
          return {
            label: el?.name,
            value: el?._id,
          }
        })
        setLrTemplateList(responseData);
      }
    } catch (error) {
      // throw error;
    }
  }

  const getDropdownDataConsignorList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: '',
      }
      const res = await getConsignorDropdownListData(object);
      setLoading(false);
      if (res?.responseCode == 200) {
        setConsignorList(res?.data?.consignor_list)
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getTemplateLrList();
    getDropdownDataConsignorList();
    // isOpenInvoiceTemplate
  }, [StorageService.getCompanyId()])

  useEffect(() => {
    if (lrTemplateList?.length > 0) {
      setIsDropDownFilter((s) => ({
        ...s,
        template_id: lrTemplateList[0],
      }))
    }
  }, [lrTemplateList])

  useEffect(() => {
    if (consignorList?.length > 0) {
      setIsDropDownFilter((s) => ({
        ...s,
        consignor_id: consignorList?.map((el) => {
          return {
            label: el?.name,
            value: el?._id,
          }
        })[0],
      }))
    }
  }, [consignorList])

  const showingDropdownFilters = () => {
    return <>
      <div className="_fleX_inds _blRaise_fits">
        <div className="_divForth">
          <CustomSelect
            options={consignorList?.map((el) => {
              return {
                label: el?.name,
                value: el?._id,
              }
            })}
            label="Select Party"
            placeholder="Select Party"
            name="consignor_id"
            value={isDropDownFilter?.consignor_id}
            onSelect={(data) => setIsDropDownFilter((s) => ({ ...s, consignor_id: data }))}
          />
        </div>

        {/* <div className="_divForth">
          <CustomSelect
            label="Template Format"
            placeholder="Template Format"
            options={lrTemplateList}
            name="template_id"
            value={templateStatus}
            isAddButton={true}
            addButtonAction={(e) => setIsOpenInvoiceTemplate(true)}
            onSelect={(data) => setTemplateStatus(data)}
          />
        </div> */}

        <div className="_divForth">
          <CustomSelect
            label="Template Format"
            placeholder="Template Format"
            options={lrTemplateList}
            name="template_id"
            value={isDropDownFilter?.template_id}
            // isAddButton={true}
            // addButtonAction={(e) => setIsOpenInvoiceTemplate(true)}
            onSelect={(data) => setIsDropDownFilter((s) => ({ ...s, template_id: data }))}
          />
        </div>
      </div>
    </>
  }

  const callInvoiceDetailsApi = async (_id, company_id) => {
    try {
      let object = {
        company_id: company_id,
        billraise_id: _id,
      }
      const res = await getDetailsOfSummaryData(object);
      if (res?.responseCode == 200) {
        setBillRaiseDetailsData(res?.data?.billraise);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  useEffect(() => {
    if (id && StorageService.getCompanyId()) {
      setLoading(true);
      callInvoiceDetailsApi(id, StorageService.getCompanyId())
    }
  }, [id, StorageService.getCompanyId()])

  return (
    <div>
      <ButtonHeading
        title={"Bill Raise"}
        Export={true}
        // ExportSubmit={hello}
        Print={true}
        // PrintSubmit={hello}
        Edit={true}
      // EditSumit={hello}
      />

      <div className='_billRaise_dataTable'>
        <CustomTableNew
          columns={columns}
          data={apiData}
          filterableColumns={filterableColumns}
          isMasterFilter={isMasterFilter}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={pageLimit}
          setColumns={(data) => updateLrColumns(data)}
          statusApiFilter={renderStatusFilters()}
          showDropdownFilters={showingDropdownFilters()}
          isTableHide={isTableHide}
        />
      </div>

      {isTableHide && <div>
        {loading ? <div className='p-4'><Loading /></div> : <BillSummaryDetails data={billRaiseDetailsData} />}
      </div>}

    </div>
  )
}

export default BillRaiseDetails