import { addressImport, fuelCardImport, planImport } from "_services";
import { getPartyAssociateApi } from "_services/accountsServices/addressBookService";
import {
  getConsignorDropdownListData,
  getContractDropdownListData,
} from "_services/dropdownServices/dropdownService";
import { CommonInput, CustomSelect, ExcelDownload, Heading } from "components";
import { UserContext } from "context";
import { errorSchema, utils } from "helper";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices";
import * as Yup from "yup";

const FuelCardUplaod = () => {
 ;

  const initi = {
    company_id: StorageService.getCompanyId(),
    file:""
  };

  const [form, setForm] = useState(initi);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
const [addressInportList,setAddressInportList] = useState([])
const [tableData,setTableData] = useState([])
const [statusCounts, setStatusCounts] = useState({
  Rejected: 0,
  Updated: 0,
  Added: 0,
  All: 0,
});

const fileSchema = {
    uploadFileSchema: Yup.object().shape({
      file: Yup.string().required("Required *")
    }),
  }



  const handleOnChange = async (name, value) => {
  
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            fileSchema?.uploadFileSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (validTypes.includes(file.type)) {
        handleOnChange("file", file);
        setFormError({
          ...formError,
          file: "",
        });
      } else {
        setFormError({
          ...formError,
          file: "Please upload only CSV or XLSX files.",
        });
      }
    }
  };

 
  const handleImportFile = async () => {
   
    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      if (key === "file" && form[key]) {
        formData.append(key, form[key]);
      } else {
        formData.append(key, form[key]);
      }
    });

    const validationResult = await utils.checkFormError(
      { ...form },
      fileSchema?.uploadFileSchema
    );

 

    if (validationResult == true) {
      setLoading(true);
      try {
        const res = await fuelCardImport(formData);
        if (res?.responseCode == 200) {
          setLoading(false);
        //   setAddressInportList(res?.data?.results)
          toast?.success(res?.responseMessage);
          
        //   const execleDat = res?.data?.results?.map((i)=>{
        //     return {
        //       "Name":i?.value?.name,
        //       "status":i?.status,
        //       "remark":i?.remarks

        //     }
        //   })

        //   const counts = res?.data?.results.reduce(
        //     (acc, item) => {
        //       if (item.status === 'Rejected') acc.Rejected++;
        //       if (item.status === 'Updated') acc.Updated++;
        //       if (item.status === 'Added') acc.Added++;
        //       acc.All++;
        //       return acc;
        //     },
        //     { Rejected: 0, Updated: 0, Added: 0,All: 0  }
        //   );

        //   setStatusCounts(counts);
        //   setTableData([...execleDat])
        } else {
          setLoading(false);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      setFormError(validationResult);
    }
  };

  return (
    <>
      <Heading title="Fuel Card Upload" />

      <div className="_Qwk_inr">
        <div className="_exceluploadBx">
          <CommonInput
            type="file"
            onChange={handleFileChange}
            accept=".csv, .xlsx"
            error={formError?.file}
          />
        </div>

        <button className="_flUpload_btn" disabled={loading} onClick={()=>handleImportFile()}>{loading?"Uploading..":"Upload File"}</button>
      </div>

      {addressInportList?.length >0 &&<div className="tbdFilts_bx">
        <div className="_hedFilts">
          <h4>Status</h4> <ExcelDownload tableData={tableData} />
        </div>

         <div className="_tbdOx_bx">
          <div className="_fourTbx">
            <h4>{statusCounts?.All}</h4>
            <p className="__successTxt">All</p>
          </div>

          <div className="_fourTbx">
            <h4>{statusCounts?.Added}</h4>
            <p className="__successTxt">Added</p>
          </div>

          <div className="_fourTbx">
            <h4>{statusCounts?.Updated}</h4>
            <p className="__successTxt">Updated</p>
          </div>

          <div className="_fourTbx">
            <h4>{statusCounts?.Rejected}</h4>
            <p className="__rejectTxe">Not Updated</p>
          </div>
        </div>
      </div>}

      {addressInportList?.length>0 && <div className="_qwkTable_dix">
        <div className="_indentTable _disPatchView _fuelCrd__table">
          <table>
            <thead>
              <tr>
                <th>Address Name</th>
                <th>Status</th>
                <th>Remarks</th>
              </tr>
            </thead>

            <tbody>
              {addressInportList?.length && addressInportList?.map((i)=>{
          
               return  <tr>
                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent">{i?.value?.name}</p>
                  </div>
                </td>

                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent __successTxt">{i?.status}</p>
                  </div>
                </td>

                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent">{i?.remarks}</p>
                  </div>
                </td>
              </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>}
    </>
  );
};

export default FuelCardUplaod;
