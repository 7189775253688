import { API, axiosInstance } from "lib";

// chart of account
export const getChartOfAccountList = (data = {}) => {
    return axiosInstance
        .post(API.GET_CHART_ACCOUNT_LIST, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err) {
                return err?.response?.data
            }
          ;
        });
};

export const getChartOfAccountDetails = (data = {}) => {
    return axiosInstance
        .post(API.GET_SINGLE_CHART_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err) {
                return err?.response?.data
            }
         
        });
};

// Delete chart of account
export const deleteChartOfAccount = (data = {}) => {
    return axiosInstance
        .post(API.DELETE_CHART_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err) {
                return err?.response?.data
            }
            
        });
};
// update chart of account
export const updateChartOfAccount = (data = {}) => {
    return axiosInstance
        .post(API.EDIT_CHART_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err) {
                return err?.response?.data
            }
            throw err;
        });
};
export const createChartOfAccount = (data = {}) => {
    return axiosInstance
        .post(API.CREATE_CHART_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err) {
                return err?.response?.data
            }
         
        });
};

export const updateApproveStatus = (data = {}) => {
    return axiosInstance
        .post(API.UPDATE_APPROVE, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err) {
                return err?.response?.data
            }
            
        });
};

export const getDropDownChartOfAccount = (data = {}) => {
    return axiosInstance
        .post(API.GET_DROPDOWN_CHART_ACCOUNT, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err) {
                return err?.response?.data
            }
           
        });
};