import React, { useContext, useEffect, useState } from 'react'
import { ButtonHeading } from 'components'
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import Freight from './Freight';
import DispatchDetails from './DispatchDetails';
import ConsignorFreight from './ConsignorFreight';
import Revenue from './Revenue';
import { useParams } from 'react-router-dom';
import { getDispatchedTrip, getLastTrip, getUserPermissionApi } from '_services';
import StorageService from 'utils/StorageServices/StorageServices';
import { UserContext } from 'context';
import Loading from 'pages/Loading';
import { BiltyPrintModal, EndTripModal } from 'modals';
import toast from 'react-hot-toast';

const TripDetail = () => {
  const { setGlobTripDetails,globTripDetails } = useContext(UserContext)
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState(1);
  const [tripDetais, setTripDetails] = useState();
  const [userPermission,setUserPermission] = useState('')
  const [endTrip, setEndTrip] = useState(false);
  const [showEndTrip, setShowEndTrip] = useState(false);
  const [lastTripDetails, setLastTripDetails] = useState();
  const [show, setShow] = useState(false)
  const [endTripApiCall,setEndTripApiCall] = useState("")
  const callBackTab = (data) => {
    setActiveTab(data);
  }
  const tabProps = {
    tabList: constant.tripDetailsTabs,
    callBackTab: callBackTab,
    activeTab: activeTab
  }

  useEffect(() => {
    if (id) {
      getDispatchedTripHandler(id);
    }
   getUserPermission(StorageService.getCompanyId())
  }, [id]);

  useEffect(() => {
    if (endTrip) {
      vehicleStatusCheck();
    }
  }, [endTrip]);

  const vehicleStatusCheck = () => {
    
    setLastTripDetails("");
    getLastTripHandle();
    setShowEndTrip(true);
  };

  const getLastTripHandle = async () => {
    const res = await getLastTrip({
      company_id: StorageService.getCompanyId(),
      vehicle_id: tripDetais?.vehicle_id?._id,
    });
    if (res?.responseCode === 200) {
      setLastTripDetails(res.data?.trip);
    } else {
      toast.error(res?.responseMessage);
    }
  };

  const getDispatchedTripHandler = async (tripId) => {
    try {
      const res = await getDispatchedTrip({
        company_id: StorageService.getCompanyId(),
        trip_id: tripId,
      });
     
      if (res?.responseCode === 200) {
        setTripDetails(res?.data?.trip);
        setGlobTripDetails(res?.data?.trip)
        setEndTripApiCall("")
      } else {
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserPermission = async() =>{
    try {
      const res = await getUserPermissionApi({
        company_id: StorageService.getCompanyId(),
      });
      if (res?.responseCode === 200) {
        setUserPermission(res?.data?.user);
      } else {
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const callApi = () => {
    getDispatchedTripHandler(id);
  }

  const callBackEnd = (data) => {
    setEndTripApiCall("call")
    callApi();
  };

  const freightProps = {
    freightId: id,
    tripDetais: tripDetais,
    callApi: callApi,
    userPermission:userPermission,
    setEndTripApiCall:setEndTripApiCall,
    endTripApiCall:endTripApiCall
  }

  const DispatchDetailsProps = {
    freightId: id,
    tripDetais: tripDetais,
    callApi: callApi,
    userPermission:userPermission,
    setEndTripApiCall:setEndTripApiCall,
    endTripApiCall:endTripApiCall
  }
  let builtyPrintModal = {
    isOpen: show,
    closeModal: (e) => setShow(false),
    modalMainClass: "_dashCommon_Modal _printViewModal _xxlModal",
    footerPart: true,
    modalTitle: "Print LR/ Bilty",
    submitTitle: "Print Bilty",
    freightId: id,
  }


  let endTripModal = {
    isOpen: showEndTrip,
    closeModal: (e) => {
      setShowEndTrip(false);
      setEndTrip(false);
    },
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "End Trip",
    submitTitle: "End Trip",
    callBackEnd: callBackEnd,
    lastTripDetails: lastTripDetails,
   
  };

  return (
    <div>
      
      <ButtonHeading 
        title={"Trip detail"}
        printButtonTitle='Print Builty/LR'
        printOnClick={() => setShow(!show)}
        tripDetais={tripDetais}
        endTripTitle={"End Trip"}
        endTripOnClick={() => setEndTrip(true)}
      />

      {tripDetais ? <div className='_trpDetail_bx'>
        <div className='addPr_flBx'>
          <Tab {...tabProps} />
          <div className='__navTabContents'>
            {activeTab === 1 && (
              <DispatchDetails {...DispatchDetailsProps} />
            )}

            {activeTab === 2 && (
              <Freight {...freightProps} />
            )}

            {activeTab === 3 && (
              <ConsignorFreight {...freightProps} />
            )}

            {activeTab === 4 && (
              <Revenue {...freightProps}/>
            )}

          </div>

        </div>

        {show
        && (
          <BiltyPrintModal {...builtyPrintModal} />
        )
      }
{showEndTrip && <EndTripModal {...endTripModal} />}

      </div> : <Loading />}

    </div>
  )
}

export default TripDetail