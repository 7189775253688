import { getFuelStatementData } from '_services/accountsServices/fuelStationServices';
import BootstrapTable from 'components/BootstrapTable'
import { AddOtherDocumentsModal } from 'modals';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import StorageService from 'utils/StorageServices';

const listData = [
    {
        vehicle_number: 'MP20 GA 2109',
        document_type: "Insurance",
        expiry_date: "Valid till 25 Aug 2025",
    },
    {
        vehicle_number: 'MP20 GA 2109',
        document_type: "Expired 20 Days Ago",
        expiry_date: "Fuel Paid By Driver",
    },
    {
        vehicle_number: 'MP20 GA 2109',
        document_type: "Pollution Certificate",
        expiry_date: "Fuel Paid By Driver",
    },
]

const VehicleDocuments = ({onDataFetch}) => {
    let { id } = useParams();
    const [otherDocumentShow, setOtherDocumentShow] = useState(false);
    const [detailsData, setDetailsData] = useState({})
    const [loading, setLoading] = useState(false);

    let otherDocumentsProps = {
        isOpen: otherDocumentShow,
        closeModal: (e) => setOtherDocumentShow(false),
        headerPart: false,
        footerPart: true,
        modalMainClass: "_dashCommon_Modal __mid_sm_modal",
        modalTitle: "Add Other Document",
        submitTitle: "Add Document"
      };



    const columns = [
        { header: "Vehicle Number", accessor: "vehicle_number" },
        { header: "Document Type", accessor: "document_type" },
        { header: "Expiry Date", accessor: "expiry_date" },
    ];
    

    const rowListData = (data) => {
        const listArray = data?.map((item, i) => {
            return {
                ...item,
                vehicle_number: <> {item?.vehicle_number} </>,
                document_type:
                    <div className='_dtx_typeBx'>
                        <div className='_ntf_bx'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <rect y="0.435059" width="31.5649" height="31.5649" rx="3.84533" fill="#E8EEFE" />
                                <g opacity="0.8">
                                    <path d="M21.285 11.7697L18.3774 9.01889C18.0682 8.7263 17.6634 8.56519 17.2377 8.56519H11.702C10.7875 8.56519 10.0435 9.30919 10.0435 10.2237V22.3459C10.0435 23.2604 10.7875 24.0044 11.702 24.0044H20.1453C21.0598 24.0044 21.8038 23.2604 21.8038 22.3459V12.9745C21.8038 12.5207 21.6147 12.0815 21.285 11.7697ZM20.4064 12.1837H18.1551C18.0719 12.1837 18.0043 12.1161 18.0043 12.033V9.91117L20.4064 12.1837ZM20.1453 23.0997H11.702C11.2863 23.0997 10.9481 22.7615 10.9481 22.3459V10.2237C10.9481 9.80801 11.2863 9.46982 11.702 9.46982H17.0996V12.033C17.0996 12.6149 17.5731 13.0884 18.1551 13.0884H20.8991V22.3459C20.8991 22.7615 20.5609 23.0997 20.1453 23.0997Z" fill="#1344C2" />
                                    <path d="M19.1498 14.5957H12.5158C12.266 14.5957 12.0635 14.7982 12.0635 15.048C12.0635 15.2978 12.266 15.5003 12.5158 15.5003H19.1498C19.3996 15.5003 19.6021 15.2978 19.6021 15.048C19.6021 14.7982 19.3996 14.5957 19.1498 14.5957Z" fill="#1344C2" />
                                    <path d="M19.1498 17.0083H12.5158C12.266 17.0083 12.0635 17.2108 12.0635 17.4606C12.0635 17.7104 12.266 17.9129 12.5158 17.9129H19.1498C19.3996 17.9129 19.6021 17.7104 19.6021 17.4606C19.6021 17.2108 19.3996 17.0083 19.1498 17.0083Z" fill="#1344C2" />
                                    <path d="M14.7086 19.4211H12.5158C12.266 19.4211 12.0635 19.6237 12.0635 19.8735C12.0635 20.1233 12.266 20.3258 12.5158 20.3258H14.7086C14.9584 20.3258 15.161 20.1233 15.161 19.8735C15.161 19.6237 14.9584 19.4211 14.7086 19.4211Z" fill="#1344C2" />
                                </g>
                            </svg>
                            <div className='__ntfRl'>1</div>
                        </div>

                        <div className='__dtfTx_tx'>{item?.document_type}</div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
                            <path opacity="0.5" d="M1.25 13C0.90625 13 0.612083 12.8789 0.3675 12.6366C0.122917 12.3944 0.000416667 12.1028 0 11.7619V5.57143C0 5.23095 0.1225 4.93959 0.3675 4.69733C0.6125 4.45508 0.906667 4.33375 1.25 4.33333H1.875V3.09524C1.875 2.23889 2.17979 1.50903 2.78937 0.905667C3.39896 0.302302 4.13583 0.000413121 5 4.22558e-07C5.86417 -0.000412276 6.60125 0.301477 7.21125 0.905667C7.82125 1.50986 8.12583 2.23971 8.125 3.09524V4.33333H8.75C9.09375 4.33333 9.38812 4.45467 9.63312 4.69733C9.87812 4.94 10.0004 5.23137 10 5.57143V11.7619C10 12.1024 9.87771 12.394 9.63312 12.6366C9.38854 12.8793 9.09417 13.0004 8.75 13H1.25ZM1.25 11.7619H8.75V5.57143H1.25V11.7619ZM5 9.90476C5.34375 9.90476 5.63812 9.78364 5.88312 9.54138C6.12812 9.29913 6.25042 9.00756 6.25 8.66667C6.24958 8.32578 6.12729 8.03441 5.88312 7.79257C5.63896 7.55073 5.34458 7.4294 5 7.42857C4.65542 7.42775 4.36125 7.54908 4.1175 7.79257C3.87375 8.03606 3.75125 8.32743 3.75 8.66667C3.74875 9.00591 3.87125 9.29748 4.1175 9.54138C4.36375 9.78529 4.65792 9.90641 5 9.90476ZM3.125 4.33333H6.875V3.09524C6.875 2.57937 6.69271 2.14087 6.32812 1.77976C5.96354 1.41865 5.52083 1.2381 5 1.2381C4.47917 1.2381 4.03646 1.41865 3.67187 1.77976C3.30729 2.14087 3.125 2.57937 3.125 3.09524V4.33333Z" fill="black" />
                        </svg>
                    </div>,
                expiry_date: <div> <button className='__addDetail_'> <span>+ Add Details</span> </button> </div>,
            }
        });
        return listArray;
    };


    const getDataFuelStatement = async (comp_id, id) => {
        setLoading(true);
        try {
            let object = {
                company_id: comp_id,
                vehicle_id: id,
            }
            const res = await getFuelStatementData(object);
            if (res?.responseCode == 200) {
                setLoading(false);
                onDataFetch(res?.data?.account_detail);
                setDetailsData(res?.data?.account_detail);
                // setTotalItems(res?.data?.total_record);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        if (StorageService.getCompanyId()) {
            getDataFuelStatement(StorageService.getCompanyId(), id);
        }
    }, [StorageService.getCompanyId(), id])

    return (
        <>
        <div>
            <div className='__bt_seprate'>
                <div></div>
                <button className='anime-btn _placeBid_btn'  onClick={() => setOtherDocumentShow(true)}
                style={{ maxWidth: '162px', minHeight: '38px', fontWeight: '400' }}>Add Other Document</button>
            </div>

            <div className='__vhlDetail_tabLx'>
                <BootstrapTable
                    columns={columns}
                    data={rowListData(listData)}
                // isLoading={loading}
                />
            </div>
        </div>

        {otherDocumentShow && <AddOtherDocumentsModal {...otherDocumentsProps} />}

        </>
    )
}

export default VehicleDocuments