import { getTripRevenue } from '_services'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import StorageService from 'utils/StorageServices'


const Revenue = ({ freightId, tripDetais, callApi, userPermission }) => {

  const [revenueDetails,setRevenueDetails] = useState('')

  useEffect(()=>{
    getRevenue()
  },[])

  const getRevenue = async () =>{

    const payload = {
      company_id:StorageService.getCompanyId(),
      trip_id: freightId
  }

  try {
    const res = await getTripRevenue(payload)
    if(res?.responseCode ==200){
      setRevenueDetails(res?.data)
    }else{
      toast.error(res?.responseMessage)
    }
  } catch (error) {
    
  }
    
  }

  function formatCurrency(value) {
    const sign = value?.startsWith('-') ? '-' : value?.startsWith('+') ? '+' : '';
    const absoluteValue = Math.abs(parseFloat(value)).toFixed(2); // Get the absolute number and format to 2 decimal places
    return `${sign} ₹ ${absoluteValue}`;
  }
  return (
    <>
      <div className='_revenueBx'>
        <div className="accordion" id="revenuAccordion">
          
          {revenueDetails?.revenue?.map((item, index) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded={index === 0 ? "true" : "false"}
              aria-controls={`collapse${index}`}
            >
              <div className='_revnHead'>
                {item.label} <span>{item?.value?formatCurrency(item?.value):"0.00"}</span>
              </div>
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
            data-bs-parent="#revenuAccordion"
          >
            <div className="accordion-body">
              {item.child.map((child, childIndex) => (
                <div className='__frtTab_lets' key={childIndex}>
                  <span>{child.label}</span> <span>{child?.value?formatCurrency(child?.value):"0.00"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

          {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <div className='_revnHead'>Truck Charges (-) <span>₹ 50000.00</span></div>
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#revenuAccordion">
              <div className="accordion-body">
                <div className='__frtTab_lets'>
                  <span>Truck Freight (-)</span> <span>₹ 50000.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Claim Paid (+)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Claim Received (-)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Truck Expenses (-)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Truck Receipt (+)</span> <span>₹ 0.00</span>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <div className='_revnHead'>Other Expenses (-) <span>₹ 0.00</span></div>
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#revenuAccordion">
              <div className="accordion-body">
                <div className='__frtTab_lets'>
                  <span>Other Expenses (-)</span> <span>₹ 0.00</span>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <div className='_revnHead'>Other Receipt (+) <span>₹ 0.00</span></div>
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#revenuAccordion">
              <div className="accordion-body">
                <div className='__frtTab_lets'>
                  <span>Other Receipt (+)</span> <span>₹ 0.00</span>
                </div>
              </div>
            </div>
          </div> */}

          <div className='__lst_Tab_lets'>
            <span>Total Profile & Loss</span> <span>{revenueDetails?.total_profit_loss?.value?formatCurrency(revenueDetails?.total_profit_loss?.value):"0.00"}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Revenue