const Images = {
    LOGO: require("assets/img/logo.png"),
    LOGO2: require("assets/img/transport360Logo.svg"),
    HAMBERGER: require("assets/img/hamIcon.svg"),
    LOGUSER: require("assets/img/logedUser.svg"),
    LOADER: require("assets/img/loading.gif"),
    USERLIMG: require("assets/img/user_img.png"),
    BACKICON: require("assets/img/backArrow.png"),
    ASINDIVIDUAL: require("assets/img/asIndividual_icon.svg"),
    ASCOMPANY: require("assets/img/asCompany_icon.svg"),
    ASSUPLIER: require("assets/img/asSupplier_icon.svg"),
    CHECKFILLICON: require("assets/img/checkIcon.svg"),
    CHECKFILLICON2: require("assets/img/checkIcon2.svg"),
    PLACEICON: require("assets/img/place_icon.svg"),
    FLAGICON: require("assets/img/Ind_flag.png"),
    RISKICON: require("assets/img/medium-risk.png"),
    DELETEICON: require("assets/img/deleteIcon.svg"),
    ROUTTRUCKICON: require("assets/img/routeTruck.svg"),
    CLAIM:require("assets/img/claimIcon.png"),
    EDIT:require("assets/img/editIcon.png"),
    EXPLORE:require("assets/img/expIcon.png"),
    PUMP:require("assets/img/pumpIcon.png"),
    RUPEE:require("assets/img/rupeeIcon.png"),
    DASHPIECHART:require("assets/img/dashPie.png"),
    Wallet:require("assets/img/wallet.svg"),
    BRANCHICON: require("assets/img/branchPlace_icon.png"),
    PDFICON: require("assets/img/pdfIcon.png"),
    WARNING: require("assets/img/warning.png"),
    TRUCKICON: require("assets/img/Truck_black.svg"),
    LOADINGTRUCK:require("assets/img/transport_gif.png"),
    FAVOURITICON: require("assets/img/ic_favourite.svg"),
    CROWNICON: require("assets/img/premium-feature-crown.svg"),
    STARICON: require("assets/img/ic_star.svg"),
    HOLLOWSTARICON: require("assets/img/ic_hollow_star.svg"),
    CLIENTIMG1: require("assets/img/client.jpg"),
    GREENTICK: require("assets/img/tick-green.svg"),
    CALENDERICON: require("assets/img/calendarIcon.svg"),
    PLAYICON: require("assets/img/playIcon.svg"),
    VEHICLEIDLE: require("assets/img/vehicle-idle.png"),
    VEHICLEMOVING: require("assets/img/vehicle-moving.png"),
    CLOSEICONLOAD: require("assets/img/Group 633106.png"),
    BOOKMARK_LOAD: require("assets/img/Vector.png"),
    BOOKMARK_MARK_LOAD: require("assets/img/Group 633097.png"),
    RP_WALLET: require("assets/img/rupeeWallet.svg"),
    BANK_ICON: require("assets/img/bank.svg"),
    FUELCARD_ICON: require("assets/img/fuel_station.svg"),
    OTHER_TRANS_ICON: require("assets/img/other_transation.svg"),
    FASTAG_ICON: require("assets/img/fastag.svg"),
    DUMMYIMG : require("assets/img/dummy.webp"),
}

export default Images;