import { Images } from "constants";
import { UserContext } from "context";
import { login } from "features";
import { resetError } from "features/auth/authSlice";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/css/onboard.css";
import { allCities, allStates, getMasterData } from "_services";
import { City, State } from "country-state-city";
import StorageService from "utils/StorageServices/StorageServices";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.auth);
  const { setGenrateOtp, genrateOtp } = useContext(UserContext);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");


  useEffect(()=>{
    allCities()
    getAllMasterData()
    allStates()
  },[])

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (/^\d*$/.test(value)) {
      setPhone(value);
      setPhoneError("");
    }
  };

  const validatePhone = () => {
    if (phone.length !== 10) {
      setPhoneError("Phone number must be exactly 10 digits.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validatePhone()) {
      try {
        const res = await dispatch(login({ mobile_no: phone }));
        if (res.payload?.responseCode === 200) {
          navigate("/verify");
          toast.success(res.payload?.responseMessage);
          setGenrateOtp(true);
        } else {
          // Handle known errors (from rejectWithValue)
          console.log(res)
          toast.error(res.payload?.message);
        }
      } catch (error) { console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred. Please try again.");}
    }
  };

const getAllMasterData = async()=>{

    const postData = {};
    try {
        let response = await getMasterData(postData);
        if(response?.responseCode===200){
          StorageService.setLocalItem("master_data",JSON.stringify(response.data))
        }else{
          console.log(response?.message);
          toast.error(response?.message)
        }
    } catch (error) {
        console.error('Error fetching master data:', error);
        toast.error("An unexpected error occurred. Please try again.")
    } finally {
       
    }
} 
  // useEffect(() => {
  //     if (data && data.data && data.data != null) {
  //         setGenrateOtp(true)
  //         navigate('/verify')
  //     }
  // }, [data && data.data && data.data != null])

  return (
    <div className="_midOb_bx">
      <div className="_innrMid_bx">
        <img className="_logoOn_board" src={Images.LOGO} alt="" />

        <div className="_midOd_head">
          <h3>Welcome to Transport360</h3>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="_subFrm_fx">
            <div className="_numInput_bx mt-4">
              <input
                type="tel"
                placeholder="Enter phone number"
                value={phone}
                maxLength={10}
                onChange={handlePhoneChange}
                required
              />
              <label for="">
                {" "}
                <img src={Images.FLAGICON} alt="" /> +91
              </label>
            </div>
            {phoneError && <small className="text-danger">{phoneError}</small>}

            <button
              type="submit"
              disabled={loading}
              className="anime-btn _submitOn_board"
            >
              {loading ? "Sending otp..." : "Verify Mobile with OTP"}{" "}
            </button>
          </div>
        </form>
      </div>

      <div className="_midBx_foot">
        <p>
          By clicking on Verify you agree to our <a href="">Terms</a> and{" "}
          <a href="">Privacy Policy</a>
        </p>
        <p>© 2024 Eminence Infotech Private Limited. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Login;
