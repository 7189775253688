import React, { useEffect, useState } from 'react'
import { CommonModal, CustomSelect } from 'components'

const PermissionModal = (props) => {

    return (
        <div>
            <CommonModal {...props}>
                <div className='addLr_body mt-2'>
                    You don't have permissions.
                </div>
            </CommonModal>
        </div>
    )
}

export default PermissionModal
