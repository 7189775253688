import React, { useContext, useState } from 'react'
import { ButtonHeading, DriverDetailsTab, DriverTripTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import Ledger from '../Ledger';
import { UserContext } from 'context';
import { useLocation } from 'react-router-dom';


const DriverDetails = () => {

   
    const { isDriverName } = useContext(UserContext)
    const location = useLocation();
    const { data } = location.state || {};
    const queryParams = new URLSearchParams(location.search);
    const tabFromQuery = parseInt(queryParams.get('tab')) || 1;
    const [activeTab, setActiveTab] = useState(tabFromQuery);

    const tabProps = {
        tabList: data?.driver=="own"?constant.driverDetailsTabs : constant.driverDetailsTabsForMarket,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    return (
        <div className=''>
            <ButtonHeading title={isDriverName ? isDriverName : 'Loading...'} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <DriverDetailsTab />
                )}
                {(activeTab === 2 && data.driver=="own") && (
                    <Ledger type={3} />
                )}
            </div>
        </div>
    )
}

export default DriverDetails;