import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa6';
import toast from 'react-hot-toast';
import bidderImg from "../../../src/assets/img/bidderImg.png"
import { utils } from 'helper';
import StorageService from 'utils/StorageServices/StorageServices';
import { acceptConnectionRequest, rejectConnectionRequest, sendConnectionRequest, withDrawalConnectionRequest } from '_services';
import { UserContext } from 'context';
import moment from 'moment';

const CommonBox = (props) => {

  const loadingBarRef = useRef(null);
  let { element, commonProps, type, nextModal, callRejectRequest, callAcceptRequest, callWithdrawalRequest, loading, id, acceptLoading, rejectLoading } = props;
  let { isOpen, onClose } = commonProps;
  const { connectionDetailsGlobal, setConnectionDetailsGlobal } = useContext(UserContext);

  // const createAtDate = useMemo(() => {
  //   const postDate = moment(element?.createdAt).utc().local();
  //   const currentDate = moment();
  //   const displayDate =
  //     currentDate.diff(postDate, "days") > 1
  //       ? postDate.format("Do MMMM")
  //       : postDate.fromNow();
  //   if (displayDate.startsWith("in")) {
  //     let date = displayDate?.split("in ")[1];
  //     return date + " ago";
  //   }
  //   return displayDate;
  // }, [element?.createdAt]);

  const createAtDate = useMemo(() => {
    const postDate = moment(element?.createdAt).utc().local();
    const currentDate = moment();

    const diffInYears = currentDate.diff(postDate, "years");
    const diffInMonths = currentDate.diff(postDate, "months");
    const diffInDays = currentDate.diff(postDate, "days");

    let displayDate;

    if (diffInYears >= 1) {
      displayDate = diffInYears === 1 ? "1 year ago" : `${diffInYears} years ago`;
    } else if (diffInMonths >= 1) {
      displayDate = diffInMonths === 1 ? "1 month ago" : `${diffInMonths} months ago`;
    } else if (diffInDays >= 1) {
      displayDate = diffInDays === 1 ? "1 day ago" : `${diffInDays} days ago`;
    } else {
      displayDate = postDate.fromNow();
    }

    return displayDate;
  }, [element?.createdAt]);

  return (
    <div className='__inr_conectBx' id={id}>
      <div className='_bidR_bxs' onClick={(e) => onClose(element)}>
        <div className='__bidR_usrBx'>
          <span className='_bidR_verifyIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
            </svg>
          </span>
          <div>
            {element?.company ? <div className='_fstLetter'> {(element?.company[0]).toUpperCase()} </div> : ''}
            {/* <img src={bidderImg} alt="" /> */}
          </div>

          {(element?.rating && element?.rating > 0) ?
            <div className='_ratingBx'>
              <FaStar />
              <span> {element?.rating} </span>
            </div> :
            <div className='_ratingBx new_starCls'>
              <span> New </span>
            </div>
          }

        </div>
        <div className='w-100'>
          <p className='tl_txt'> {element?.company ? utils.firstLetterCapital(element?.company) : '-'} </p>
          <p className='tsm_txt'>{element?.city?.label}, {element?.state?.label}</p>
        </div>
      </div>

      <div className='parent_connectInvi' onClick={(e) => onClose(element)}>
        <p className='xst_coIng'>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <g clip-path="url(#clip0_10757_3340)">
              <path d="M7.5 0C5.31917 0 3.54492 1.77425 3.54492 3.95508C3.54492 6.13591 5.31917 7.91016 7.5 7.91016C9.68083 7.91016 11.4551 6.13591 11.4551 3.95508C11.4551 1.77425 9.68083 0 7.5 0Z" fill="#173C9A" />
              <path d="M12.4209 10.494C11.3381 9.39454 9.90261 8.78906 8.37891 8.78906H6.62109C5.09742 8.78906 3.66193 9.39454 2.57912 10.494C1.50161 11.5881 0.908203 13.0322 0.908203 14.5605C0.908203 14.8032 1.10496 15 1.34766 15H13.6523C13.895 15 14.0918 14.8032 14.0918 14.5605C14.0918 13.0322 13.4984 11.5881 12.4209 10.494Z" fill="#173C9A" />
            </g>
            <defs>
              <clipPath id="clip0_10757_3340">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg> {" "}
          <span>
            {
              element?.company_type?.length > 0 ? element?.company_type?.map((el, indxx) => {
                return (
                  <React.Fragment key={indxx}> {el?.name ? utils.firstLetterCapital(el?.name, 0, 5) : '-'}, </React.Fragment>
                )
              }) : '-'
            }
          </span>
        </p>
        <p className='xst_coIng'>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
            <path d="M14.3227 3.28801H12.0613V0.272705H1.50767C0.674665 0.272705 0 0.94737 0 1.78038V10.0725H1.50767C1.50767 11.3201 2.52156 12.334 3.76916 12.334C5.01676 12.334 6.03064 11.3201 6.03064 10.0725H10.5536C10.5536 11.3201 11.5675 12.334 12.8151 12.334C14.0627 12.334 15.0766 11.3201 15.0766 10.0725H16.5843V6.30335L14.3227 3.28801ZM3.76916 11.2032C3.14347 11.2032 2.63841 10.6982 2.63841 10.0725C2.63841 9.44678 3.14347 8.94173 3.76916 8.94173C4.39485 8.94173 4.8999 9.44678 4.8999 10.0725C4.8999 10.6982 4.39481 11.2032 3.76916 11.2032ZM12.8151 11.2032C12.1894 11.2032 11.6844 10.6982 11.6844 10.0725C11.6844 9.44678 12.1894 8.94173 12.8151 8.94173C13.4408 8.94173 13.9458 9.44678 13.9458 10.0725C13.9458 10.6982 13.4408 11.2032 12.8151 11.2032ZM12.0613 6.30335V4.41879H13.9458L15.4271 6.30335H12.0613Z" fill="#173C9A" />
          </svg> {" "}
          <span>
          {
            element?.lorry_type_id?.length > 0 ? element?.lorry_type_id?.map((el) => {
              return (
                <> {el?.lorry_type ? utils.firstLetterCapital(el?.lorry_type) : '-'}, </>
              )
            }) : '-'
          }
          </span>
        </p>
        <p className='xst_coIng'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M6.47883 6.3802L0.189766 3.73218C0.099668 3.69422 0 3.76039 0 3.85818V10.9374C0 11.1163 0.10891 11.2772 0.275023 11.3436L6.375 13.7836C6.4648 13.8196 6.5625 13.7534 6.5625 13.6567V6.5062C6.5625 6.45118 6.52952 6.40155 6.47883 6.3802Z" fill="#173C9A" />
            <path d="M7.05311 5.62794L13.132 3.0684C13.2413 3.02241 13.2442 2.86863 13.1368 2.8185L7.18507 0.0410361C7.06779 -0.0136787 6.93233 -0.0136787 6.81505 0.0410361L0.863333 2.8185C0.755899 2.86863 0.758825 3.02241 0.868091 3.0684L6.94702 5.62794C6.98092 5.64224 7.0192 5.64224 7.05311 5.62794Z" fill="#173C9A" />
            <path d="M7.4375 6.5062V13.6567C7.4375 13.7534 7.5352 13.8196 7.625 13.7836L13.725 11.3436C13.8911 11.2772 14 11.1163 14 10.9374V3.85818C14 3.76039 13.9003 3.69422 13.8102 3.73218L7.52117 6.3802C7.47048 6.40155 7.4375 6.45118 7.4375 6.5062Z" fill="#173C9A" />
          </svg> {" "} {" "}
          <span>
          {
            element?.cities_routes?.length > 0 ? element?.cities_routes?.map((el) => {
              return (
                <> {el?.name ? utils.firstLetterCapital(el?.name) : ''}, </>
              )
            }) : '-'
          }
          {
            element?.states_routes?.length > 0 ? element?.states_routes?.map((el) => {
              return (
                <> {el?.name ? utils.firstLetterCapital(el?.name) : ''}, </>
              )
            }) : ''
          }
          </span>
        </p>
      </div>

      {/* <div className='__connDetail_main'>
        <div className='__connDetail'>
          <span>Joined</span>
          <p> {createAtDate} </p>
        </div>

        <div className='__connDetail'>
          <span>Connections</span>
          <p> {(element?.connection?.connected?.length > 0) ? element?.connection?.connected?.length : 0} </p>
        </div>

        <div className='__connDetail'>
          <span>Load posted</span>
          <p>{element?.load_count ? element?.load_count : 0}</p>
        </div>
      </div> */}

      {type === 1 && (
        <div className='_bottomBtn_bar' onClick={(e) => onClose(element)}>
          <div className='singleDiv'><button className='_viewPrf_btn'>View Profile</button></div>
        </div>
      )}

      {type === 2 && (
        <div className='_bottomBtn_bar'>
          <div className='one_3rdDiv'><button className='_rejctPrf_btn' onClick={(e) => callRejectRequest(element)} disabled={rejectLoading}> {rejectLoading ? 'Loading...' : 'Reject'} </button></div>
          <div className='twiceDiv'><button className='_acceptPrf_btn' onClick={(e) => callAcceptRequest(element)} disabled={acceptLoading}> {acceptLoading ? 'Loading...' : 'Accept'} </button></div>
        </div>
      )}

      {type === 3 && (
        <div className='_bottomBtn_bar'>
          <div className='one_3rdDiv'><button className='_viewPrf_btn' onClick={(e) => onClose(element)} >View Profile</button></div>
          <div className='twiceDiv'><button className='_rejctPrf_btn' onClick={(e) => callWithdrawalRequest(element)} disabled={loading}>{loading ? 'Loading...' : 'Withdraw Request'}</button></div>
        </div>
      )}

      {type === 4 && (
        <div className='_bottomBtn_bar'>
          <div className='one_3rdDiv'><button className='_viewPrf_btn' onClick={(e) => onClose(element)}>View Profile</button></div>
          <div className='twiceDiv'><button className='_acceptPrf_btn' onClick={(e) => { nextModal(element); setConnectionDetailsGlobal(element); }} disabled={loading}>{loading ? 'Loading...' : 'Connect'}</button></div>
        </div>
      )}
    </div>
  )
}

export default React.memo(CommonBox)