import React, { useContext, useEffect, useState } from "react";
import {
  createNewLr,
  fetchLrNumberData,
  getDataLrDetails,
  updateNewLr,
} from "_services/accountsServices/lrServices";
import { getBranchDropdownListData } from "_services/dropdownServices/dropdownService";
import {
  CommonDatePicker,
  CommonInput,
  CommonSwitch,
  CustomSelect,
} from "components";
import { UserContext } from "context";
import { utils } from "helper";
import AddNewSeriesModal from "modals/AddNewSeriesModal";
import LrSeriesModal from "modals/LrSeriesModal";
import StorageService from "utils/StorageServices/StorageServices";
import SelectUnitLrModal from "modals/SelectUnitLrModal";
import SelectConsigneeLrModal from "modals/SelectConsigneeLrModal";
import AddEditConsignee from "modals/AddEditConsignee";
import AddEditUnitModal from "modals/AddEditUnitModal";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FaTrash } from "react-icons/fa";
import { getMaterialListData } from "_services/materialServices/materialServices";
import { getMaterialCategoryListData } from "_services/materialServices/materialCategoryServices";
import AddItemModal from "modals/AddItemModal";
import AddItemGroupModal from "modals/AddItemGroupModal";
import AddEditBranch from "modals/AddEditBranch";
import { fetchSingleUnitData } from "_services/accountsServices/unitServices";
import { fetchConsigneeDetailsData } from "_services/accountsServices/consigneeServices";
import moment from "moment";
import { getAddressDetailsApi } from "_services/accountsServices/addressBookService";
import AddAddressBook from "modals/AddAddressBook";

const AddEditLrModal = (props) => {
  let { isOpen, onClose, callBackDataLr, type, callGetData = () => { } } = props;

  const formObj = {
    invoice_date: "",
    invoice_time: "",
    consignor_id: "",
    branch_id: {},
    from_location: {},
    from_state: "",
    to_location: {},
    to_state: "",
    unit_id: "",
    consignee_id: "",
    invoice_number: "",
    loading_slip_number: "",
    gst_invoice_number: "",
    po_number: "",
    lr_number: "",
    mlr_number: "",
    company_id: StorageService.getCompanyId(),
    eway_bill_number: null,
    eway_bill_date: "",
    eway_expiry_date: "",
    freight_type: "",
    total_qty: "",
    rate: "",
    total_amount: "",
    lr_material_list:
      isOpen?.permission_at_item?.item_group_hsn?.view === 1
        ? [
          {
            name: "",
            material_code: "",
            hsn_code: null,
            material_category_id: "",
            qty: 0,
            qty_type: 1,
            weight: 0,
            weight_type: 1,
            kot: 0,
            amount: 0,
          },
        ]
        : [],
  };

  const {
    masterData,
    cities,
    modalZIndex,
    setModalZIndex,
    setIsMaterialIndex,
    isMaterialIndex,
    setFromStationToUnit,
    fromStationToUnit,
  } = useContext(UserContext);
  const [formValue, setFormValue] = useState(formObj);
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [unitInLR, setUnitInLR] = useState(false);
  const [consigneeInLR, setConsigneeInLR] = useState(false);
  const [dropdownBranchList, setDropdownBranchList] = useState([]);
  const [isOpenLrSeries, setIsOpenLrSeries] = useState(false);
  const [showAddNewLrSeries, setShowAddNewLrSeries] = useState(false);
  const [isSetLrSeries, setIsSetLrSeries] = useState("");
  const [isLrNumber, setIsLrNumber] = useState("");
  const [isEditLR, setIsEditLR] = useState(false);
  const [addNewConsignee, setAddNewConsignee] = useState(false);
  const [selectConsigneeData, setSelectConsigneeData] = useState({});
  const [addNewUnit, setAddNewUnit] = useState(false);
  const [selectUnitData, setSelectUnitData] = useState({});
  const [materialList, setMaterialList] = useState([]);
  const [materialCategoryList, setMaterialCategoryList] = useState([]);
  const [showAddItem, setShowAddItem] = useState(false);
  const [showAddGroupItem, setShowAddGroupItem] = useState(false);
  const [addNewBranch, setAddNewBranch] = useState(false);
  const [dataSingleConsignee, setDataSingleConsignee] = useState({});
  const [dataSingleUnit, setDataSingleUnit] = useState({});
  const [ewayBillRadioToggle, setEwayBillRadioToggle] = useState(false);
  const [freightRadioToggle, setFreightRadioToggle] = useState(true);
  const [startDate, setStartDate] = useState(
    new Date()
  );
  const [ewayBillStartDate, setEwayBillStartDate] = useState(
    new Date()
  )
  // const [ewayBillEndDate, setEwayBillEndDate] = useState(
  //   moment(new Date()).hours(23).minutes(59).add(2, 'days').toDate()
  // )
  const [isEditMaterialTable, setIsEditMaterialTable] = useState([]);

  const createDynamicSchema = () => {
    return Yup.object().shape({
      invoice_date: Yup.string().required("Required * "),
      from_location: Yup.object().shape({
        label: Yup.string().required("Required * "),
      }),
      to_location: Yup.object().shape({
        label: Yup.string().required("Required * "),
      }),
      branch_id: Yup.object().shape({
        label: Yup.string().required("Required * "),
      }),
      invoice_number:
        isOpen?.permission_at_invoice_number?.view === 1 &&
          isOpen?.permission_at_invoice_number?.mandatory === 1
          ? Yup.string().min(isOpen?.permission_at_invoice_number?.min ? isOpen?.permission_at_invoice_number?.min : 8, `Must be more than ${isOpen?.permission_at_invoice_number?.min} digit `).max(isOpen?.permission_at_invoice_number?.max ? isOpen?.permission_at_invoice_number?.max : 12, `Must be less than ${isOpen?.permission_at_invoice_number?.max}`).required("Required * ")
          : Yup.string().notRequired(),
      loading_slip_number:
        isOpen?.permission_at_field2?.view === 1 &&
          isOpen?.permission_at_field2?.mandatory === 1
          ? Yup.string().min(isOpen?.permission_at_field2?.min ? isOpen?.permission_at_field2?.min : 8, `Must be more than ${isOpen?.permission_at_field2?.min} digit `).max(isOpen?.permission_at_field2?.max ? isOpen?.permission_at_field2?.max : 12, `Must be less than ${isOpen?.permission_at_field2?.max}`).required("Required * ")
          : Yup.string().notRequired(),
      gst_invoice_number:
        isOpen?.permission_at_field3?.view === 1 &&
          isOpen?.permission_at_field3?.mandatory === 1
          ? Yup.string().min(isOpen?.permission_at_field3?.min ? isOpen?.permission_at_field3?.min : 8, `Must be more than ${isOpen?.permission_at_field3?.min} digit `).max(isOpen?.permission_at_field3?.max ? isOpen?.permission_at_field3?.max : 12, `Must be less than ${isOpen?.permission_at_field3?.max}`).required("Required * ")
          : Yup.string().notRequired(),
      po_number:
        isOpen?.permission_at_field4?.view === 1 &&
          isOpen?.permission_at_field4?.mandatory === 1
          ? Yup.string().min(isOpen?.permission_at_field4?.min ? isOpen?.permission_at_field4?.min : 8, `Must be more than ${isOpen?.permission_at_field4?.min} digit `).max(isOpen?.permission_at_field4?.max ? isOpen?.permission_at_field4?.max : 12, `Must be less than ${isOpen?.permission_at_field4?.max}`).required("Required * ")
          : Yup.string().notRequired(),
      mlr_number:
        isOpen?.permission_at_field5?.view === 1 &&
          isOpen?.permission_at_field5?.mandatory === 1
          ? Yup.string().min(isOpen?.permission_at_field5?.min ? isOpen?.permission_at_field5?.min : 8, `Must be more than ${isOpen?.permission_at_field5?.min} digit `).max(isOpen?.permission_at_field5?.max ? isOpen?.permission_at_field5?.max : 12, `Must be less than ${isOpen?.permission_at_field5?.max}`).required("Required * ")
          : Yup.string().notRequired(),
      eway_bill_number:
        isOpen?.permission_at_lr?.eway_bill_mandatory === 1
          ? Yup.string().required("Required * ")
          : Yup.string().notRequired(),
      unit_id:
        isOpen?.permission_at_lr?.unit_mandatory === 1
          ? Yup.string().required("Required * ")
          : Yup.string().notRequired(),
      consignee_id:
        isOpen?.permission_at_lr?.consignee_mandatory === 1
          ? Yup.string().required("Required * ")
          : Yup.string().notRequired(),
      lr_material_list:
        isOpen?.permission_at_item?.item_group_hsn?.view === 1
          ? Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Required * "),
              // hsn_code: Yup.string().required("Required * "),
              material_category_id: Yup.string().required("Required * "),
              qty:
                isOpen?.permission_at_item?.qty?.mandatory === 1
                  ? Yup.string().required("Required * ")
                  : Yup.string().notRequired(),
              weight:
                isOpen?.permission_at_item?.weight?.mandatory === 1
                  ? Yup.string().required("Required * ")
                  : Yup.string().notRequired(),
              kot:
                isOpen?.permission_at_item?.kot?.mandatory === 1
                  ? Yup.string().required("Required * ")
                  : Yup.string().notRequired(),
            })
          )
          : Yup.array().of(
            Yup.object().shape({
              name: Yup.string(),
              hsn_code: Yup.string(),
              material_category_id: Yup.string(),
            })
          ),
    });
  };

  const schema = createDynamicSchema();

  const handleOnChange = async (name, value) => {
    const stateObj = { ...formValue, [name]: value };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.dynamicCheckFormError(
        stateObj,
        schema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const onhandleSelect = async (item, name) => {
    const stateObj = { ...formValue, [name]: item };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.dynamicCheckFormError(
        stateObj,
        schema
      );
      if (validationResult === true) {
        setFormError({});
      } else {
        setFormError(validationResult);
      }
    }
  };

  const apiCallingFunction = async () => {
    try {
      if (!!isOpen?.isEditLr) {
        let object = {
          ...formValue,
          contract_id: isOpen?.contract?._id ? isOpen?.contract?._id : '',
          lr_id: formValue?._id,
          branch_id: formValue?.branch_id?.value,
          freight_type: formValue?.freight_type?.value,
          from_location: formValue?.from_location?.name,
          to_location: formValue?.to_location?.name,
          unit_id: selectUnitData?.value,
          consignee_id: selectConsigneeData?.value,
          consignor_id: formValue?.consignor_id,
          total_qty: (formValue?.freight_type?.value != 6) ? (formValue?.total_qty != null ? formValue?.total_qty : '') : '',
          rate: (formValue?.freight_type?.value != 6) ? (formValue?.rate != null ? formValue?.rate : '') : '',
          total_amount: (formValue?.freight_type?.value != 6) ? ((formValue.rate * formValue.total_qty > 0 && formValue.rate * formValue.total_qty != null) ? formValue.rate * formValue.total_qty : '') : ((formValue.total_amount > 0 && formValue?.total_amount != null) ? formValue.total_amount : ''),
          // total_amount: formValue?.total_amount != null ? formValue?.total_amount : '',
          lr_material_list: formValue?.lr_material_list?.map((el) => {
            return {
              ...el,
              hsn_code: el?.hsn_code != '' ? el?.hsn_code : null,
              kot: el?.kot != null ? el?.kot : 0,
              qty: el?.qty != null ? el?.qty : 0,
              weight: el?.weight != null ? el?.weight : 0,
            }
          }),
          invoice_time: new Date().toLocaleString("en-US", {
            timeStyle: "short",
          }),
        };
        delete object.lr_status;
        delete object.created_by;
        delete object.total_kot;
        delete object.createdAt;
        delete object.updatedAt;
        delete object.lr_process;
        delete object.pod_file;
        delete object.total_weight;
        delete object.updated_by;
        delete object.pod_remark;
        delete object.pod_date;
        delete object.pod_status;
        delete object.lr_number;
        delete object.trip_id;
        delete object._id;
        delete object.plan_id;
        delete object.dispatch_date;
        delete object.sap_invoice_number;
        delete object.gst_number;
        delete object.eway_bill_status;
        delete object.remarks;
        delete object.is_deleted;
        delete object.from;
        delete object.to;
        delete object.lr_freight;
        delete object.billraise_id;
        delete object.is_upgrade;
        delete object.contract;
        const res = await updateNewLr(object);
        if (!!res) {
          if (type === "from_trip") {
            callBackDataLr(res?.data?.lr)
          }
          setFormValue({});
          setLoading(false);
          toast.success("LR Update Successfully");
          callGetData();
          onClose();
        } else {
          toast.error("Error");
          setLoading(false);
        }
      } else {
        let object = {
          ...formValue,
          contract_id: isOpen?.contract?._id ? isOpen?.contract?._id : '',
          branch_id: formValue?.branch_id?.value,
          freight_type: formValue?.freight_type?.value,
          from_location: formValue?.from_location?.name,
          from_state: formValue?.from_location?.stateCode,
          to_location: formValue?.to_location?.name,
          to_state: formValue?.to_location?.stateCode,
          unit_id: selectUnitData?.value,
          consignee_id: selectConsigneeData?.value,
          total_qty: (formValue?.freight_type?.value != 6) ? (formValue?.total_qty != null ? formValue?.total_qty : '') : '',
          rate: (formValue?.freight_type?.value != 6) ? (formValue?.rate != null ? formValue?.rate : '') : '',
          total_amount: formValue?.total_amount != null ? formValue?.total_amount : '',
          lr_material_list: formValue?.lr_material_list?.map((el) => {
            return {
              ...el,
              hsn_code: el?.hsn_code != '' ? el?.hsn_code : null,
              kot: el?.kot != null ? el?.kot : 0,
              qty: el?.qty != null ? el?.qty : 0,
              weight: el?.weight != null ? el?.weight : 0,
            }
          }),
          invoice_time: new Date().toLocaleString("en-US", {
            timeStyle: "short",
          }),
        };
        delete object.lr_number;
        delete object.contract;
        const res = await createNewLr(object);
        if (!!res) {
          if (type === "from_trip") {
            callBackDataLr(res?.data?.lr)
          }
          setFormValue({});
          setLoading(false);
          toast.success("LR Created Successfully");
          callGetData();
          onClose();
        } else {
          toast.error("Error");
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error("Error");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = await utils.dynamicCheckFormError(
      formValue,
      schema
    );
    if (utils?.isObjectKeyEmpty(validationResult)) {
      setLoading(true);
      apiCallingFunction();
      setFormError("");
    } else {
      setFormError(validationResult);
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormValue((s) => ({
      ...s,
      eway_bill_date: moment(new Date()).hours(16).minutes(30).toDate(),
      // eway_expiry_date: getDateTwoDaysAfterDate(new Date()),
      consignor_id: isOpen?._id,
      branch_id: dropdownBranchList?.map((el) => {
        return {
          label: el?.branch,
          value: el?._id,
        };
      })[0],
    }));
  }, [dropdownBranchList]);

  useEffect(() => {
    setFormValue((s) => ({
      ...s,
      eway_bill_date: moment(new Date(ewayBillStartDate)).hours(16).minutes(30).toDate(),
    }));
  }, [ewayBillStartDate]);

  const getDropdownDataBranchList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
      };
      const res = await getBranchDropdownListData(object);
      if (res?.responseCode == 200) {
        setDropdownBranchList(res?.data?.branch_list);
      }
    } catch (error) {
      // throw error;
    }
  };

  const getTableMaterialListData = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
      };
      const res = await getMaterialListData(object);
      if (res?.responseCode == 200) {
        setMaterialList(res?.data?.materials);
      }
    } catch (error) {
      // throw error;
    }
  };

  const getTableMaterialCategoryListData = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
      };
      const res = await getMaterialCategoryListData(object);
      if (res?.responseCode == 200) {
        setMaterialCategoryList(res?.data?.material_categorys);
      }
    } catch (error) {
      // throw error;
    }
  };

  const getDataLrNumber = async (id) => {
    try {
      let object = {
        branch_id: id,
      };
      const res = await fetchLrNumberData(object);
      if (res?.responseCode == 200) {
        setFormValue((s) => ({ ...s, lr_number: res?.data?.lr_number }));
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (formValue?.branch_id?.value) {
      getDataLrNumber(formValue?.branch_id?.value);
    }
  }, [formValue?.branch_id, isSetLrSeries]);

  let unitInLrModal = {
    isOpen: unitInLR,
    closeModal: (e) => setUnitInLR(false),
    nextModal: (e) => setAddNewUnit(true),
    selectUnit: (e) => setSelectUnitData(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Select Address",
    submitTitle: "Submit",
  };

  let consigneeInLrModal = {
    isOpen: consigneeInLR,
    closeModal: (e) => setConsigneeInLR(false),
    nextModal: (e) => setAddNewConsignee(true),
    selectConsignee: (e) => setSelectConsigneeData(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Select Address",
    submitTitle: "Submit",
  };

  const handleLrFunctionality = () => {
    if (!isOpen?.isEditLr) {
      setIsOpenLrSeries(true);
    }
  };

  let isLrSeriesModalProps = {
    isOpen: isOpenLrSeries,
    closeModal: () => setIsOpenLrSeries(!isOpenLrSeries),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Select LR Series",
    formData: formValue,
    submitTitle: "Save",
    nextModal: (e) => setShowAddNewLrSeries(true),
    setIsEditLR: (e) => setIsEditLR(e),
    setLrSeries: (e) => setIsSetLrSeries(e),
    setIsLrNumber: (e) => setIsLrNumber(e),
  };

  let addNewSeriesModalProps = {
    isOpen: showAddNewLrSeries,
    closeModal: () => setShowAddNewLrSeries(!showAddNewLrSeries),
    headerPart: false,
    footerPart: false,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add New Series",
    submitTitle: "Save",
    formData: formValue,
    isEditLR: isEditLR,
    onSubmit: (e) => setShowAddNewLrSeries(false),
    previosModal: () => setIsOpenLrSeries(!isOpenLrSeries),
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const getSingleConsigneeData = async (id) => {
    try {
      let object = {
        addressbook_id: id,
        company_id: StorageService.getCompanyId(),
      };

      // const res = await fetchConsigneeDetailsData(object); // change api unit details to address details

      const res = await getAddressDetailsApi(object); // change api unit details to address details

      if (res?.responseCode == 200) {
        setDataSingleConsignee(res?.data?.book_detail);
        // handleOnChange("to_location", {...res?.data?.book_detail?.city, name: res?.data?.book_detail?.city?.label});
      }
    } catch (error) {
      throw error;
    }
  };

  const getSingleUnitData = async (id) => {
    try {
      let object = {
        addressbook_id: id,
        company_id: StorageService.getCompanyId(),
      };
      // const res = await fetchSingleUnitData(object); // change api unit details to address details

      const res = await getAddressDetailsApi(object); // change api unit details to address details
      if (res?.responseCode == 200) {
        setDataSingleUnit(res?.data?.book_detail);
        // handleOnChange("from_location", {...res?.data?.book_detail?.city, name: res?.data?.book_detail?.city?.label});
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (selectConsigneeData?.value) {
      handleOnChange("consignee_id", selectConsigneeData?.value);
      getSingleConsigneeData(selectConsigneeData?.value);
    }
  }, [selectConsigneeData?.value]);

  useEffect(() => {
    if (selectUnitData?.value) {
      handleOnChange("unit_id", selectUnitData?.value);
      getSingleUnitData(selectUnitData?.value);
    }
  }, [selectUnitData?.value]);

  useEffect(() => {
    getDropdownDataBranchList();
    // getTableMaterialListData();
    // getTableMaterialCategoryListData();
  }, []);

  useEffect(() => {
    getTableMaterialListData();
    getTableMaterialCategoryListData();
  }, [showAddItem, showAddGroupItem]);

  const addNewTableRowHandler = () => {
    setFormValue((state) => ({
      ...state,
      lr_material_list: [
        ...formValue.lr_material_list,
        {
          name: "",
          material_code: "",
          hsn_code: null,
          material_category_id: "",
          qty: 0,
          qty_type: 1,
          weight: 0,
          weight_type: 1,
          kot: 0,
          amount: 0,
        },
      ],
    }));
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...formValue?.lr_material_list];
    list.splice(index, 1);
    setFormValue((state) => ({ ...state, lr_material_list: list }));
  };

  const handleInputChange = async (e, index) => {
    const { name, value } = e.target;

    const list = [...formValue?.lr_material_list];
    list[index][name] = value;

    const stateObj = { ...formValue, lr_material_list: list };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const error = await utils.checkFormError(stateObj, schema);
      setFormError(error);
    }
  };

  const handleInputChangeSelect1 = async (item, name, index) => {
    const list = [...formValue?.lr_material_list];
    list[index][name] = item?.label;
    list[index]["material_code"] = item?.value;

    const stateObj = { ...formValue, lr_material_list: list };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.dynamicCheckFormError(
        stateObj,
        schema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleInputChangeSelect2 = async (item, name, index) => {
    const list = [...formValue?.lr_material_list];
    list[index][name] = item?.value;

    const stateObj = { ...formValue, lr_material_list: list };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.dynamicCheckFormError(
        stateObj,
        schema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  let firstAddItemModal = {
    isOpen: showAddItem,
    materialCategoryList: materialCategoryList,
    closeModal: (e) => setShowAddItem(false),
    isCurrentModal: (e) => setShowAddItem(false),
    nextModal: (e) => setShowAddGroupItem(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Item",
    submitTitle: "Add Item",
    modalZIndex: modalZIndex,
  };

  const closeAllModal = async (e) => {
    setModalZIndex(null);
    setShowAddItem(false);
    setShowAddGroupItem(false);
  }

  let firstAddGroupItemModal = {
    isOpen: showAddGroupItem,
    closeModal: (e) => closeAllModal(e),
    isCurrentModal: (e) => setShowAddGroupItem(false),
    previousModal: (e) => setShowAddItem(true),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Item Group",
    submitTitle: "Add Item Group",
  };

  useEffect(() => {
    if (
      isOpen?.permission_at_item?.item_group_hsn?.view === 1 &&
      materialList.length > 0 &&
      materialCategoryList.length > 0 &&
      !isOpen?.isEditLr
    ) {
      const list = [...formValue?.lr_material_list];
      list.splice(isMaterialIndex, 1, {
        ...list[isMaterialIndex],
        name: materialList[0]?.name,
        material_code: materialList[0]?.material_code,
        material_category_id: materialCategoryList[0]?._id,
        hsn_code: materialList[0]?.hsn_code ? materialList[0]?.hsn_code : '',
        qty_type: materialList[0]?.qty_type,
        qty: materialList[0]?.qty,
        weight: materialList[0]?.weight ? materialList[0]?.weight : 0,
        weight_type: materialList[0]?.weight_type,
        kot: materialList[0]?.kot ? materialList[0]?.kot : 0,
        amount: materialList[0]?.amount,
      });
      setFormValue((s) => ({ ...s, lr_material_list: list }));
    }
  }, [
    isOpen?.permission_at_item?.item_group_hsn?.view,
    materialList,
    materialCategoryList,
    !showAddGroupItem,
  ]);

  // edit lr table condition - start

  useEffect(() => {
    if (
      isOpen?.permission_at_item?.item_group_hsn?.view === 1 &&
      materialList.length > 0 &&
      materialCategoryList.length > 0 &&
      isEditMaterialTable?.length > 0 &&
      isMaterialIndex
    ) {
      const _list = [...isEditMaterialTable];
      _list.splice(isMaterialIndex, 1, {
        ..._list[isMaterialIndex],
        name: materialList[0]?.name,
        material_code: materialList[0]?.material_code,
        material_category_id: materialCategoryList[0]?._id,
        hsn_code: materialList[0]?.hsn_code ? materialList[0]?.hsn_code : '',
        qty_type: materialList[0]?.qty_type,
        qty: isEditMaterialTable[0]?.qty,
        weight: materialList[0]?.weight,
        weight_type: materialList[0]?.weight_type,
        kot: materialList[0]?.kot,
        amount: isEditMaterialTable[0]?.amount,
      });
      setFormValue((s) => ({ ...s, lr_material_list: _list }));
    }
  }, [
    isOpen?.permission_at_item?.item_group_hsn?.view,
    materialList,
    materialCategoryList,
    isEditMaterialTable,
    // isMaterialIndex,
  ]);

  // edit lr table condition - end

  const totalCoutFunction = (name) => {
    if (formValue?.lr_material_list?.length > 0) {
      return formValue?.lr_material_list?.reduce((acc, currTeam) => {
        const value = Number(currTeam[name]);
        return acc + (isNaN(value) ? 0 : value);
      }, 0);
    }
    return 0;
  };

  const firstRowData = (_materialList, _materialCategoryList) => {
    return [{
      name: materialList[0]?.name,
      material_code: materialList[0]?.material_code,
      material_category_id: materialCategoryList[0]?._id,
      hsn_code: materialList[0]?.hsn_code ? materialList[0]?.hsn_code : '',
      qty_type: materialList[0]?.qty_type,
      qty: materialList[0]?.qty,
      weight: materialList[0]?.weight ? materialList[0]?.weight : 0,
      weight_type: materialList[0]?.weight_type,
      kot: materialList[0]?.kot ? materialList[0]?.kot : 0,
      amount: materialList[0]?.amount,
    }]
  }

  const getTodayDate = (date) => {
    if(date){
      const today = new Date(date);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  }

  const getDetailsOfLrData = async () => {
    try {
      let object = {
        company_id: isOpen?.company_id,
        lr_id: isOpen?.lr_id,
      };
      const res = await getDataLrDetails(object);
      if (res) {
        let values = res?.data?.lr;
        let tableData = (values?.lr_material_list?.length > 0) ? values?.lr_material_list : firstRowData(materialList,materialCategoryList);
        setFormValue({
          ...values,
          loading_slip_number: values?.sap_invoice_number,
          from_location: {
            label: values?.from_location,
            name: values?.from_location,
          },
          to_location: {
            label: values?.to_location,
            name: values?.to_location,
          },
          unit_id: values?.unit_id?._id,
          consignee_id: values?.consignee_id?._id,
          branch_id: {
            label: values?.branch_id?.branch,
            value: values?.branch_id?._id,
          },
          invoice_date: moment(values?.invoice_date).hours(16).minutes(30).toDate(),
          // eway_bill_date: utils.getTodayDate(values?.eway_bill_date),
          eway_bill_date: moment(values?.eway_bill_date).hours(16).minutes(30).toDate(),
          eway_expiry_date: getTodayDate(values?.eway_expiry_date),
          // eway_expiry_date: moment(values?.eway_expiry_date).hours(16).minutes(30).toDate(),
          from_state: values?.from_state,
          to_state: values?.to_state,
          consignor_id: isOpen?._id,
          gst_invoice_number: values?.gst_number,
          freight_type: isOpen?.contract?.rate_type
            ?.map((el) => {
              return {
                label: el?.name,
                value: el?.id,
              };
            })
            ?.find((el) => (el?.value === values?.lr_freight?.freight_type ? el : "")),
          total_qty: values?.lr_freight?.qty,
          rate: values?.lr_freight?.rate,
          total_amount: values?.lr_freight?.total_freight,
          lr_material_list: tableData,
        });
        setIsEditMaterialTable(tableData);
        setStartDate(
          moment(values?.invoice_date).hours(0).minutes(0).toDate()
        );
        setEwayBillStartDate(moment(values?.eway_bill_date).hours(0).minutes(0).toDate());
        // setEwayBillEndDate(moment(values?.eway_expiry_date).hours(23).minutes(59).toDate());
        setSelectUnitData({
          label: values?.unit_id?.name,
          value: values?.unit_id?._id,
        });
        setSelectConsigneeData({
          label: values?.consignee_id?.name,
          value: values?.consignee_id?._id,
        });
        setIsLrNumber(values?.lr_number);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  };

  useEffect(() => {
    if (!!isOpen?.isEditLr) {
      getDetailsOfLrData();
    }
  }, [isOpen, materialList]);

  // useEffect(() => {
  //   if (formValue?.eway_bill_date) {
  //     setFormValue((s) => ({ ...s, eway_expiry_date: getDateTwoDaysAfterDate(formValue?.eway_bill_date)}))
  //     // setEwayBillEndDate(moment(new Date(formValue?.eway_bill_date)).hours(23).minutes(59).add(2, 'days').toDate())
  //   }
  // }, [formValue?.eway_bill_date])

  useEffect(() => {
    getDropdownDataBranchList();
  }, [addNewBranch]);

  useEffect(() => {
    if (isOpen?.permission_at_lr?.eway_bill_mandatory === 1) {
      setEwayBillRadioToggle(true);
    }
  }, [isOpen?.permission_at_lr?.eway_bill_mandatory]);

  useEffect(() => {
    handleOnChange("invoice_date", startDate);
  }, [startDate]);

  const handleTableIndexAction = (name, index) => {
    if (name === "material") {
      setShowAddItem(!showAddItem);
      setIsMaterialIndex(index);
    } else {
      setShowAddGroupItem({
        modalValue: true,
        nextView: false,
        previousView: true,
      });
      setIsMaterialIndex(index);
    }
  };

  return (
    <>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">
          {" "}
          {!!isOpen?.isEditLr ? "Edit LR" : "Add LR"}{" "} &nbsp; {isOpen?.contract?.name && <span className="default_lrContrx"> {isOpen?.contract?.name} </span>}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => onClose(!isOpen)}
        ></button>
      </div>

      <div className="offcanvas-body">
        <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
          <div className="_lrdMax">
            <div className="flxInr">
              <div className="halfDiv">
                {/* <CommonInput
                  type="date"
                  label="LR Date & Time * "
                  placeholder="LR Date & Time * "
                  name="invoice_date"
                  value={formValue.invoice_date}
                  focus={!!(typeof formError === "object" && formError?.invoice_date)}
                  error={!!(typeof formError === "object") ? formError?.invoice_date : ""}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                /> */}
                <CommonDatePicker
                  name="invoice_date"
                  label="LR Date & Time * "
                  id={Math.random() * 100000}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  error={!!(typeof formError === "object") ? formError?.invoice_date : ""}
                />
              </div>

              <div className="halfDiv">
                <CommonInput
                  disabled={!!isOpen?.isEditLr ? true : false}
                  type="text"
                  label="LR Number "
                  placeholder="LR Number "
                  name="lr_number"
                  value={isLrNumber ? isLrNumber : formValue.lr_number}
                  onClick={handleLrFunctionality}
                />
              </div>

              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Party Name *"
                  placeholder="Party Name *"
                  name="name"
                  value={isOpen?.name}
                  disabled
                />
              </div>

              <div className="halfDiv">
                <CustomSelect
                  flotingLabel="Branch Name*"
                  disabled={!!isOpen?.isEditLr ? true : false}
                  options={dropdownBranchList?.map((el) => {
                    return {
                      label: el?.branch,
                      value: el?._id,
                    };
                  })}
                  name="branch_id"
                  label="Branch Name"
                  isAddButton={true}
                  addButtonAction={(e) => setAddNewBranch(!addNewBranch)}
                  placeholder="Branch Name"
                  value={formValue?.branch_id}
                  error={formError["branch_id.label"]}
                  // focus={!!(typeof formError === "object" && formError?.branch_id)}
                  // error={!!(typeof formError === "object") ? formError?.branch_id : ""}
                  onSelect={(data) => onhandleSelect(data, "branch_id")}
                />
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={cities}
                  label="From Station * "
                  placeholder="From Station"
                  value={formValue.from_location}
                  name="from_location"
                  onSelect={(data) => onhandleSelect(data, "from_location")}
                  error={formError["from_location.label"]}
                />
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={cities}
                  label="To Station * "
                  placeholder="To Station"
                  value={formValue.to_location}
                  name="to_location"
                  onSelect={(data) => onhandleSelect(data, "to_location")}
                  error={formError["to_location.label"]}
                />
              </div>
            </div>

            <div className="_csgAdd_bx">
              <div className="halfDiv">
                {selectUnitData?.value ? (
                  <div className="_unit_detailBxs _avPointer">
                    <h6> Unit </h6>
                    <p> Name : {selectUnitData?.label} </p>
                    <p> Code : {dataSingleUnit?.code} </p>
                    <p>
                      {" "}
                      Address :{" "}
                      {dataSingleUnit?.address_line1
                        ? dataSingleUnit?.address_line1
                        : "-"}{" "}
                      {dataSingleUnit?.city?.label
                        ? dataSingleUnit?.city?.label
                        : "-"}{" "}
                      {dataSingleUnit?.state?.label
                        ? dataSingleUnit?.state?.label
                        : "-"}{" "}
                    </p>
                    <div className="_addDel_bx mt-3">
                      <button
                        className="_pickN_dropBtn"
                        onClick={(e) =>
                          setUnitInLR({ _id: selectUnitData?.value })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                            fill="#1956F6"
                          />
                        </svg>
                        <span>Add New</span>
                      </button>

                      <button
                        className="_deleteBtn"
                        onClick={(e) => {
                          setSelectUnitData(null);
                          handleOnChange("unit_id", "");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                            fill="#C41F1F"
                          ></path>
                        </svg>
                        <span>Remove</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    className="addConsignee_lBtn"
                    onClick={(e) => setUnitInLR(!unitInLR)}
                  >
                    {" "}
                    + Add Unit{" "}
                  </button>
                )}
                {formError?.unit_id && (
                  <span className="input_drop_error">{formError?.unit_id}</span>
                )}
              </div>

              <div className="halfDiv">
                {selectConsigneeData?.value ? (
                  <div className="_unit_detailBxs">
                    <h6> Consignee </h6>
                    <p> Name : {selectConsigneeData?.label} </p>
                    <p>
                      {" "}
                      Code : {
                        dataSingleConsignee?.code
                      }{" "}
                    </p>
                    <p>
                      {" "}
                      Address :{" "}
                      {dataSingleConsignee?.address_line1
                        ? dataSingleConsignee?.address_line1
                        : "-"}{" "}
                      {dataSingleConsignee?.city?.label
                        ? dataSingleConsignee?.city?.label
                        : "-"}{" "}
                      {dataSingleConsignee?.state?.label
                        ? dataSingleConsignee?.state?.label
                        : "-"}{" "}
                    </p>
                    <div className="_addDel_bx mt-3">
                      <button
                        className="_pickN_dropBtn"
                        onClick={(e) =>
                          setConsigneeInLR({ _id: selectConsigneeData?.value })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                            fill="#1956F6"
                          />
                        </svg>
                        <span>Add New</span>
                      </button>

                      <button
                        className="_deleteBtn"
                        onClick={(e) => {
                          setSelectConsigneeData(null);
                          handleOnChange("consignee_id", "");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                            fill="#C41F1F"
                          ></path>
                        </svg>
                        <span>Remove</span>
                      </button>
                    </div>
                    {/* <div style={{ 'fontSize': '12px' }} className='mt-2'>
                                                <span className='_avPointer' onClick={(e) => setConsigneeInLR(!consigneeInLR)}> + Add New </span>
                                                <span className='_avPointer' onClick={(e) => { setSelectConsigneeData(null); handleOnChange('consignee_id', "") }}> X Remove </span>
                                            </div> */}
                  </div>
                ) : (
                  <button
                    className="addConsignee_lBtn"
                    onClick={(e) => setConsigneeInLR(!consigneeInLR)}
                  >
                    + Add Consignee
                  </button>
                )}
                {formError?.consignee_id && (
                  <span className="input_drop_error">
                    {formError?.consignee_id}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {isOpen?.permission_at_item?.item_group_hsn?.view === 1 && (
          <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
            <div className="_materialTable">
              <table>
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Material Category</th>
                    <th>HSN Code</th>
                    <th>QTY</th>
                    <th>Weight</th>
                    <th>KOT</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {formValue?.lr_material_list?.length > 0 ? (
                    formValue?.lr_material_list?.map((el, ind) => {
                      return (
                        <React.Fragment key={ind}>
                          <tr key={ind + el?.name}>
                            <td>
                              <CustomSelect
                                placeholder="Material * "
                                label="Material * "
                                options={
                                  materialList?.length > 0
                                    ? materialList?.map((el) => {
                                      return {
                                        label: el?.name,
                                        value: el?._id,
                                      };
                                    })
                                    : []
                                }
                                value={
                                  materialList
                                    ?.map((el) => {
                                      return {
                                        label: el?.name,
                                        value: el?._id,
                                      };
                                    })
                                    ?.filter((item) =>
                                      item?.label === el?.name ? item : ""
                                    )[0]
                                }
                                name="name"
                                isAddButton={true}
                                addButtonAction={(e) =>
                                  handleTableIndexAction("material", ind)
                                }
                                error={
                                  formError[`lr_material_list[${ind}].name`]
                                }
                                onSelect={(data) =>
                                  handleInputChangeSelect1(data, "name", ind)
                                }
                              />
                            </td>
                            <td>
                              <CustomSelect
                                label="Category * "
                                placeholder="Category * "
                                value={
                                  materialCategoryList
                                    ?.map((el) => {
                                      return {
                                        label: el?.name,
                                        value: el?._id,
                                      };
                                    })
                                    ?.filter((item) =>
                                      item?.value === el?.material_category_id
                                        ? item
                                        : ""
                                    )[0]
                                }
                                options={
                                  materialCategoryList?.length > 0
                                    ? materialCategoryList?.map((el) => {
                                      return {
                                        label: el?.name,
                                        value: el?._id,
                                      };
                                    })
                                    : []
                                }
                                name="material_category_id"
                                isAddButton={true}
                                addButtonAction={(e) =>
                                  handleTableIndexAction(
                                    "material_category",
                                    ind
                                  )
                                }
                                error={
                                  formError[
                                  `lr_material_list[${ind}].material_category_id`
                                  ]
                                }
                                onSelect={(data) =>
                                  handleInputChangeSelect2(
                                    data,
                                    "material_category_id",
                                    ind
                                  )
                                }
                              />
                            </td>
                            <td>
                              <CommonInput
                                type="number"
                                label="HSN Code "
                                placeholder="HSN Code "
                                name="hsn_code"
                                maxLength="6"
                                onInput={maxLengthCheck}
                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                value={el?.hsn_code}
                                focus={
                                  !!formError[
                                  `lr_material_list[${ind}].hsn_code`
                                  ]
                                }
                                error={
                                  formError[`lr_material_list[${ind}].hsn_code`]
                                }
                                onChange={(e) => handleInputChange(e, ind)}
                              />
                            </td>
                            <td>
                              <div className="_inpSelect">
                                <CommonInput
                                  type="number"
                                  label="Qty "
                                  placeholder="Qty "
                                  name="qty"
                                  maxLength="8"
                                  onInput={maxLengthCheck}
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                  value={el?.qty > 0 ? el?.qty : ''}
                                  focus={
                                    !!formError[`lr_material_list[${ind}].qty`]
                                  }
                                  error={
                                    formError[`lr_material_list[${ind}].qty`]
                                  }
                                  onChange={(e) => handleInputChange(e, ind)}
                                />

                                <select
                                  className="_selectInner_bx"
                                  name="qty_type"
                                  id="qty_type"
                                  defaultValue={el?.qty_type}
                                  onChange={(e) => handleInputChange(e, ind)}
                                >
                                  {masterData?.material_qty_type_list?.map(
                                    (el, indx) => {
                                      return (
                                        <option
                                          key={indx}
                                          value={el?.id}
                                          defaultValue={ind === 0}
                                        >
                                          {el?.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="_inpSelect llt_weight_input">
                                <CommonInput
                                  type="number"
                                  label="Weight "
                                  placeholder="Weight "
                                  name="weight"
                                  maxLength="8"
                                  onInput={maxLengthCheck}
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                  value={el?.weight > 0 ? el?.weight : ''}
                                  focus={
                                    !!formError[
                                    `lr_material_list[${ind}].weight`
                                    ]
                                  }
                                  error={
                                    formError[`lr_material_list[${ind}].weight`]
                                  }
                                  onChange={(e) => handleInputChange(e, ind)}
                                />
                                <select
                                  className="_selectInner_bx"
                                  name="weight_type"
                                  id="weight_type"
                                  disabled
                                  defaultValue={el?.weight_type}
                                  onChange={(e) => handleInputChange(e, ind)}
                                >
                                  {masterData?.material_weight_type_list?.map(
                                    (el, indxx) => {
                                      return (
                                        <option
                                          key={indxx}
                                          value={el?.id}
                                          defaultValue={ind === 0}
                                        >
                                          {el?.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            </td>
                            <td>
                              <CommonInput
                                type="number"
                                label="KOT "
                                placeholder="KOT "
                                name="kot"
                                maxLength="8"
                                onInput={maxLengthCheck}
                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                value={el?.kot > 0 ? el?.kot : ''}
                                focus={
                                  !!formError[`lr_material_list[${ind}].kot`]
                                }
                                error={
                                  formError[`lr_material_list[${ind}].kot`]
                                }
                                onChange={(e) => handleInputChange(e, ind)}
                              />
                            </td>
                            <td>
                              <CommonInput
                                type="number"
                                label="Amount "
                                placeholder="Amount "
                                name="amount"
                                maxLength="10"
                                onInput={maxLengthCheck}
                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                value={el?.amount > 0 ? el?.amount : ''}
                                focus={
                                  !!formError[`lr_material_list[${ind}].amount`]
                                }
                                error={
                                  formError[`lr_material_list[${ind}].amount`]
                                }
                                onChange={(e) => handleInputChange(e, ind)}
                              />
                            </td>
                            <td>
                              {formValue?.lr_material_list?.length !== 1 && (
                                <p
                                  onClick={(e) => handleRemoveClick(e, ind)}
                                  className="faTrash_cls"
                                >
                                  {" "}
                                  <FaTrash />{" "}
                                </p>
                              )}
                            </td>
                          </tr>
                          {formValue?.lr_material_list?.length - 1 === ind && (
                            <tr>
                              <td>
                                <button
                                  className="addMst_btn"
                                  onClick={(e) => addNewTableRowHandler(e)}
                                >
                                  + Add Material
                                </button>
                              </td>

                              <td></td>

                              <td></td>

                              <td align="right">
                                {totalCoutFunction("qty")} Qty{" "}
                              </td>

                              <td align="right">
                                {totalCoutFunction("weight")} Kg{" "}
                              </td>

                              <td align="right">
                                {" "}
                                {totalCoutFunction("kot")} kot{" "}
                              </td>

                              <td align="right">
                                {" "}
                                {totalCoutFunction("amount")} Rs{" "}
                              </td>

                              <td></td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {isOpen?.permission_at_invoice_number?.view === 1 ||
          isOpen?.permission_at_field2?.view === 1 ||
          isOpen?.permission_at_field3?.view === 1 ||
          isOpen?.permission_at_field4?.view === 1 ||
          isOpen?.permission_at_field5?.view === 1 ? (
          <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
            <div className="_inrPrt_flx2 ">
              {isOpen?.permission_at_invoice_number?.view === 1 && (
                <div className="_divForth">
                  <CommonInput
                    type={isOpen?.permission_at_invoice_number?.field_type === 2 ? "number" : "text"}
                    label={
                      isOpen?.permission_at_invoice_number?.mandatory === 1
                        ? "Invoice Number * "
                        : "Invoice Number"
                    }
                    placeholder={
                      isOpen?.permission_at_invoice_number?.mandatory === 1
                        ? "Invoice Number * "
                        : "Invoice Number"
                    }
                    name="invoice_number"
                    min={isOpen?.permission_at_invoice_number?.min ? isOpen?.permission_at_invoice_number?.min : ""}
                    max={isOpen?.permission_at_invoice_number?.max ? isOpen?.permission_at_invoice_number?.max : ""}
                    maxLength={
                      isOpen?.permission_at_invoice_number?.max
                        ? isOpen?.permission_at_invoice_number?.max
                        : "12"
                    }
                    onInput={maxLengthCheck}
                    // disabled={isOpen?.permission_at_invoice_number?.view === 1 && isOpen?.permission_at_invoice_number?.mandatory === 0}
                    value={formValue.invoice_number}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.invoice_number
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.invoice_number
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <span className="outOf_cks"> {formValue?.invoice_number?.length} / {isOpen?.permission_at_invoice_number?.max ? isOpen?.permission_at_invoice_number?.max : 12} </span>
                </div>
              )}

              {isOpen?.permission_at_field2?.view === 1 && (
                <div className="_divForth">
                  <CommonInput
                    type={isOpen?.permission_at_field2?.field_type === 2 ? "number" : "text"}
                    label={
                      isOpen?.permission_at_field2?.mandatory === 1
                        ? isOpen?.permission_at_field2?.field_name
                          ? `${isOpen?.permission_at_field2?.field_name} *`
                          : "SAP Invoice Number * "
                        : isOpen?.permission_at_field2?.field_name
                          ? `${isOpen?.permission_at_field2?.field_name}`
                          : "SAP Invoice Number "
                    }
                    placeholder={
                      isOpen?.permission_at_field2?.mandatory === 1
                        ? isOpen?.permission_at_field2?.field_name
                          ? `${isOpen?.permission_at_field2?.field_name} *`
                          : "SAP Invoice Number * "
                        : isOpen?.permission_at_field2?.field_name
                          ? `${isOpen?.permission_at_field2?.field_name}`
                          : "SAP Invoice Number "
                    }
                    min={isOpen?.permission_at_field2?.min ? isOpen?.permission_at_field2?.min : ""}
                    max={isOpen?.permission_at_field2?.max ? isOpen?.permission_at_field2?.max : ""}
                    maxLength={
                      isOpen?.permission_at_field2?.max
                        ? isOpen?.permission_at_field2?.max
                        : "12"
                    }
                    onInput={maxLengthCheck}
                    name="loading_slip_number"
                    value={formValue.loading_slip_number}
                    // disabled={isOpen?.permission_at_field2?.view === 1 && isOpen?.permission_at_field2?.mandatory === 0}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.loading_slip_number
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.loading_slip_number
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <span className="outOf_cks"> {formValue?.loading_slip_number?.length} / {isOpen?.permission_at_field2?.max ? isOpen?.permission_at_field2?.max : 12} </span>
                </div>
              )}

              {isOpen?.permission_at_field3?.view === 1 && (
                <div className="_divForth">
                  <CommonInput
                    type={isOpen?.permission_at_field3?.field_type === 2 ? "number" : "text"}
                    label={
                      isOpen?.permission_at_field3?.mandatory === 1
                        ? isOpen?.permission_at_field3?.field_name
                          ? `${isOpen?.permission_at_field3?.field_name} *`
                          : "GST Number *"
                        : isOpen?.permission_at_field3?.field_name
                          ? `${isOpen?.permission_at_field3?.field_name}`
                          : "GST Number "
                    }
                    placeholder={
                      isOpen?.permission_at_field3?.mandatory === 1
                        ? isOpen?.permission_at_field3?.field_name
                          ? `${isOpen?.permission_at_field3?.field_name} *`
                          : "GST Number *"
                        : isOpen?.permission_at_field3?.field_name
                          ? `${isOpen?.permission_at_field3?.field_name}`
                          : "GST Number "
                    }
                    min={isOpen?.permission_at_field3?.min ? isOpen?.permission_at_field3?.min : ""}
                    max={isOpen?.permission_at_field3?.max ? isOpen?.permission_at_field3?.max : ""}
                    maxLength={
                      isOpen?.permission_at_field3?.max
                        ? isOpen?.permission_at_field3?.max
                        : "12"
                    }
                    onInput={maxLengthCheck}
                    name="gst_invoice_number"
                    value={formValue.gst_invoice_number}
                    // disabled={isOpen?.permission_at_field3?.view === 1 && isOpen?.permission_at_field3?.mandatory === 0}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.gst_invoice_number
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.gst_invoice_number
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <span className="outOf_cks"> {formValue?.gst_invoice_number?.length} / {isOpen?.permission_at_field3?.max ? isOpen?.permission_at_field3?.max : 12} </span>
                </div>
              )}

              {isOpen?.permission_at_field4?.view === 1 && (
                <div className="_divForth">
                  <CommonInput
                    type={isOpen?.permission_at_field4?.field_type === 2 ? "number" : "text"}
                    label={
                      isOpen?.permission_at_field4?.mandatory === 1
                        ? isOpen?.permission_at_field4?.field_name
                          ? `${isOpen?.permission_at_field4?.field_name} *`
                          : "PO Number *"
                        : isOpen?.permission_at_field4?.field_name
                          ? `${isOpen?.permission_at_field4?.field_name}`
                          : "PO Number "
                    }
                    placeholder={
                      isOpen?.permission_at_field4?.mandatory === 1
                        ? isOpen?.permission_at_field4?.field_name
                          ? `${isOpen?.permission_at_field4?.field_name} *`
                          : "PO Number *"
                        : isOpen?.permission_at_field4?.field_name
                          ? `${isOpen?.permission_at_field4?.field_name}`
                          : "PO Number "
                    }
                    min={isOpen?.permission_at_field4?.min ? isOpen?.permission_at_field4?.min : ""}
                    max={isOpen?.permission_at_field4?.max ? isOpen?.permission_at_field4?.max : ""}
                    maxLength={
                      isOpen?.permission_at_field4?.max
                        ? isOpen?.permission_at_field4?.max
                        : "12"
                    }
                    onInput={maxLengthCheck}
                    name="po_number"
                    value={formValue.po_number}
                    // disabled={isOpen?.permission_at_field4?.view === 1 && isOpen?.permission_at_field4?.mandatory === 0}
                    focus={
                      !!(typeof formError === "object" && formError?.po_number)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.po_number
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <span className="outOf_cks"> {formValue?.po_number?.length} / {isOpen?.permission_at_field4?.max ? isOpen?.permission_at_field4?.max : 12} </span>
                </div>
              )}

              {isOpen?.permission_at_field5?.view === 1 && (
                <div className="_divForth">
                  <CommonInput
                    type={isOpen?.permission_at_field5?.field_type === 2 ? "number" : "text"}
                    label={
                      isOpen?.permission_at_field5?.mandatory === 1
                        ? isOpen?.permission_at_field5?.field_name
                          ? `${isOpen?.permission_at_field5?.field_name} *`
                          : "MLR Number *"
                        : isOpen?.permission_at_field5?.field_name
                          ? `${isOpen?.permission_at_field5?.field_name}`
                          : "MLR Number "
                    }
                    placeholder={
                      isOpen?.permission_at_field5?.mandatory === 1
                        ? isOpen?.permission_at_field5?.field_name
                          ? `${isOpen?.permission_at_field5?.field_name} *`
                          : "MLR Number *"
                        : isOpen?.permission_at_field5?.field_name
                          ? `${isOpen?.permission_at_field5?.field_name}`
                          : "MLR Number "
                    }
                    min={isOpen?.permission_at_field5?.min ? isOpen?.permission_at_field5?.min : ""}
                    max={isOpen?.permission_at_field5?.max ? isOpen?.permission_at_field5?.max : ""}
                    maxLength={
                      isOpen?.permission_at_field5?.max
                        ? isOpen?.permission_at_field5?.max
                        : "12"
                    }
                    onInput={maxLengthCheck}
                    name="mlr_number"
                    value={formValue.mlr_number}
                    // disabled={isOpen?.permission_at_field5?.view === 1 && isOpen?.permission_at_field5?.mandatory === 0}
                    focus={
                      !!(typeof formError === "object" && formError?.mlr_number)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.mlr_number
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <span className="outOf_cks"> {formValue?.mlr_number?.length} / {isOpen?.permission_at_field5?.max ? isOpen?.permission_at_field5?.max : 12} </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
          <div className="_inrPrt_flx2 ">
            <div className="singleDiv">
              <CommonSwitch
                id="ewayBill"
                name="ewaybill"
                label="Eway Bill"
                // value={ewayBillRadioToggle}
                checked={ewayBillRadioToggle === true ? true : false}
                isHidden={isOpen?.permission_at_lr?.eway_bill_mandatory === 1}
                onChange={(e) => setEwayBillRadioToggle(e?.target?.checked)}
              />
            </div>
            {ewayBillRadioToggle ? (
              <>
                <div className="_divForth">
                  <CommonInput
                    type="number"
                    label="Eway Bill Number * "
                    placeholder="Eway Bill Number * "
                    name="eway_bill_number"
                    maxLength="12"
                    onInput={maxLengthCheck}
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    value={formValue.eway_bill_number}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.eway_bill_number
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.eway_bill_number
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <span className="outOf_cks"> {formValue?.eway_bill_number?.length ? formValue?.eway_bill_number?.length : 0} / 12 </span>
                </div>

                <div className="_divForth">
                  <CommonDatePicker
                    name="eway_bill_date"
                    label="Start Date"
                    id={Math.random() * 1000}
                    startDate={ewayBillStartDate}
                    setStartDate={setEwayBillStartDate}
                    error={!!(typeof formError === "object") ? formError?.eway_bill_date : ""}
                  />
                  {/* <CommonInput
                    type="date"
                    label="Start Date"
                    placeholder="Start Date "
                    name="eway_bill_date"
                    value={formValue.eway_bill_date}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.eway_bill_date
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.eway_bill_date
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  /> */}
                </div>

                <div className="_divForth">
                  {/* <CommonDatePicker
                    name="eway_expiry_date"
                    label="End Date"
                    id={Math.random() * 10000}
                    startDate={ewayBillEndDate}
                    setStartDate={setEwayBillEndDate}
                    error={!!(typeof formError === "object") ? formError?.eway_expiry_date : ""}
                  /> */}
                  <CommonInput
                    type="date"
                    label="End Date"
                    placeholder="End Date "
                    name="eway_expiry_date"
                    min={ewayBillStartDate}
                    value={formValue.eway_expiry_date}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.eway_expiry_date
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.eway_expiry_date
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {(isOpen?.contract?.chargeable_on === 1) && <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
          <div className="_inrPrt_flx2 _maxWid-650 ">
            <div className="singleDiv">
              <CommonSwitch
                id="freightReceivable"
                name="Freight Receivable"
                label="Freight Receivable"
                value={freightRadioToggle}
                checked={freightRadioToggle === true ? true : false}
                onChange={(e) => setFreightRadioToggle(e.target.checked)}
              />
            </div>
            {freightRadioToggle ? (
              <>
                <div className="_divForth">
                  <CustomSelect
                    label="Freight Type"
                    value={formValue.freight_type}
                    options={isOpen?.contract?.rate_type?.map((el) => {
                      return {
                        label: el?.name,
                        value: el?.id,
                      };
                    })}
                    name="freight_type"
                    placeholder="Freight Type"
                    error={formError["freight_type.label"]}
                    onSelect={(data) => onhandleSelect(data, "freight_type")}
                  />
                </div>

                {formValue?.freight_type?.value != 6 && (<>
                  <div className="_divForth">
                    <CommonInput
                      type="number"
                      label="Qty"
                      placeholder="Qty "
                      name="total_qty"
                      maxLength="12"
                      onInput={maxLengthCheck}
                      value={formValue.total_qty > 0 ? formValue.total_qty : ''}
                      focus={
                        !!(typeof formError === "object" && formError?.total_qty)
                      }
                      error={
                        !!(typeof formError === "object")
                          ? formError?.total_qty
                          : ""
                      }
                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                    />
                  </div>

                  <div className="_divForth">
                    <CommonInput
                      type="number"
                      label="Rate"
                      placeholder="Rate "
                      name="rate"
                      maxLength="12"
                      onInput={maxLengthCheck}
                      value={formValue.rate > 0 ? formValue.rate : ''}
                      focus={!!(typeof formError === "object" && formError?.rate)}
                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      error={
                        !!(typeof formError === "object") ? formError?.rate : ""
                      }
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                    />
                  </div>
                </>)}

                <div className="_divForth">
                  <CommonInput
                    type="number"
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    label="Amount"
                    placeholder="Amount "
                    name="total_amount"
                    maxLength="12"
                    onInput={maxLengthCheck}
                    value={(formValue?.freight_type?.value != 6) ? (formValue.rate * formValue.total_qty > 0 && formValue.rate * formValue.total_qty) : (formValue.total_amount > 0 ? formValue.total_amount : '')}
                    disabled={(formValue?.freight_type?.value != 6) ? true : false}
                    focus={
                      !!(
                        typeof formError === "object" && formError?.total_amount
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.total_amount
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>}
      </div>

      <div className="offcanvas-footer">
        {loading ? (
          <button className="_addComm_btn _sameWid" disabled>
            {" "}
            Loading...{" "}
          </button>
        ) : (
          <button
            className="_addComm_btn _sameWid"
            onClick={(e) => handleSubmit(e)}
            disabled={loading}
          >
            {" "}
            {!!isOpen?.isEditLr ? "Update LR" : "Add LR"}{" "}
          </button>
        )}
        <button className='_cancel_btn _avPointer' onClick={(e) => onClose(false)} >Cancel</button>
        {/* {!isOpen?.isEditLr && (
          <button className="_addNewComm_btn _sameWid">Add & New</button>
        )} */}
      </div>

      {unitInLR && <SelectUnitLrModal {...unitInLrModal} />}

      {isOpenLrSeries && <LrSeriesModal {...isLrSeriesModalProps} />}

      {showAddNewLrSeries && <AddNewSeriesModal {...addNewSeriesModalProps} />}

      {consigneeInLR && <SelectConsigneeLrModal {...consigneeInLrModal} />}

      {/* Add New Consignee Offcanvas - Start */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${addNewConsignee ? "show" : ""
          }`}
        tabIndex={-1}
        style={{ visibility: addNewConsignee ? "visible" : "hidden" }}
      >
        {addNewConsignee && (
          <AddAddressBook
            isOpen={addNewUnit}
            onClose={(e) => setAddNewConsignee(false)}
            callBackAddressData={(e) => setConsigneeInLR(true)}
          />
          // <AddEditConsignee
          //   isOpen={addNewConsignee}
          //   onClose={() => setAddNewConsignee(false)}
          //   previousModal={(e) => setConsigneeInLR(true)}
          // />
        )}
      </div>
      <div className={addNewConsignee ? "offBack_drop" : ""}></div>

      {/* Add New Consignee Offcanvas - End */}

      {/* Add New Unit Offcanvas - Start */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${addNewUnit ? "show" : ""
          }`}
        tabIndex={-1}
        style={{ visibility: addNewUnit ? "visible" : "hidden" }}
      >
        {addNewUnit && (
          <AddAddressBook
            isOpen={addNewUnit}
            onClose={(e) => setAddNewUnit(false)}
            callBackAddressData={(e) => setUnitInLR(true)}
          />
          // <AddEditUnitModal
          //   isOpen={addNewUnit}
          //   onClose={(e) => setAddNewUnit(false)}
          //   previousModal={(e) => setUnitInLR(true)}
          // />
        )}
      </div>
      <div className={addNewUnit ? "offBack_drop" : ""}></div>

      {/* Add New Unit Offcanvas - End */}

      {/* Add new branch offcanvas - start */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${addNewBranch ? "show" : ""
          }`}
        tabIndex={-1}
        style={{ visibility: addNewBranch ? "visible" : "hidden" }}
      >
        {addNewBranch && (
          <AddEditBranch
            isOpen={addNewBranch}
            onClose={(e) => setAddNewBranch(false)}
          />
        )}
      </div>
      <div className={addNewBranch ? "offBack_drop" : ""}></div>

      {/* Add new branch offcanvas - End */}

      {showAddItem && <AddItemModal {...firstAddItemModal} />}

      {showAddGroupItem?.modalValue && (
        <AddItemGroupModal {...firstAddGroupItemModal} />
      )}
    </>
  );
};

export default React.memo(AddEditLrModal);
