import { getMasterRole } from "_services";
import { ButtonHeading, CommonInput, CommonTextarea } from "components";
import { addroleCreate } from "features/pageServices/pageAction";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const AddRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.auth);
  const isInitialRender = useRef(true);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [whichRole, setWhichRole] = useState();
  const [permission, setPermission] = useState(false);
  const [disble, setDisable] = useState(true);
  const [roleError, setRoleError] = useState({
    name: false,
    check: false,
  });

  useEffect(() => {
    getAllMain();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (value) {
      setRoleName(value);
      setRoleError("");
    } else {
      setRoleName("");
      setRoleError({ ...roleError, name: "Role Name is required" });
    }
  };

  const getAllMain = async () => {
    const postData = {
      company_id: StorageService.getCompanyId(),
    };
    try {
      let response = await getMasterRole(postData);

      if (response?.responseCode === 200) {
        setRoleData(response?.data?.menu_list);
        setWhichRole(response?.data?.which_role);

        await response?.payload?.menu_list.map((user) => {
          let array = [];
          user?.menus?.map((menus) => {
            array.push(
              ...array,
              menus?.permissions?.create === 1 ||
                menus?.permissions?.edit === 1 ||
                menus?.permissions?.delete === 1 ||
                menus?.permissions?.view === 1
            );
          });
          setPermission(array.every((i) => i) ? false : true);
        });
      }
    } catch (error) {}
  };

  const handleFullCheck = (e, index) => {
    const { id, checked, value } = e.target;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (id === `fullCheck_${index}` && index === cateIndex) {
          let newData = [...roleData];

          if (checked) {
            newData[cateIndex].menus[newIndex].full_access = 1;
          } else {
            newData[cateIndex].menus[newIndex].full_access = 0;
          }

          if (newData[cateIndex].menus[newIndex].full_access) {
            newData[cateIndex].menus[newIndex].view = 1;
            newData[cateIndex].menus[newIndex].edit = 1;
            newData[cateIndex].menus[newIndex].create = 1;
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].delete = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }
      });
    });
  };

  const handleSelectAll = (e, index) => {
    const { id, checked, value } = e.target;

    let fullcheckValue = false;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (
          id === `allSelect_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].full_access = newData[cateIndex]
            .menus[newIndex]?.full_access
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].full_access) {
            newData[cateIndex].menus[newIndex].view = 1;
            newData[cateIndex].menus[newIndex].edit = 1;
            newData[cateIndex].menus[newIndex].create = 1;
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].delete = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }
        if (oneMenu.full_access) {
          fullcheckValue = true;
        }
      });

      setDisable(!fullcheckValue);
    });
  };

  const handleChange = (e, index) => {
    const { id, checked, value } = e.target;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (
          id === `view_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].view = newData[cateIndex].menus[
            newIndex
          ]?.view
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].view) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `create_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].create = newData[cateIndex].menus[
            newIndex
          ]?.create
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].create) {
            newData[cateIndex].menus[newIndex].create = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `edit_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].edit = newData[cateIndex].menus[
            newIndex
          ]?.edit
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].edit) {
            newData[cateIndex].menus[newIndex].edit = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `delete_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].delete = newData[cateIndex].menus[
            newIndex
          ]?.delete
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].delete) {
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].delete = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;
            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }

        let newData = [...roleData];

        if (
          newData[cateIndex].menus[newIndex].create &&
          newData[cateIndex].menus[newIndex].edit &&
          newData[cateIndex].menus[newIndex].delete &&
          newData[cateIndex].menus[newIndex].view
        ) {
          newData[cateIndex].menus[newIndex].full_access = 1;
        } else {
          newData[cateIndex].menus[newIndex].full_access = 0;
        }
      });
    });
  };

  const handuleSubmit = async (e, action) => {
    e.preventDefault();
    setDisable(true);
    //prepare add role data here
    let role_menus = [];
    roleData?.map((i) => {
      i?.menus?.map((a) => {
        role_menus.push({
          menu_id: a?._id,
          permissions: {
            view: a?.view,
            create: a?.create,
            edit: a?.edit,
            delete: a?.delete,
          },
        });
      });
    });

    let payload = {
      company_id: StorageService.getCompanyId(),
      role_name: roleName,
      role_menus: role_menus,
      description: description,
    };

    try {
      const res = await dispatch(addroleCreate(payload));

      if (res?.payload?.responseCode === 200) {
        toast.success(res?.payload?.responseMessage);
        if (action === "add") {
          navigate("/setting/role");
          setDisable(false);
          clearAll();
        } else {
          clearAll();

          setDisable(false);
        }
      } else {
        toast.error(res?.payload?.responseMessage);
        setDisable(false);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setRoleError({
      ...roleError,
      check: shouldReturnFalse(roleData) ? "Permission is required" : "",
    });
  }, [roleData]);

  const shouldReturnFalse = (menus) => {
    for (const category of menus) {
      for (const menu of category.menus) {
        const permissions = menu;
        if (
          permissions.view === 1 ||
          permissions.create === 1 ||
          permissions.edit === 1 ||
          permissions.delete === 1
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const clearAll = () => {
    setRoleName("");
    setDescription("");
    getAllMain();
  };

  return (
    <div>
      <ButtonHeading title={"Add Role"} buttonTitle={"Add Role"} />

      <div>
        <div className="_addContainer">
          <div className="_addRl_bx">
            <div className="_addRlHead">Role Details</div>

            <div className="_addRl_tableBx">
              <div className="_rlsInputs_bx">
                <div className="addRl_inputOtr">
                  {/* <input type="text" className='simpleInput' placeholder='Role Name' /> */}
                  <CommonInput
                    type="text"
                    placeholder="Role Name"
                    label="Role Name"
                    value={roleName}
                    onChange={handleInputChange}
                    error={roleError.name}
                  />
                </div>

                <div className="addRl_inputOtr">
                  {/* <textarea name="" className='simpleTextarea' placeholder='Description' id=""></textarea> */}
                  <CommonTextarea
                    placeholder="Description"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              {roleError.check && (
                <span className={"input_drop_error"}>{roleError.check}</span>
              )}
              <div className="_otrRl_table">
                {roleData.map((category, categoryIndex) => {
                  return (
                    <div className="_mainRl_table" key={category.name}>
                      <div className="_addRl_headCheck">
                        <input
                          type="checkbox"
                          label="Dashboard"
                          id={`fullCheck_${categoryIndex}`}
                          onChange={(e) => handleFullCheck(e, categoryIndex)}
                          checked={category?.menus.every(
                            (i) => i.full_access === 1
                          )}
                          value={
                            category?.menus.every((i) => i.full_access === 1)
                              ? 1
                              : 0
                          }
                          disabled={
                            whichRole === 0
                              ? permission
                                ? false
                                : true
                              : false
                          }
                        />
                        <label
                          htmlFor={`fullCheck_${categoryIndex}`}
                          className="anvLabel"
                        >
                          {category?.name}
                        </label>
                      </div>

                      {
                      // category?.name === "Dashboard" ? (
                      //   <table>
                      //     <thead>
                      //       <tr>
                      //         <th>Description</th>
                      //         <th>View</th>
                      //       </tr>
                      //     </thead>

                      //     <tbody>
                      //       <tr>
                      //         <td>Summary</td>
                      //         <td>
                      //           <input
                      //             type="checkbox"
                      //             id="Summary"
                      //             className="_checkTbl"
                      //           />
                      //           <label htmlFor="Summary">
                      //             <span style={{ visibility: "hidden" }}>
                      //               Summary
                      //             </span>
                      //           </label>
                      //         </td>
                      //       </tr>

                      //       <tr>
                      //         <td>Live Location</td>
                      //         <td>
                      //           <input
                      //             type="checkbox"
                      //             id="LiveLocation"
                      //             className="_checkTbl"
                      //           />
                      //           <label htmlFor="LiveLocation">
                      //             <span style={{ visibility: "hidden" }}>
                      //               Live Location
                      //             </span>
                      //           </label>
                      //         </td>
                      //       </tr>

                      //       <tr>
                      //         <td>Timeline</td>
                      //         <td>
                      //           <input
                      //             type="checkbox"
                      //             id="Timeline"
                      //             className="_checkTbl"
                      //           />
                      //           <label htmlFor="Timeline">
                      //             <span style={{ visibility: "hidden" }}>
                      //               Timeline
                      //             </span>
                      //           </label>
                      //         </td>
                      //       </tr>
                      //     </tbody>
                      //   </table>
                      // ) : (
                        category?.category_type === 1 && (
                          <table>
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Full Access</th>
                                <th>View</th>
                                <th>Create</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            {category?.menus.map((menus, menuIndex) => (
                              <tbody key={`menuIndex_${menuIndex}`}>
                                <tr>
                                  <td> {menus?.label}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                      checked={
                                        menus?.full_access ? true : false
                                      }
                                      onChange={(e) =>
                                        handleSelectAll(e, menuIndex)
                                      }
                                      value={menus?.full_access}
                                      disabled={
                                        whichRole === 0
                                          ? menus?.permissions?.create === 1 &&
                                            menus?.permissions?.edit === 1 &&
                                            menus?.permissions?.delete === 1 &&
                                            menus?.permissions?.view === 1
                                            ? false
                                            : true
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl1
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`view_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.view}
                                      checked={menus?.view ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? menus?.permissions?.create === 1 &&
                                            menus?.permissions?.edit === 1 &&
                                            menus?.permissions?.delete === 1 &&
                                            menus?.permissions?.view === 1
                                            ? false
                                            : true
                                          : menus?.create ||
                                            menus?.edit ||
                                            menus?.delete
                                      }
                                    />
                                    <label
                                      htmlFor={`view_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl2
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`create_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.create}
                                      checked={menus?.create ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.create
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`create_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl3
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.edit}
                                      checked={menus?.edit ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.edit
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl4
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.delete}
                                      checked={menus?.delete ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.delete
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl5
                                      </span>
                                    </label>
                                  </td>
                                </tr>

                                {/* <tr>
                                                <td>Site</td>
                                                <td>
                                                    <input type="checkbox" id='st1' className='_checkTbl' />
                                                    <label htmlFor="st1"><span style={{ visibility: "hidden" }}>st1</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='st2' className='_checkTbl' />
                                                    <label htmlFor="st2"><span style={{ visibility: "hidden" }}>st2</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='st3' className='_checkTbl' />
                                                    <label htmlFor="st3"><span style={{ visibility: "hidden" }}>st3</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='st4' className='_checkTbl' />
                                                    <label htmlFor="st4"><span style={{ visibility: "hidden" }}>st4</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='st5' className='_checkTbl' />
                                                    <label htmlFor="st5"><span style={{ visibility: "hidden" }}>st5</span></label>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Client Group</td>
                                                <td>
                                                    <input type="checkbox" id='cg1' className='_checkTbl' />
                                                    <label htmlFor="cg1"><span style={{ visibility: "hidden" }}>cg1</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='cg2' className='_checkTbl' />
                                                    <label htmlFor="cg2"><span style={{ visibility: "hidden" }}>cg2</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='cg3' className='_checkTbl' />
                                                    <label htmlFor="cg3"><span style={{ visibility: "hidden" }}>cg3</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='cg4' className='_checkTbl' />
                                                    <label htmlFor="cg4"><span style={{ visibility: "hidden" }}>cg4</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='cg5' className='_checkTbl' />
                                                    <label htmlFor="cg5"><span style={{ visibility: "hidden" }}>cg5</span></label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Site Group</td>
                                                <td>
                                                    <input type="checkbox" id='sg1' className='_checkTbl' />
                                                    <label htmlFor="sg1"><span style={{ visibility: "hidden" }}>sg1</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='sg2' className='_checkTbl' />
                                                    <label htmlFor="sg2"><span style={{ visibility: "hidden" }}>sg2</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='sg3' className='_checkTbl' />
                                                    <label htmlFor="sg3"><span style={{ visibility: "hidden" }}>sg3</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='sg4' className='_checkTbl' />
                                                    <label htmlFor="sg4"><span style={{ visibility: "hidden" }}>sg4</span></label>
                                                </td>
                                                <td>
                                                    <input type="checkbox" id='sg5' className='_checkTbl' />
                                                    <label htmlFor="sg5"><span style={{ visibility: "hidden" }}>sg5</span></label>
                                                </td>
                                            </tr> */}
                              </tbody>
                            ))}
                          </table>
                        )
                      // )
                      }
                    </div>
                  );
                })}

                {/* <div className="_mainRl_table">
                  <div className="_addRl_headCheck">
                    <input
                      id="Client"
                      boxclassName="_addRl_headCheck"
                      type="checkbox"
                      label="Client Site"
                    />
                    <label htmlFor="Client" className="anvLabel">
                      Client Site
                    </label>
                  </div>

                  <table>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Full Access</th>
                        <th>View</th>
                        <th>Create</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Client</td>
                        <td>
                          <input
                            type="checkbox"
                            id="cl1"
                            className="_checkTbl"
                          />
                          <label htmlFor="cl1">
                            <span style={{ visibility: "hidden" }}>cl1</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cl2"
                            className="_checkTbl"
                          />
                          <label htmlFor="cl2">
                            <span style={{ visibility: "hidden" }}>cl2</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cl3"
                            className="_checkTbl"
                          />
                          <label htmlFor="cl3">
                            <span style={{ visibility: "hidden" }}>cl3</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cl4"
                            className="_checkTbl"
                          />
                          <label htmlFor="cl4">
                            <span style={{ visibility: "hidden" }}>cl4</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cl5"
                            className="_checkTbl"
                          />
                          <label htmlFor="cl5">
                            <span style={{ visibility: "hidden" }}>cl5</span>
                          </label>
                        </td>
                      </tr>

                      <tr>
                        <td>Site</td>
                        <td>
                          <input
                            type="checkbox"
                            id="st1"
                            className="_checkTbl"
                          />
                          <label htmlFor="st1">
                            <span style={{ visibility: "hidden" }}>st1</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="st2"
                            className="_checkTbl"
                          />
                          <label htmlFor="st2">
                            <span style={{ visibility: "hidden" }}>st2</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="st3"
                            className="_checkTbl"
                          />
                          <label htmlFor="st3">
                            <span style={{ visibility: "hidden" }}>st3</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="st4"
                            className="_checkTbl"
                          />
                          <label htmlFor="st4">
                            <span style={{ visibility: "hidden" }}>st4</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="st5"
                            className="_checkTbl"
                          />
                          <label htmlFor="st5">
                            <span style={{ visibility: "hidden" }}>st5</span>
                          </label>
                        </td>
                      </tr>

                      <tr>
                        <td>Client Group</td>
                        <td>
                          <input
                            type="checkbox"
                            id="cg1"
                            className="_checkTbl"
                          />
                          <label htmlFor="cg1">
                            <span style={{ visibility: "hidden" }}>cg1</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cg2"
                            className="_checkTbl"
                          />
                          <label htmlFor="cg2">
                            <span style={{ visibility: "hidden" }}>cg2</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cg3"
                            className="_checkTbl"
                          />
                          <label htmlFor="cg3">
                            <span style={{ visibility: "hidden" }}>cg3</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cg4"
                            className="_checkTbl"
                          />
                          <label htmlFor="cg4">
                            <span style={{ visibility: "hidden" }}>cg4</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="cg5"
                            className="_checkTbl"
                          />
                          <label htmlFor="cg5">
                            <span style={{ visibility: "hidden" }}>cg5</span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Site Group</td>
                        <td>
                          <input
                            type="checkbox"
                            id="sg1"
                            className="_checkTbl"
                          />
                          <label htmlFor="sg1">
                            <span style={{ visibility: "hidden" }}>sg1</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="sg2"
                            className="_checkTbl"
                          />
                          <label htmlFor="sg2">
                            <span style={{ visibility: "hidden" }}>sg2</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="sg3"
                            className="_checkTbl"
                          />
                          <label htmlFor="sg3">
                            <span style={{ visibility: "hidden" }}>sg3</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="sg4"
                            className="_checkTbl"
                          />
                          <label htmlFor="sg4">
                            <span style={{ visibility: "hidden" }}>sg4</span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="sg5"
                            className="_checkTbl"
                          />
                          <label htmlFor="sg5">
                            <span style={{ visibility: "hidden" }}>sg5</span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}

                {/* <div className="_mainRl_table">
                  <div className="_addRl_headCheck">
                    <input
                      id="Reports"
                      boxclassName="_addRl_headCheck"
                      type="checkbox"
                      label="Reports"
                    />
                    <label htmlFor="Reports" className="anvLabel">
                      Reports
                    </label>
                  </div>

                  <div className="_addRl_checkList">
                    <input type="checkbox" id="or1" label="Order Report" />
                    <label htmlFor="or1" className="anvLable">
                      Order Report
                    </label>

                    <input
                      type="checkbox"
                      id="edr"
                      label="Employee Detail Report"
                    />
                    <label htmlFor="edr" className="anvLable">
                      Employee Detail Report
                    </label>

                    <input
                      type="checkbox"
                      id="svt"
                      label="Sales VS Target Report"
                    />
                    <label htmlFor="svt" className="anvLable">
                      Sales VS Target Report
                    </label>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="bottomFix">
          <button
            className="_addComm_btn"
            type="button"
            disabled={loading || disble || !roleName}
            onClick={(e) => handuleSubmit(e, "add")}
          >
            Add Role
          </button>
          <button
            className="_addNewComm_btn"
            type="button"
            disabled={loading || disble || !roleName}
            onClick={(e) => handuleSubmit(e, "addNew")}
          >
            Add & New
          </button>
          <button className="_cancel_btn">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
