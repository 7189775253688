import {
  CommonDatePicker,
  CommonDateRange,
  CommonInput,
  CustomSelect,
} from "components";
import { AddButton } from "components/common/CommonButton";
import moment from "moment";
import React, { useState } from "react";

const MasterFilterModal = (props) => {
  let { isOpen, onClose, filter, setFilter, isShow, list ,name} = props;

  const [modalFilter,setModalFilter] = useState({
    search:"",
    branch_id:"",
    start_date:'',
    end_date:'',
    user_id:"",
    expense_category_id:"",
    consignor_id:""
  })



const handleSave = () => {
  const commonFilter = {
    search: modalFilter?.search,
    branch_id: modalFilter?.branch_id,
    start_date: modalFilter?.start_date,
    end_date: modalFilter?.end_date,
  };

  switch (name) {
 
    case "plan":
      
      setFilter({
        ...filter,
        ...commonFilter,
        consignor_id: modalFilter?.consignor_id,
      });
      break;

    case "payment":
    
      setFilter({
        ...filter,
        ...commonFilter,
        expense_category_id:modalFilter?.expense_category_id,
        user_id:modalFilter?.user_id
      });
      break;

    default:
      console.warn("Unknown filter name:", name);
      break;

  }

  // Close the modal after handling
  onClose(false);
};


  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];


  return (
    <>
      <div className="offcanvas-header">
        <div className="d-flex gap-2 align-items-center">
          <h5 className="offcanvas-title"> Filters </h5>{" "}
          {filter?.search ? (
            <div className="_counts"> {filter?.search ? 1 : 0} </div>
          ) : (
            <></>
          )}
        </div>
        <button
          type="button"
          className="btn-close"
          onClick={(e) => onClose(false)}
        ></button>
      </div>

      <div className="offcanvas-body">
        <div className="_tableFilter_inrFx">
          <div className="_leftFilts">
            <div className="_filtrFlx">
              <div className="_inpW_search">
                <svg
                  className="srcIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M16.5311 15.6665L11.9134 11.0488C13.023 9.71662 13.5764 8.00793 13.4583 6.27818C13.3402 4.54842 12.5598 2.93078 11.2794 1.76176C9.99906 0.592742 8.31727 -0.0376443 6.58394 0.0017403C4.8506 0.0411249 3.19918 0.747248 1.97322 1.97322C0.747248 3.19918 0.0411249 4.8506 0.0017403 6.58394C-0.0376443 8.31727 0.592742 9.99906 1.76176 11.2794C2.93078 12.5598 4.54842 13.3402 6.27818 13.4583C8.00793 13.5764 9.71662 13.023 11.0488 11.9134L15.6665 16.5311L16.5311 15.6665ZM1.24477 6.74783C1.24477 5.65943 1.56752 4.59547 2.1722 3.69049C2.77689 2.78552 3.63635 2.08018 4.6419 1.66366C5.64745 1.24715 6.75394 1.13817 7.82143 1.35051C8.88892 1.56285 9.86947 2.08696 10.6391 2.85658C11.4087 3.6262 11.9328 4.60675 12.1452 5.67424C12.3575 6.74173 12.2485 7.84821 11.832 8.85376C11.4155 9.85932 10.7101 10.7188 9.80517 11.3235C8.9002 11.9281 7.83623 12.2509 6.74783 12.2509C5.28883 12.2493 3.89004 11.669 2.85837 10.6373C1.82669 9.60562 1.24639 8.20684 1.24477 6.74783Z"
                    fill="black"
                  />
                </svg>
                <input
                  type="text"
                  placeholder="Search"
                  className="simpleFilter_input"
                  value={modalFilter.search}
                  onChange={(e) =>
                    setModalFilter((s) => ({
                      ...s,
                      search: e.target.value,
                      offset: 1,
                    }))
                  }
                />
              </div>
            </div>

            {isShow?.allBranch && (
              <div className="singleDiv">
                <CustomSelect
                  label="Branch"
                  placeholder="Select Branch"
                  options={list?.branch ? list?.branch : []}
                  value={list?.branch?.find(
                    (i) => i?.value == modalFilter?.branch_id
                  )}
                  //   options={branch}
                  //   value={branch?.find((i) => i?.value == filter?.branch_id)}
                  onSelect={(data) => {
                    setModalFilter((s) => ({
                      ...s,
                      branch_id: data?.value,
                      offset: 1,
                    }));
                  }}
                />
              </div>
            )}

            {isShow?.datePicker && (
              <div className="singleDiv">
                {" "}
                <CommonDatePicker
                  name="date"
                  label="Date"
                  startDate={modalFilter?.start_date}
                  setStartDate={(data) => {
                    setModalFilter((s) => ({
                      ...s,
                      start_date: data,
                    }));
                  }}
                  //   error={formError?.start_date}
                  //   focus={!!formError?.start_date}
                />
              </div>
            )}
            {isShow?.dateRange && (
              <div className="singleDiv">
                {" "}
                <CommonDateRange
                  initialStartDate={
                    modalFilter?.start_date ? modalFilter?.start_date : ""
                  }
                  initialEndDate={modalFilter?.end_date ? modalFilter?.end_date : ""}
                  onApply={(data) => {
                    setModalFilter({
                      ...modalFilter,
                      start_date: moment(data?.startDate),
                      end_date: moment(data?.endDate),
                    });
                  }}
                  datePrefield={
                    !modalFilter?.start_date && !modalFilter?.end_date ? false : true
                  }
                />
              </div>
            )}

            {isShow?.allUser && (
              <div className="singleDiv">
                <CustomSelect
                  label="User"
                  placeholder="All users"
                  options={list?.user}
                  value={list?.user?.find(
                    (i) => i?.value?._id == modalFilter?.user_id
                  )}
                  //   options={user}
                  //   value={user?.find((i) => i?.value == filter?.user_id)}
                  onSelect={(data) => {
                    setModalFilter((s) => ({
                      ...s,
                      user_id: data?.value?._id,
                      offset: 1,
                    }));
                  }}
                />
              </div>
            )}

            {isShow?.expenseCatagory && (
              <div className="singleDiv">
                <CustomSelect
                  label="Expenses Category"
                  placeholder="All Categories"
                  options={list?.expense}
                  value={list?.expense?.find(
                    (i) => i?.value == modalFilter?.expense_category_id
                  )}
                  //   options={expense}
                  //   value={expense?.find(
                  //     (i) => i?.value == filter?.expense_category_id
                  //   )}
                  onSelect={(data) => {
                    setModalFilter((s) => ({
                      ...s,
                      expense_category_id: data?.value,
                      offset: 1,
                    }));
                  }}
                />
              </div>
            )}

            {isShow?.consignor && (
              <div className="singleDiv">
                <CustomSelect
                  label="Consignor"
                  placeholder="Select Consignor"
                  options={list?.consignor ? list?.consignor : []}
                  value={list?.consignor?.find(
                    (i) => i?.value == modalFilter?.consignor_id
                  )}
                  //   options={branch}
                  //   value={branch?.find((i) => i?.value == filter?.branch_id)}
                  onSelect={(data) => {
                    setModalFilter((s) => ({
                      ...s,
                      consignor_id: data?.value,
                      offset: 1,
                    }));
                  }}
                />
              </div>
            )}
          </div>

          {/* {allfilterableColumns.map(column => (
                        <div key={column} className="column-filter-dropdown">
                            <button className="dropbtn" onClick={() => toggleActiveFilter(column)}>
                                <label className='_float_lbl' ><span>Select {`${(allColumns.find(col => col.accessor === column)).Header}`}</span></label>

                                {`All ${(allColumns.find(col => col.accessor === column)).Header}`}

                                <div className='_drop_iconBr'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                                        <path opacity="0.6" d="M1.49235 1.2206e-06L8.50765 6.07302e-07C8.78461 -0.000377072 9.05602 0.0731502 9.29046 0.212072C9.5249 0.350995 9.7128 0.549632 9.83239 0.78499C9.97239 1.06424 10.0263 1.37494 9.98801 1.68166C9.94971 1.98838 9.82073 2.2788 9.61577 2.51982L6.10812 6.52327C5.97047 6.67291 5.80028 6.79293 5.60909 6.87518C5.4179 6.95743 5.21018 7 5 7C4.78982 7 4.5821 6.95743 4.39091 6.87518C4.19972 6.79293 4.02954 6.67291 3.89188 6.52327L0.384232 2.51982C0.179267 2.2788 0.0502849 1.98838 0.0119853 1.68166C-0.0263143 1.37494 0.0276093 1.06425 0.167609 0.784991C0.287203 0.549632 0.475097 0.350996 0.709542 0.212073C0.943985 0.0731509 1.21539 -0.00037641 1.49235 1.2206e-06Z" fill="black" />
                                    </svg>
                                </div>
                            </button>
                            {activeFilters.includes(column) && (
                                <div className="dropdown-content menuAnimate">
                                    <div className='_srcField_bx'>
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 h-4 w-4 shrink-0 opacity-50"><path d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>

                                        <input
                                            type="text"
                                            placeholder={`Search ${(allColumns.find(col => col.accessor === column)).Header}`}
                                            value={filterSearchInput[column] || ""}
                                            onChange={e => handleFilterSearchInputChange(column, e.target.value)}
                                            className="filter-column-search-input"
                                        />
                                    </div>
                                    <div className='_filtList_bx'>
                                        {uniqueColumnValues(column)
                                            .filter(value => value.toString().toLowerCase().includes((filterSearchInput[column] || "").toLowerCase())).map(value => (
                                                <div key={value} className='active-filter-group'>
                                                    <label className='_allFl_list'>
                                                        <input
                                                            type="checkbox"
                                                            checked={filter[column]?.includes(value) || false}
                                                            onChange={() => handleFilterChange(column, value)}
                                                        />
                                                        <span>
                                                            {value}</span>
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))} */}
        </div>
      </div>

      <div className="offcanvas-footer">
        <AddButton
          name="Save"
          className="_addComm_btn"
          onClick={handleSave}
          buttonTitle="Save"
        />

        <button
          className="_cancel_btn"
          onClick={(e) => setFilter((s) => ({ ...s, search: "", offset: 1 }))}
        >
          Reset
        </button>
      </div>
    </>
  );
};

export default MasterFilterModal;
