import { fetchBranchDetailsData } from '_services/accountsServices/branchServices';
import { getFuelStatementData } from '_services/accountsServices/fuelStationServices';
import { CommonInput } from 'components';
import { UserContext } from 'context';
import { utils } from 'helper';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';

const VehicleDetailsTab = ({ onDataFetch, loadingBarRef }) => {

    let { id } = useParams();
    const { masterData } = useContext(UserContext)
    const [detailsData, setDetailsData] = useState({})
    const [loading, setLoading] = useState(false);

    const getDataFuelStatement = async (comp_id, id) => {
        setLoading(true);
        try {
            let object = {
                company_id: comp_id,
                vehicle_id: id,
            }
            const res = await getFuelStatementData(object);
            if (res?.responseCode == 200) {
                setLoading(false);
                onDataFetch(res?.data?.account_detail);
                setDetailsData(res?.data?.account_detail);
                // setTotalItems(res?.data?.total_record);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        if (StorageService.getCompanyId()) {
            getDataFuelStatement(StorageService.getCompanyId(), id);
        }
    }, [StorageService.getCompanyId(), id])

  

    return (
        <div>
            {
                loading ? <div className='p-5'> Loading... </div> : (
                    <div className='_addContainer2 '>
                        <div className='_ptyDetail_bx'>
                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Basic Details</div>
                                <div className='_inrPrt_flx'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            placeholder="Vehicle No"
                                            label="Vehicle No *"
                                            name='name'
                                            value={detailsData?.vehicle_no}
                                            disabled
                                        />
                                    </div>

                                    {/* <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Branch Code"
                                            placeholder="Branch Code"
                                            value={detailsData?.branch_code}
                                            disabled
                                        />
                                    </div> */}

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Mobile Number"
                                            placeholder="Mobile Number"
                                            value={detailsData?.contact_no}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Alternate Mobile Number"
                                            placeholder="Alternate Mobile Number"
                                            value={detailsData?.alt_contact_no}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Email ID"
                                            placeholder="Email ID"
                                            value={detailsData?.email}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Remark"
                                            placeholder="Remark"
                                            value={detailsData?.remark}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs ">
                                <div className='_inrPrt_head'> Address </div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Address Line 1"
                                            placeholder="Address Line 1"
                                            value={detailsData?.address_line1}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State"
                                            placeholder="State"
                                            value={detailsData?.state?.label}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="City"
                                            placeholder="City"
                                            value={detailsData?.city?.label}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="PIN Code"
                                            placeholder="PIN Code"
                                            value={detailsData?.pincode}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Tax Info</div>
                                <div className='_inrPrt_flx'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="GST Type"
                                            placeholder="GST Type"
                                            value={masterData?.gst_type_list?.filter((el) => (el?.id == detailsData?.gst_type))[0]?.name}
                                            disabled
                                        />
                                    </div>

                                    {
                                        detailsData?.gst_no ? (
                                            <div className='_divForth'>
                                                <CommonInput
                                                    type="text"
                                                    label="GST Number"
                                                    placeholder="GST Number"
                                                    value={detailsData?.gst_no}
                                                    disabled
                                                />
                                            </div>
                                        ) : <></>
                                    }

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State State"
                                            placeholder="State State"
                                            value={detailsData?.gst_state?.label}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default VehicleDetailsTab