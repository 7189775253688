import { planImport } from "_services";
import {
  getConsignorDropdownListData,
  getContractDropdownListData,
} from "_services/dropdownServices/dropdownService";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { errorSchema, utils } from "helper";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";
import * as Yup from "yup";

const QuickUploadModal = (props) => {
  const initial = {
    company_id: StorageService?.getCompanyId(),
    file: "",
  };

  const fileSchema = {
    uploadFileSchema: Yup.object().shape({
      file: Yup.string().required("Required *")
    }),
  }

  const navigate = useNavigate()
  const [form, setForm] = useState(initial);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
 

  const handleOnChange = async (name, value) => {

    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            fileSchema?.uploadFileSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  //file

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (validTypes.includes(file.type)) {
        handleOnChange("file", file);
        setFormError({
          ...formError,
          file: "",
        });
      } else {
        setFormError({
          ...formError,
          file: "Please upload only CSV or XLSX files.",
        });
      }
    }
  };

 
  const handleImportFile = async () => {

    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      if (key === "file" && form[key]) {
        formData.append(key, form[key]);
      } else {
        formData.append(key, form[key]);
      }
    });

    const validationResult = await utils.checkFormError(
      { ...form },
      fileSchema?.uploadFileSchema
    );
    if (validationResult == true) {
      setLoading(true)
      try {
        const res = await planImport(formData);

        if (res?.responseCode == 200) {
          setLoading(false)
          toast?.success(res?.responseMessage)
          
          setTimeout(() => {

            if (props?.title == "plan") {
              navigate("/plan/planlist")
            }

          }, 3000);
          props?.closeModal()
        } else {
          setLoading(false)
        }
      } catch (error) {
        toast.error(error)

      }
    } else {

      setFormError(validationResult);

    }
  };

  const inhenceProps = {
    ...props,
    onSubmit: handleImportFile,
    disabled: loading
  };

  return (
    <>
      <CommonModal {...inhenceProps}>
        <div className="pb-3">
          <>
            <div className="_endTr_body">
              <div className="singleDiv">
                <CommonInput
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv, .xlsx"
                  error={formError?.file}
                />
              </div>
            </div>
          </>
        </div>
      </CommonModal>
    </>
  );
};

export default QuickUploadModal;
