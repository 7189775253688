import { getAccountingUser, getUserAccountList } from '_services'
import { ButtonHeading } from 'components'
import { Images } from 'constants'
import { AddExpensesModal, AddTransctionsModal } from 'modals'
import Loading from 'pages/Loading'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import StorageService from 'utils/StorageServices/StorageServices'

const Transactions = () => {

  const [accounts, setAccounts] = useState('');
  const [show, setShow] = useState(false);
  const [showExpModal, setShowExpModal] = useState(false)
  // const [paramData,setParamData] = useState({
  //   category_id:"",
  //   sub_category_id:"",
  //   account_id:""
  // })

  // onClick={()=>{
  //   setParamData((pre)=>({...pre, category_id: accounts?.id, sub_category_id: i?.id, account_id: k?.id }))
  // } }

  useEffect(() => {
    getAccountListHandler()
  }, [])

  const callBackFreight = ()=>{
    getAccountListHandler()
  }

  const getAccountListHandler = async () => {
    try {
      const res = await getAccountingUser({
        company_id: StorageService?.getCompanyId(),

      });
      if (res?.responseCode === 200) {
        setAccounts(res?.data?.chart_of_account)
      } else {
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let AddTransactionAccountModal = {
    isOpen: show,
    closeModal: (e) => setShow(false),
    modalMainClass: "_dashCommon_Modal _mdModal",
    modalTitle: "Add Account ",
    // headerPart: true,
    footerPart: true,
    submitTitle: "Add Account",
    getAccountListHandler: getAccountListHandler
  }

  let AddExpensesModals = {
    isOpen: showExpModal,
    closeModal: (e) => setShowExpModal(false),
    modalMainClass: "_dashCommon_Modal _addExpModal",
    modalTitle: "General Expenses",
    headerPart: false,
    footerPart: true,
    cancelButtonTitle:"Send for Approval",
    submitTitle: "Add Expense",
    callBackFreight:callBackFreight
    // getAccountListHandler: getAccountListHandler
  }

  // let AddTransactionAccountModal = {
  //   isOpen: editOpen,
  //   closeModal: (e) => setEditOpen(false),
  //   modalMainClass: "_dashCommon_Modal _mdModal",
  //   modalTitle: "Add Account",
  //   // headerPart: true,
  //   footerPart: true,
  //   submitTitle: "Add Account",
  // }


  return (
    <>
      <ButtonHeading
        onClick={() => setShow(!show)}
        title={"Transactions"}
        buttonTitle="Add Account"
        Expenses={true}
        expenseOnClick={() => setShowExpModal(!showExpModal)}
      />

      {accounts ? <div className='_trancMain_bx'>
        {accounts?.sub_category?.map((i, index) => {
          return <div className='container-fluid' key={`${i.id}_${i?.name}`}>
            <h4 className='_trancHead'>{i?.name}</h4>

            <div className="row cashInHnad_s">
              {i?.accounts?.map((k) => {

                return <div className="col-md-6 col-lg-4 col-xl-3">
                  <Link to='/accounting/transactions/bank_transaction_list' className='branch_inner' state={{ data: { category_id: accounts?.id, sub_category_id: i?.id, account_id: k?.id } }}>
                    <div className='branch_left'>
                      <div>
                        <h6>{k?.name}</h6>
                        <span className={k?.balance < 0 ? '_minusRs' : "_plusRs"}>{`₹ ${k?.balance}`}</span>
                      </div>
                    </div>
                    <div className='branch_right'>
                      <img src={Images.Wallet.default} alt="" />
                    </div>
                  </Link>
                </div>
              })}

              {/* <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div> */}

            </div>
          </div>
        })}

        {/* <div className='container-fluid'>
          <h4 className='_trancHead'>Banking Account</h4>

          <div className="row cashInHnad_s">
            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

          </div>
        </div> */}

        {/* <div className='container-fluid'>
          <h4 className='_trancHead'>Credit Card Account</h4>

          <div className="row cashInHnad_s">
            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_minusRs'>₹ -598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3">
              <a href='#' className='branch_inner'>
                <div className='branch_left'>
                  <div>
                    <h6>Indore Branch</h6>
                    <span className='_plusRs'>₹ 598501.66</span>
                  </div>
                </div>
                <div className='branch_right'>
                  <img src={Images.Wallet.default} alt="" />
                </div>
              </a>
            </div>

          </div>
        </div> */}

        {show
          && (
            // <AddChartAccountModal {...AddTransactionAccountModal} />
            <AddTransctionsModal {...AddTransactionAccountModal} />
          )
        }

        {showExpModal
          && (
            <AddExpensesModal {...AddExpensesModals} />
          )
        }


      </div> : <Loading />}
    </>
  )
}

export default Transactions