import { handleKeyDown } from "_services";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { UserContext } from "context";
import { addTotalFrieght, editTotalFrieght } from "features";
import { errorSchema, utils } from "helper";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import StorageService from "utils/StorageServices/StorageServices";

const AddTotalFreightModal = (props) => {
  const intialTotal = {
    trip_id: props?.tripId,
    freight_type: 4,
    qty: "",
    rate: "",
    amount: "",
    tds_diduction: 0,
  };
  const dispatch = useDispatch();
  const { masterData } = useContext(UserContext);
  const [tdsChecked, setTdsChecked] = useState(false);
  const [tdsList, setTDsList] = useState([]);
  const [formTotal, setFormTotal] = useState(intialTotal);
  const [formTotalName, setFormTotalName] = useState(intialTotal);
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [freightTypeList, setFreightTypeList] = useState([]);

  useEffect(() => {
    if (masterData) {
      const Tds = masterData.tds_section_list?.map((item) => {
        return { label: item?.name, value: item?.id };
      });
      const lrFreight = masterData?.contract_rate_type_list?.map((item) => {
        return { label: item?.name, value: item?.id };
      });
      setTDsList(Tds);
      setFreightTypeList(lrFreight);
      setFormTotal({ ...formTotal, trip_id: props?.tripId });
    }
  }, [masterData]);

  useEffect(() => {
    if (props?.totalFreightDetails) {

      setFormTotal({
        ...formTotal,
        amount: props?.totalFreightDetails?.balance,
        rate: props?.totalFreightDetails?.rate,
        qty: props?.totalFreightDetails?.qty,
        freight_type:props?.totalFreightDetails?.freight_type,
        tds_diduction:props?.totalFreightDetails?.tds_deduction
      });
      if(props?.totalFreightDetails?.tds_deduction > 0){
        setTdsChecked(true)
      }
    }
  }, [props?.totalFreightDetails]);

  useEffect(() => {
    if (!tdsChecked) {
   
      setFormTotal({ ...formTotal, tds_diduction: 0, trip_id: props?.tripId });
      setFormTotalName({
        ...formTotalName,
        tds_diduction: { label: "", value: "" },
      });
      // setFormTotalName(intialTotal)
    } else {
 
      setFormTotal({ ...formTotal, tds_diduction: 1, trip_id: props?.tripId });
      setFormTotalName({
        ...formTotalName,
        tds_diduction: { label: "1%", value: "1" },
      });
    }
  }, [tdsChecked]);

  useEffect(() => {
    if(formTotal?.freight_type!==6){
        setFormTotal({ ...formTotal, amount: Number(formTotal?.qty) * Number(formTotal.rate) });
    }
  }, [formTotal.rate, formTotal?.qty ]);

  useEffect(() => {
    if (formTotal.freight_type === 6) {
      setFormTotal({ ...formTotal, rate: 0, qty: 0 });
    } else {
      if(!props?.totalFreightDetails){
        setFormTotal({ ...formTotal, rate: "", qty: "" });
      }
     
    }
  }, [formTotal.freight_type]);


  

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...formTotal,
      [name]: value,
    };
    setFormTotal(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        { ...stateObj },
        errorSchema.addTotalFreigthSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const onSubmitTotal = async () => {
    const validationResult = await utils.checkFormError(
      { ...formTotal },
      errorSchema.addTotalFreigthSchema
    );
    const payload = {
      company_id: StorageService?.getCompanyId(),
      trip_id: props?.tripId,
      freight_type: formTotal?.freight_type,
      qty: Number(formTotal?.qty),
      rate: Number(formTotal?.rate),
      amount: Number(formTotal?.amount),
      tds_diduction: formTotal?.tds_diduction ? formTotal?.tds_diduction : 0,
    };
    try {
      if (validationResult === true) {
        setIsLoading(true);
        const res = await dispatch(!props?.totalFreightDetails?addTotalFrieght(payload):editTotalFrieght(payload));
        if (res?.payload?.responseCode === 200) {
          setIsLoading(false);
          handleCloseTotal();
          props.callbackTotal();
        }
      } else {
        setFormError(validationResult);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const allClear = () => {
    setFormTotal({ ...intialTotal });
  };
  const handleCloseTotal = () => {
    props?.closeModal();
    allClear();
  };

  const modifyProps = {
    ...props,
    onSubmit: onSubmitTotal,
    isLoading: isLoading,
    disabled: props?.userPermission?.add_total_freight ? isLoading : true,
   
  };
  return (
    <div>
      <CommonModal {...modifyProps}>
        <div className="addTotalFreight_body">
          <div className="_qntSelector">
            <CustomSelect
              label="Freight Type"
              options={freightTypeList?.length ? freightTypeList : []}
              onSelect={(data) => handleOnChange("freight_type", data?.value)}
              value={freightTypeList?.find(
                (i) => i?.value === formTotal?.freight_type
              )}
              focus={!!formError?.freight_type}
              error={formError.freight_type}
            />
          </div>

          {formTotal?.freight_type !== 6 ? (
            <div className="_clmsFlex">
              <div className="clmDual_fx">
                <CommonInput
                  type="text"
                  name="qty"
                  value={formTotal.qty}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  label={
                    freightTypeList.length
                      ? freightTypeList?.find(
                          (i) => i?.value === formTotal?.freight_type
                        ).label
                      : "Qty"
                  }
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.qty}
                  error={formError.qty}
                />
              </div>
              <div className="clmDual_fx">
                <CommonInput
                  type="text"
                  label="Rate"
                  name="rate"
                  value={formTotal.rate}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.rate}
                  error={formError.rate}
                />
              </div>
            </div>
          ) : (
            <div className="">
              <CommonInput
                type="text"
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
                label={
                  freightTypeList.length
                    ? freightTypeList?.find(
                        (i) => i?.value === formTotal?.freight_type
                      ).label
                    : "Qty"
                }
                placeholder="Enter Freight"
                value={formTotal?.amount}
                name="amount"
                onKeyDown={handleKeyDown}
                focus={!!formError?.amount}
                error={formError.amount}
              />
            </div>
          )}

          <div className="_amtBaar">
            <p>Amount</p>
            <p>{`Rs. ${formTotal?.amount ? formTotal?.amount : 0}`}</p>
          </div>

          {/* <CommonInput
            type="text"
            label="Amount"
            placeholder="Enter Amount"
            onKeyDown={handleKeyDown}
            value={formTotal?.amount}
            onChange={(e) => handleOnChange("amount", e.target.value)}
          />
          {formError.amount  && <small className="text-danger">{formError.amount}</small>} */}

          {/* <div className="form-check form-switch">
            <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={tdsChecked}
            onChange={(e) => setTdsChecked(e.target.checked)}
            />
            <label className="form-check-label" for="flexSwitchCheckDefault">
            {tdsChecked ? "Yes" : "No"}
            </label>
            </div> */}

          <div>
            <span className="_tdsLabel">TDS Deduction</span>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={tdsChecked}
                onChange={(e) => setTdsChecked(e.target.checked)}
              />
              <label className="form-check-label" for="flexSwitchCheckDefault">
                {tdsChecked ? "Yes" : "No"}
              </label>
            </div>
            {tdsChecked && (
              <CustomSelect
                label="TDS Deduction %"
                // value={formTotalName?.tds_diduction }
                value={
                  formTotal?.tds_diduction !== 0
                    ? tdsList?.find(
                        (i) => i?.value === formTotal?.tds_diduction
                      )
                    : { label: "", value: "" }
                }
                options={tdsList}
                disabled={!tdsChecked}
                onSelect={(data) => {
                  handleOnChange("tds_diduction", data?.value);
                  setFormTotalName({ ...formTotalName, tds_diduction: data });
                }}
                focus={!!formError?.tds_diduction}
                error={formError.tds_diduction}
              />
            )}
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default AddTotalFreightModal;
