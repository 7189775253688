import React, { useContext, useEffect, useState, useRef } from 'react'
import { ButtonHeading, CommonModal, CustomPagination } from 'components'
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { AddEditOwnDriver, AddEditVehicleModal } from 'modals';
import { changeVehicleStatusData, deleteVehicleData, getVehicleListData } from '_services/accountsServices/vehicleServices';
import { Link } from 'react-router-dom';

import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { UserContext } from 'context';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import MastersFilterModal from 'modals/MastersFilterModal';
import AddEditSupplier from 'modals/AddEditSupplier';
import LoadingBar from 'react-top-loading-bar';

const Vehicle = () => {

    const loadingBarRef = useRef(null);
    const { masterData, mastersChild } = useContext(UserContext)
    const masterChildTrucks = mastersChild?.find((i) => i?.label === "Trucks");
    const [showOffcanvasOfSupplier, setShowOffcanvasOfSupplier] = useState(false);
    const [showOffcanvasOfVehical, setShowOffcanvasOfVehical] = useState(false);
    const [showOffcanvasOfDriver, setShowOffcanvasOfDriver] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [isSupplier, setIsSupplier] = useState(false);
    const [isDriver, setIsDriver] = useState(false);

    const [vehicleList, setVehicleList] = useState([])
    const [loading, setLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const itemsPerPage = 10;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({
        limit: 10,
        offset: 1,
        search: '',
        status: 1
    });

    const [editVehicleData, setEditVehicleData] = useState(null)
    const [checkboxStates, setCheckboxStates] = useState({});

    const toggleOffcanvas = () => {
        setShowOffcanvasOfVehical(!showOffcanvasOfVehical);
        setEditVehicleData(null)
    };

    const getDataVehicleList = async () => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                ...filter,
                company_id: StorageService.getCompanyId(),
            }
            const res = await getVehicleListData(object);
            if (res?.responseCode == 200) {
                setVehicleList(res?.data?.vehicle_list);
                setTotalItems(res?.data?.total_record);
            }
        } catch (error) {
            console.log('Error', error)
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        getDataVehicleList();
    }, [filter, showOffcanvasOfVehical])

    const deleteHandler = (item) => {
        setIsDelete(item)
    }

    const submitDeleteVehicle = async () => {
        setLoading(true);
        try {
            let object = {
                company_id: isDelete?.company_id,
                vehicle_id: isDelete?._id,
            }
            const res = await deleteVehicleData(object);
            if (res?.responseCode == 200) {
                toast.success(`${res?.responseMessage}`);
                getDataVehicleList();
                setLoading(false);
                setIsDelete(false);
            } else {
                toast.success('Error !! ');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    // handling Add New Supplier button in Dropdown
    const handleAddNewSupplierButtonAction = () => {
        // setShowOffcanvasOfVehical(false);
        setIsSupplier(false);
        setShowOffcanvasOfSupplier(true)
    }

    const handleAddNewVehicleButtonAction = () => {
        // setShowOffcanvasOfVehical(false);
        setIsDriver(false)
        setShowOffcanvasOfDriver(true)
    }

    let propsObject = {
        isOpen: isDelete,
        closeModal: setIsDelete,
        modalTitle: "Confirmation",
        submitTitle: "Confirm",
        cancleTitle: "Close",
        onSubmit: submitDeleteVehicle,
        isLoadding: loading,
    }

    const columns = [
        { header: "", accessor: "serial" },
        { header: "Vehicle Number", accessor: "vehicle_no" },
        { header: "Type", accessor: "type" },
        { header: "Ownership", accessor: "ownership" },
        { header: "Assoicated", accessor: "assoicated" },
        { header: "Vehicle Status", accessor: "vehicle_status" },
        { header: "Trip Status", accessor: "trip_status" },
        { header: "Status", accessor: "status" },
       
    ];

    useEffect(() => {
        if (vehicleList && vehicleList.length > 0) {
            const initialStates = vehicleList.reduce((acc, item) => {
                acc[item._id] = Boolean(item?.active);
                return acc;
            }, {});
            setCheckboxStates(initialStates);
        }
    }, [vehicleList]);

    const handleCheckboxChange = (id) => {
        const newStatus = !checkboxStates[id];
        setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

        const object = {
            company_id: StorageService.getCompanyId(),
            vehicle_id: id,
            active: Number(newStatus)
        };
        statusChangeofvehicle(object);
    };

    const statusChangeofvehicle = async (obj) => {
        try {
            if (obj) {
                const res = await changeVehicleStatusData(obj);
                if (res?.responseCode === 200) {
                    toast.success(`${res?.responseMessage}`);
                } else {
                    toast.error('Error !!');
                    await getDataVehicleList();
                }
            }
        } catch (error) {
            toast.error(error.message);
            await getDataVehicleList();
        }
    };

    const editHandler = (supplier) => {
        setShowOffcanvasOfVehical(true);
        setEditVehicleData(supplier);
    };

    const RowListData = (data) => {
        return data?.map((item, i) => ({
            ...item,
            // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
            serial: <>
                <div className="signDropdown">
                    <button className="dropbtn11">
                        <BsThreeDotsVertical />
                    </button>
                    <div className="dropdownContent">
                        {masterChildTrucks?.permissions?.view === 1 && <Link to={`/account/vehicle/details/${item?._id}`} className='_avPointer'
                            style={{ textDecoration: 'none' }}
                        >
                            <span className='_samAnchor'>
                                <span className='viewIcon_avt'>
                                    <AiOutlineEye />
                                </span>View
                            </span>
                        </Link>}

                        {masterChildTrucks?.permissions?.edit === 1 && <div
                            onClick={(e) => editHandler(item)}
                            className='_avPointer'
                        >
                            <span className='_samAnchor'>
                                <span className='editIcon_avt'>
                                    <FiEdit />
                                </span>
                                Edit
                            </span>
                        </div>}

                        {masterChildTrucks?.permissions?.delete === 1 && <div
                            onClick={(e) => deleteHandler(item)}
                            className='_avPointer'
                        >
                            <span className='_samAnchor'>
                                <span className='deleteIcon_avt'>
                                    <FaRegTrashCan color='red' />
                                </span>
                                Delete
                            </span>
                        </div>}
                    </div>
                </div>
            </>,
            vehicle_no:
                <Link to={`/account/vehicle/details/${item?._id}`}
                    style={{ textDecoration: 'none' }}
                >
                    {item?.vehicle_no ? item?.vehicle_no : '-'}
                </Link>,
            type:
                <React.Fragment>
                    {item?.lorry_type_id?.lorry_type ? item?.lorry_type_id?.lorry_type : '-'}
                    {item?.vehicle_type_id_1?.name ? item?.vehicle_type_id_1?.name : '-'}
                </React.Fragment>,
            ownership:
                <React.Fragment>
                    {masterData.vehicle_ownership[item?.vehicle_ownership]}
                </React.Fragment>,
            assoicated:
                <React.Fragment>
                    {item?.city?.label ? item?.city?.label : '-'}
                </React.Fragment>,
            vehicle_status:
                <React.Fragment>
                    {masterData.vehicle_status[item?.vehicle_status]}
                </React.Fragment>,
            trip_status:
                <React.Fragment>
                    -
                </React.Fragment>,
            status: (
                <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="actStatus"
                        id={`actStatus-${item._id}`}
                        autoComplete="off"
                        checked={checkboxStates[item._id]}
                        onChange={() => handleCheckboxChange(item._id)}
                    />
                    <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
                        {checkboxStates[item._id] ? "Active" : "Disabled"}
                    </span>
                </div>
            ),
            action: (
                <React.Fragment>
                    -
                </React.Fragment>
            ),
        }));
    };

    const handlePageChange = (page) => {
        setFilter((s) => ({ ...s, offset: page }))
    };
   

    const handleSupplierNextModal = () => {
        setIsSupplier(!isSupplier)
        setShowOffcanvasOfVehical(true)
    }

    const handleDriverNextModal = () => {
        setIsDriver(!isDriver)
        setShowOffcanvasOfVehical(true)
    }

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading
                title={"Vehicle"}
                onClick={toggleOffcanvas}
                buttonTitle={masterChildTrucks?.permissions?.create === 1 && "Add Vehicle"}
                showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
                onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
                filter={filter}
                setFilter={setFilter}
            />

            <div className='_tabWith_outTab'>
                <BootstrapTable
                    columns={columns}
                    data={RowListData(vehicleList?.length > 0 ? vehicleList : [])}
                    isLoading={loading}
                />

               {vehicleList?.length &&  <div className='paginationFt_avt'>
                <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
                </div>}
            </div>

            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvasOfVehical ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showOffcanvasOfVehical ? 'visible' : 'hidden' }}
            >
                {showOffcanvasOfVehical &&
                    <AddEditVehicleModal
                        isOpen={showOffcanvasOfVehical}
                        onClose={() => setShowOffcanvasOfVehical(false)}
                        isSupplier={isSupplier}
                        isDriver={isDriver}
                        handleAddNewSupplierButtonAction={handleAddNewSupplierButtonAction}
                        handleAddNewVehicleButtonAction={handleAddNewVehicleButtonAction}
                    />
                }
            </div>

            <div className={showOffcanvasOfVehical ? 'offBack_drop' : ''}></div>

            {/* Supplier Canvas Open and Vahicle canvas will close */}
            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvasOfSupplier ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showOffcanvasOfSupplier ? 'visible' : 'hidden' }}
            >
                {showOffcanvasOfSupplier &&
                    <AddEditSupplier
                        isOpen={showOffcanvasOfSupplier}
                        onClose={(e) => setShowOffcanvasOfSupplier(false)}
                        callBackSupplier={(e) => handleSupplierNextModal(e)}
                    />
                }
            </div>
            <div className={showOffcanvasOfSupplier ? 'offBack_drop' : ''}></div>

            {/* Driver Canvas Open and Vahicle canvas will close */}
            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvasOfDriver ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showOffcanvasOfDriver ? 'visible' : 'hidden' }}
            >
                {showOffcanvasOfDriver &&
                    <AddEditOwnDriver
                        isOpen={showOffcanvasOfDriver}
                        onClose={() => setShowOffcanvasOfDriver(false)}
                        callBackDriver={(e) => handleDriverNextModal(e)}
                    />
                }
            </div>
            <div className={showOffcanvasOfDriver ? 'offBack_drop' : ''}></div>

            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
            >
                {showFilterModal &&
                    <MastersFilterModal
                        isOpen={showFilterModal}
                        onClose={() => setShowFilterModal(false)}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
            </div>
            <div className={showFilterModal ? 'offBack_drop' : ''}></div>

            {
                isDelete?.company_id && (
                    <CommonModal {...propsObject}>
                        <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
                    </CommonModal>
                )
            }
        </div>
    )
}

export default Vehicle