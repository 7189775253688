import React, { useContext, useEffect, useState } from 'react'
import { fetchSingleUnitData } from '_services/accountsServices/unitServices';
import { CommonInput } from 'components';
import { UserContext } from 'context';
import { useParams } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';
import toast from 'react-hot-toast';
import { getAddressDetailsApi } from '_services/accountsServices/addressBookService';

const UnitDetailsTab = ({ onDataFetch, loadingBarRef }) => {

    let { id } = useParams();
    const { masterData } = useContext(UserContext)
    let company_id = StorageService.getCompanyId();
    const [detailsData, setDetailsData] = useState({})
    const [loading, setLoading] = useState(false);

    const getDataUnitDetails = async (id_1, id_2) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                addressbook_id: id_1,
                company_id: id_2,
            };
            const res = await getAddressDetailsApi(object);
            if (res?.responseCode == 200) {
                setDetailsData(res?.data?.book_detail);
                onDataFetch(res?.data?.book_detail); // Call the callback function
                setLoading(false)
            }
        } catch (error) {
            toast.error("error", error);
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    };


    useEffect(() => {
        if (id && company_id) {
            setLoading(true);
            getDataUnitDetails(id, company_id);
        }
    }, [id])

    return (
        <div>
            {
                loading ? <div className='p-5 mt-2'> Loading... </div> : (
                    <div className='_addContainer2 '>
                        <div className='_ptyDetail_bx'>
                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Basic Details</div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            placeholder="Name"
                                            label="Name *"
                                            name='name'
                                            value={detailsData?.name}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Unit Code"
                                            placeholder="Unit Code"
                                            value={detailsData?.unit_code}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Mobile Number"
                                            placeholder="Mobile Number"
                                            value={detailsData?.contact_no}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs ">
                                <div className='_inrPrt_head'> Address </div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Address Line 1"
                                            placeholder="Address Line 1"
                                            value={detailsData?.address_line1}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State"
                                            placeholder="State"
                                            value={detailsData?.state?.label}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="City"
                                            placeholder="City"
                                            value={detailsData?.city?.label}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="PIN Code"
                                            placeholder="PIN Code"
                                            value={detailsData?.pincode}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Tax Info</div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="GST Type"
                                            placeholder="GST Type"
                                            value={masterData?.gst_type_list?.filter((el) => (el?.id == detailsData?.gst_type))[0]?.name}
                                            disabled
                                        />
                                    </div>

                                    {detailsData?.gst_no ? <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="GST Number"
                                            placeholder="GST Number"
                                            value={detailsData?.gst_no}
                                            disabled
                                        />
                                    </div> : <></>}

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State State"
                                            placeholder="State State"
                                            value={detailsData?.gst_state?.label}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'> Other Details </div>
                                <div className='_inrPrt_flx '>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Zone"
                                            placeholder="Zone"
                                            value={detailsData?.zone_id?.name}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Party Group"
                                            placeholder="Party Group"
                                            value={detailsData?.consignor_id?.name}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default UnitDetailsTab