import { deleteConsignorData } from '_services/accountsServices/consignorServices';
import { changeUnitStatusData, deleteUnitData, getUnitListData } from '_services/accountsServices/unitServices';
import { ButtonHeading, CommonModal, CustomPagination, NavTabs } from 'components'
import { AddEditUnitModal, AddUnitModal } from 'modals';
import React, { useContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';
import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import MastersFilterModal from 'modals/MastersFilterModal';
import LoadingBar from 'react-top-loading-bar';
import { deleteAddressBook } from '_services/accountsServices/addressBookService';
import { UserContext } from 'context';

export default function Unit(props) {

  let { id, handleScrollClass = '' } = props

  const { mastersChild } = useContext(UserContext);
  const masterChildUnit = mastersChild?.find((i) => i?.label === "Unit");

  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [unitList, setUnitList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);


  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1
  });

  const [editUnitData, setEditUnitData] = useState(null)
  const [checkboxStates, setCheckboxStates] = useState({});
  const [addressId, setAddressId] = useState({
    id: "",
    title: "Unit"
  })

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
    setEditUnitData(null)
  };

  const getDataUnitList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      }
      const res = await getUnitListData(object);
      if (res?.responseCode == 200) {
        setUnitList(res?.data?.unit_list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      throw error;
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  useEffect(() => {
    getDataUnitList();
  }, [filter, showOffcanvas])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteConsignor = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        addressbook_id: isDelete?._id,
      }
      const res = await deleteAddressBook(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataUnitList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteConsignor,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Unit Name", accessor: "unit_name" },
    { header: "Unit Code", accessor: "unit_code" },
    { header: "Unit Group", accessor: "unit_group" },
    { header: "City", accessor: "city" },
    { header: "Zone", accessor: "zone" },
    { header: "Status", accessor: "status" },

  ];


  useEffect(() => {
    if (unitList && unitList.length > 0) {
      // Initialize checkboxStates based on data
      const initialStates = unitList.reduce((acc, item) => {
        acc[item._id] = Boolean(item?.active);
        return acc;
      }, {});
      setCheckboxStates(initialStates);
    }
  }, [unitList]);

  const handleCheckboxChange = (id) => {
    const newStatus = !checkboxStates[id];
    setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

    const object = {
      company_id: StorageService.getCompanyId(),
      unit_id: id,
      active: Number(newStatus)
    };
    statusChangeofUnit(object);
  };


  const statusChangeofUnit = async (obj) => {
    try {
      if (obj) {
        // const res = await changeUnitStatusData(obj)
        // if (res?.responseCode == 200) {
        //   toast.success(`${res?.responseMessage}`);
        // } else {
        //   toast.error('Error !!');
        await getDataUnitList();
        // }
      }
    } catch (error) {
      toast.error(error.message);
      await getDataUnitList();
    }
  };

  const editHandler = (supplier) => {
    setShowOffcanvas(true);
    setEditUnitData(supplier);
    setAddressId({
      ...supplier,
      id: supplier?._id
    })
  };

  const RowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
      serial: <>
        <div className="signDropdown">
          <button className="dropbtn11">
            <BsThreeDotsVertical />
          </button>
          <div className="dropdownContent">
            {masterChildUnit?.permissions?.view === 1 &&
              <Link to={`/account/unit/details/${item?._id}`}
                className='_avPointer'
                style={{ textDecoration: 'none' }}
              >
                <span className='_samAnchor'>
                  <span className='viewIcon_avt'>
                    <AiOutlineEye />
                  </span>View
                </span>
              </Link>
            }
            {masterChildUnit?.permissions?.edit === 1 &&
              <div
                onClick={(e) => editHandler(item)}
                className='_avPointer'
              >
                <span className='_samAnchor'>
                  <span className='editIcon_avt'>
                    <FiEdit />
                  </span>
                  Edit
                </span>
              </div>
            }
            {masterChildUnit?.permissions?.delete === 1 &&
              <div
                onClick={(e) => deleteHandler(item)}
                className='_avPointer'
              >
                <span className='_samAnchor'>
                  <span className='deleteIcon_avt'>
                    <FaRegTrashCan color='red' />
                  </span>
                  Delete
                </span>
              </div>
            }
          </div>
        </div>

      </>,
      unit_name:
        <Link to={`/account/unit/details/${item?._id}`} style={{ textDecoration: 'none' }}>
          {item?.name ? item?.name : '-'}
        </Link>,
      unit_code:
        <React.Fragment>
          {item?.unit_code ? item?.unit_code : '-'}
        </React.Fragment>,
      unit_group:
        <React.Fragment>
          {item?.unit_group?.name ? item?.unit_group?.name : '-'}
        </React.Fragment>,
      city:
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : '-'}
        </React.Fragment>,
      zone:
        <React.Fragment>
          {item?.zone_id?.name ? item?.zone_id?.name : '-'}
        </React.Fragment>,
      status: (
        <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={checkboxStates[item._id]}
            onChange={() => handleCheckboxChange(item._id)}
          />
          <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
            {checkboxStates[item._id] ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      balance:
        <React.Fragment>
          {item?.balance ? item?.balance : '-'}
        </React.Fragment>,
      action: (
        <React.Fragment>
          -
        </React.Fragment>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };

  const callBackAddressData = (data) => {
    getDataUnitList()
  }


  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Unit"}
        onClick={toggleOffcanvas}
        buttonTitle={masterChildUnit?.permissions?.create === 1 && "Add Unit"}
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />



      <div className={`_tabWith_outTab ${handleScrollClass}`}>
        <BootstrapTable
          columns={columns}
          data={RowListData(unitList?.length > 0 ? unitList : [])}
          isLoading={loading}
        />

        {unitList?.length && <div className='paginationFt_avt'>
          <CustomPagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={filter.offset}
            onPageChange={handlePageChange}
          />
        </div>}
      </div>

      {/* <div
        className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
      >
        {showOffcanvas &&
          <AddEditUnitModal isOpen={showOffcanvas} onClose={(e) => setShowOffcanvas(false)} />
        }
      </div>
      <div className={showOffcanvas ? 'offBack_drop' : ''}></div> */}

      <div>
        <div
          className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? "show" : ""
            }`}
          tabIndex={-1}
          style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
        >
          {showOffcanvas && (
            <AddUnitModal
              isOpen={showOffcanvas}
              onClose={(e) => {
                setAddressId({ id: "", title: "Unit" })
                setShowOffcanvas(false)
              }}
              id={addressId}
              callBackAddressData={callBackAddressData}
            />
          )}
        </div>
        <div className={showOffcanvas ? "offBack_drop" : ""}></div>
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
          </CommonModal>
        )
      }
    </div>
  )
}
