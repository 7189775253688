import { createFuelOutApi, getFuelRateApi, handleKeyDown } from "_services";
import { CommonDatePicker, CommonInput, CommonModal } from "components";
import { Images } from "constants";
import { UserContext } from "context";
import { endTrip } from "features";
import { errorSchema, utils } from "helper";
import moment from "moment";
import Loading from "pages/Loading";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import StorageService from "utils/StorageServices";

const FuelOutModal = (props) => {
  const { closeModal, callBackEnd, lastTripDetails,endTripFrom } = props;

  const initialFrom = {
    company_id: StorageService.getCompanyId(),
    trip_id: "",
    fuel_amount:"",
    fuel_ltr:"",
    fuel_out_time: new Date(),
   fuel_rate:""
  };

  const { masterData, globTripDetails } = useContext(UserContext);
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialFrom);
  const [formError, setFormError] = useState("");
  const [isLoadding, setIsLoadding] = useState(false);
  const [totalKm,setTotalKm] = useState(0)

  
  useEffect(()=>{
getFuelRateHandle()
  },[globTripDetails])


  const getFuelRateHandle = async()=>{
    const payload = {
      company_id:StorageService?.getCompanyId(),
      vehicle_id:globTripDetails?.vehicle_id?._id
    }

    const res = await getFuelRateApi(payload)
    
    if (res?.responseCode === 200) {
      setForm((pre)=>({...pre,fuel_rate:res.data?.vehicle?.average_fuel_rate}))
    }
  }
  
  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };


let formattedValue = value;

  if (name === "fuel_ltr"||name === "fuel_amount") {
   
    formattedValue = value.replace(/[^0-9.]/g, '');

 
    if (formattedValue.includes('.')) {
      const [integerPart, decimalPart] = formattedValue.split('.');
      formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
  }

  let updatedForm = { ...form, [name]: formattedValue };

  if (name === "fuel_ltr" && updatedForm.fuel_rate) {
    // Calculate fuel_amount based on fuel_ltr and fuel_rate
    const fuelRate = parseFloat(updatedForm.fuel_rate);
    const fuelLtr = parseFloat(formattedValue);
  
    // Ensure both values are valid numbers before performing the calculation
    if (!isNaN(fuelRate) && !isNaN(fuelLtr)) {
      updatedForm.fuel_amount = (fuelRate * fuelLtr).toFixed(2);
    } else {
      updatedForm.fuel_amount = ""; // Clear the value if invalid
    }
  } else if (name === "fuel_amount" && updatedForm.fuel_rate) {
    // Calculate fuel_ltr based on fuel_amount and fuel_rate
    const fuelRate = parseFloat(updatedForm.fuel_rate);
    const fuelAmount = parseFloat(formattedValue);
  
    // Ensure both values are valid numbers before performing the calculation
    if (!isNaN(fuelRate) && !isNaN(fuelAmount)) {
      updatedForm.fuel_ltr = (fuelAmount / fuelRate).toFixed(2);
    } else {
      updatedForm.fuel_ltr = ""; // Clear the value if invalid
    }
  }
  
  setForm((prevForm) => ({
    ...prevForm,
    ...updatedForm,
  }));


    if (!!formError) {
      const validationResult = await utils.checkFormError(
        { ...stateObj },
        errorSchema.fueloutTripSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }

    
  };

  const onSubmitEnd = async () => {
    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.fueloutTripSchema
    );

   
    const payload = {
      company_id: form?.company_id,
      trip_id: globTripDetails?._id,
      date: utils.convertToISO(form?.fuel_out_time,true),
      fuel_out:form?.fuel_ltr,
      fuel_amount:form?.fuel_amount
    };

    if (validationResult === true) {
      setIsLoadding(true);
      try {
        const res = await createFuelOutApi(payload)
       
        if (res?.responseCode === 200) {
          setIsLoadding(false);
          closeModal();
          callBackEnd();
          toast.success(res?.responseMessage)
        }else{
          setIsLoadding(false);
          toast.error(res?.responseMessage)
        }
      } catch {
        
        setIsLoadding(false);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const propsEndTrip = {
    ...props,
    isLoadding: isLoadding,
    disabled: isLoadding,
    onSubmit: onSubmitEnd,
  };
  

  return (
    <>
      <CommonModal {...propsEndTrip}>
        <div className="">
        
          <>
            
            <div className="_endTr_body">
              <div className="singleDiv">
            
                <CommonDatePicker
                  name="date"
                  label="Fuel Out Time"
                  startDate={form?.fuel_out_time}
                  setStartDate={(data) => {
                    handleOnChange("date", data);
                  }}
                  error={!form?.fuel_out_time ? "Required" : ""}
                />
              </div>

              
             <>
             <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Fuel Ltr"
                  name="fuel_ltr"
                  value={form?.fuel_ltr}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                  }}
                 
                  focus={!!formError?.fuel_ltr}
                  error={formError.fuel_ltr }
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Fuel Amount"
                  name="fuel_amount"
                  value={form?.fuel_amount}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                  }}
                  focus={!!formError?.fuel_amount}
                  error={formError.fuel_amount }
                  onKeyDown={handleKeyDown}
                />
              </div>
              
             </>
             <div className="singleDiv _balSuccess">
                <p>Fuel Average Rate </p>

                <p>
                  {(
                   
                    form?.fuel_rate?form?.fuel_rate:0
                  )}
                </p>
              </div>
            </div>
          </>
        
        </div>
      </CommonModal>
    </>
  );
};

export default FuelOutModal;
