import React from 'react'

const Tab = ({ tabList, callBackTab, activeTab, invoiceBillRaiseClass,disabled=false , isCountShow=false }) => {



    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        callBackTab(value);
    };

    return (
        <>
            <ul className={`nav nav-tabs cstNew_tabs ${invoiceBillRaiseClass}`} id="addParty" role="tablist">
                {tabList?.map((item, indx) => (
                    <li
                        className="nav-item _vhlTab_li"
                        role="presentation"
                        key={indx + item?.value}
                    >
                        <button
                            className={
                                "nav-link tabCst_btn _vhlTab_lst " + (activeTab === item?.id ? "active" : "")
                            }
                            type="button"
                            aria-selected={activeTab === item?.id}
                            onClick={(e, val) => changeActiveTab(e, item.id)}
                            // style={{textAlign: 'left'}}
                            disabled={disabled}
                        >
                           <span>{` ${item?.title} `} </span>
                           {isCountShow && <div className='_nwRate'> {`${item?.data != null ? (item?.data).toFixed(2) : 0} `} </div>}
                           {item?.count !== undefined && <span className='_counts'> {item?.count} </span>}
                         
                        </button>
                        
                    </li>
                ))}

            </ul>
        </>
    )
}

export default Tab