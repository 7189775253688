import React, { useContext, useState } from 'react';
import Tab from 'components/common/Tab';
import { constant } from 'constants/constant';
import OwnDriver from '../OwnDriver';
import MarketDriver from '../MarketDriver';
import { ButtonHeading } from 'components';
import { UserContext } from 'context';

const Driver = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const { mastersChild } = useContext(UserContext);
    const masterChildDriver = mastersChild?.find((i) => i?.label === "Driver");

    const tabProps = {
        tabList: constant.driverTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    };

    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    return (
        <div>
            <ButtonHeading
                title="Driver"
                buttonTitle={masterChildDriver?.permissions?.create === 1 && (activeTab === 1 ? "Add Own Driver" : "Add Market Driver")}
                onClick={toggleOffcanvas}
            />
            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <OwnDriver showOffcanvas={showOffcanvas} toggleOffcanvas={toggleOffcanvas} />
                )}

                {activeTab === 2 && (
                    <MarketDriver showOffcanvas={showOffcanvas} toggleOffcanvas={toggleOffcanvas} />
                )}
            </div>
        </div>
    );
}

export default Driver;
