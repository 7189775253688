
import React, { useEffect, useState, useRef, useContext } from 'react'
import { ButtonHeading, CommonModal, CustomPagination } from 'components'
import { deleteConsignorData } from '_services/accountsServices/consignorServices';
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { changeVendorStatusData, deleteVendersData, getVendersListData } from '_services/accountsServices/vendorsServices';
import { AddEditVendors } from 'modals';
import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import MastersFilterModal from 'modals/MastersFilterModal';
import RupeeIcon from '../Supplier/RupeeIcon';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from 'context';

const Vendors = (props) => {

  const loadingBarRef = useRef(null);

  const { mastersChild } = useContext(UserContext);
  const masterChildVendors = mastersChild?.find((i) => i?.label === "Vendors");

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [vendersList, setVendersList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1
  });
  const [editVendorData, setEditVendorData] = useState(null)
  const [checkboxStates, setCheckboxStates] = useState({});

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
    setEditVendorData(null)
  };

  const getDataVendersList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      }
      const res = await getVendersListData(object);
      if (res?.responseCode == 200) {
        setVendersList(res?.data?.list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      throw error;
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }


  useEffect(() => {
    getDataVendersList();
  }, [filter, showOffcanvas])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteConsignor = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        vendor_id: isDelete?._id,
      }
      const res = await deleteVendersData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataVendersList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteConsignor,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Vender Name", accessor: "vender_name" },
    { header: "Contact No.", accessor: "contact_nunber" },
    { header: "City", accessor: "city" },
    { header: "Status", accessor: "status" },
    { header: "Balance", accessor: "balance" },
    
  ];

  useEffect(() => {
    if (vendersList && vendersList.length > 0) {
      const initialStates = vendersList.reduce((acc, item) => {
        acc[item._id] = Boolean(item?.active);
        return acc;
      }, {});
      setCheckboxStates(initialStates);
    }
  }, [vendersList]);

  const handleCheckboxChange = (id) => {
    const newStatus = !checkboxStates[id];
    setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

    const object = {
      company_id: StorageService.getCompanyId(),
      verdor_id: id,
      active: Number(newStatus)
    };
    statusChangeofVendor(object);
  };

  const statusChangeofVendor = async (obj) => {
    try {
      if (obj) {
        const res = await changeVendorStatusData(obj);
        if (res?.responseCode === 200) {
          toast.success(`${res?.responseMessage}`);
        } else {
          toast.error('Error !!');
          await getDataVendersList();
        }
      }
    } catch (error) {
      toast.error(error.message);
      await getDataVendersList();
    }
  };

  const editHandler = (supplier) => {
    setShowOffcanvas(true);
    setEditVendorData(supplier);
  };

  const RowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
      serial: <>
        <div className="signDropdown">
          <button className="dropbtn11">
            <BsThreeDotsVertical />
          </button>
          <div className="dropdownContent">
            {masterChildVendors?.permissions?.view === 1 && <Link to={`/account/vendors/details/${item?._id}`} className='_avPointer'
              style={{ textDecoration: 'none' }}
            >
              <span className='_samAnchor'>
                <span className='viewIcon_avt'>
                  <AiOutlineEye />
                </span>
                &nbsp; View
              </span>
            </Link>}

            {masterChildVendors?.permissions?.edit === 1 && <div
              onClick={(e) => editHandler(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='editIcon_avt'>
                  <FiEdit />
                </span>
                Edit
              </span>
            </div>}

            {masterChildVendors?.permissions?.delete === 1 && <div
              onClick={(e) => deleteHandler(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='deleteIcon_avt'>
                  <FaRegTrashCan color='red' />
                </span>
                Delete
              </span>
            </div>}
          </div>
        </div>
      </>,
      vender_name: <Link
        to={`/account/vendors/details/${item?._id}`}
        style={{ textDecoration: 'none' }}
      >
        {item?.name ? item?.name : '-'}
      </Link>,
      contact_nunber:
        <React.Fragment>
          {item?.contact_no ? item?.contact_no : '-'}
        </React.Fragment>,
      party_group:
        <React.Fragment>
          {item?.consignor_group_id?.name ? item?.consignor_group_id?.name : '-'}
        </React.Fragment>,
      city:
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : '-'}
        </React.Fragment>,
      status: (
        <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={checkboxStates[item._id]}
            onChange={() => handleCheckboxChange(item._id)}
          />
          <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
            {checkboxStates[item._id] ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      balance:
        item?.balance ? (
          <Link to={`/account/supplier/details/${item?._id}?tab=4`} style={{ textDecoration: 'none' }}>
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              color: item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'
            }}>
              <span>
                <RupeeIcon
                  color={item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'}
                  size={20}
                />
              </span>
              <span>
                {item.balance}
              </span>
            </div>
          </Link>
        ) : (
          <Link to={`/account/supplier/details/${item?._id}?tab=4`} style={{ textDecoration: 'none' }}>
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              color: '#1450ec'
            }}>
              <span>
                <RupeeIcon
                  color='#1450ec'
                  size={20}
                />
              </span>
              <span>
                0
              </span>
            </div>
          </Link>
        )
      ,
      action: (
        <React.Fragment>
          -
        </React.Fragment>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };



  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Venders"}
        onClick={toggleOffcanvas}
        buttonTitle={masterChildVendors?.permissions?.create === 1 && "Add Venders"}
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />

      {/* <div className='p-5'>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">S No.</th>
              <th scope="col">Venders Name</th>
              <th scope="col">Venders Group</th>
              <th scope="col">Location</th>
              <th scope="col">Balance</th>
              <th scope="col">Created On</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {
              vendersList?.length > 0 ? (
                vendersList?.map((el, ind) => {
                  return (
                    <tr key={ind + 'abc'}>
                      <th scope="row">{ind + 1}</th>
                      <td>
                        <Link to={`/account/vendors/details/${el?._id}`}> {el?.name} </Link>
                      </td>
                      <td>{el?.consignor_group_id?.name}</td>
                      <td>{el?.city?.label ? el?.city?.label : '-'}</td>
                      <td>{el?.opening_balance}</td>
                      <td>{new Date(el?.as_of_date).toDateString()}</td>
                      <td>
                        <span disabled={loading}>Edit</span> &nbsp; &nbsp;
                        <span onClick={(e) => deleteHandler(el)}>Delete</span>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr > <td> No data available </td> </tr>
              )
            }
          </tbody>
        </table>
      </div> */}

      <div className='_tabWith_outTab'>
        <BootstrapTable
          columns={columns}
          data={RowListData(vendersList?.length > 0 ? vendersList : [])}
          isLoading={loading}
        />

        {vendersList?.length && <div className='paginationFt_avt'>
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
        </div>}
      </div>

      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Confirm Modal
      </button>

      <div className="modal fade confirmModal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="modal-title " id="exampleModalLabel"> <img src={Images.RISKICON} alt="" /> Leave this page ?</h4>
            </div>

            <div className="modal-body">If you leave, your unsaved changes will be discarded.</div>

            <div className="modal-footer">
              <button type="button" className="_addComm_btn">Save changes</button>
              <button type="button" className="_cancel_btn" data-bs-dismiss="modal">Close</button>
            </div>

          </div>
        </div>
      </div> */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
      >
        {showOffcanvas &&
          <AddEditVendors isOpen={showOffcanvas} onClose={(e) => setShowOffcanvas(false)} />
        }
      </div>
      <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
          </CommonModal>
        )
      }
    </div>
  )
}

export default Vendors