import { API, axiosInstance } from "lib";

export const planImport = (data = {}) => {
  return axiosInstance
    .post(API.PLAN + "import_plan", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const addressImport = (data = {}) => {
  return axiosInstance
    .post(API.ACCOUNT + "import_addressbook", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const fuelCardImport = (data = {}) => {
  return axiosInstance
    .post(API.FUEL_CARD + "import_fuelcard", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};