import { getallowedmenus } from "_services";
import { Images } from "constants";
import images from "constants/images";
import { UserContext } from "context";
import { ConfirmModalFor } from "modals";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setAllMenu,
    globCompanyId,
    setLrPermissions,
    setMastersChild,
    setSettingChild,
    setReportChild,
    setAccountingChild,
    setLrDetialsFrom,
    setDispatchIndent
  } = React.useContext(UserContext);
  const [menulist, setMenuList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [indexOuter, setOuterIndex] = useState(null);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [nextLink, setNextLink] = useState("");
  const companyIDSet = StorageService.getCompanyId()


  useEffect(() => {
    if (companyIDSet !== undefined && companyIDSet != null ) {
      getAllowSideBarMenu();
    }
  }, [companyIDSet, globCompanyId]);
  useEffect(() => {
    setLrDetialsFrom("");
  }, []);
  async function getAllowSideBarMenu() {
    const res = await getallowedmenus({
      company_id: StorageService.getCompanyId() || globCompanyId,
    });

    setMenuList(res?.data?.menu_list);
    setAllMenu(res?.data?.menu_list);
    setLrPermissions(res?.data?.menu_list?.filter((i) => i?.label === "LR")[0]);
    setMastersChild(
      res?.data?.menu_list?.filter((i) => i?.label === "Masters")[0]?.childs
    );
    setSettingChild(
      res?.data?.menu_list?.filter((i) => i?.label === "Settings")[0]?.childs
    );
    setReportChild(
      res?.data?.menu_list?.filter((i) => i?.label === "Reports")[0]?.childs
    );
    setAccountingChild(
      res?.data?.menu_list?.filter((i) => i?.label === "Accounting")[0]?.childs
    );
  }

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  let activeStyle = {
    // backgroundColor: "#0049D7",
    color: "",
  };

  const handleNavLinkClick = (link,event,index) => {
    event.preventDefault();
    let linkExists = false;

    // for (let item of menulist) {
    //   if (item.link === location?.pathname) {
    //     linkExists = true;
    //     break;
    //   }

    //   if (item.childs && item.childs.length > 0) {
    //     for (let child of item.childs) {
    //       if (child.link === location?.pathname) {
    //         linkExists = true;
    //         break;
    //       }
    //     }
    //   }

    //   if (linkExists) break;
    // }

    if(location?.pathname==="/trip/addtrip/createtrip" || location?.pathname==="/invoicing/createbillraise" || location?.pathname==="/invoicing/selectlr" || location?.pathname==="/invoicing/selectedlr" || location?.pathname==="/invoicing/summary"){
      setNextLink(link);
      setConfirmModalShow(true);
      setOuterIndex(index);
    }else{
      setActiveIndex(index);
      navigate(link);
     
    }

    // if (!linkExists) {
    //   setNextLink(link);
    //   setConfirmModalShow(true);
    //   setOuterIndex(index);
    // }else{
    //   setActiveIndex(index);
    //     navigate(link);
    // }
  };



  const handleConfirm = () => {
    setConfirmModalShow(false);
    setActiveIndex(indexOuter);
    navigate(nextLink);
    setDispatchIndent('')
  };

  const handleCancel = () => {
    setConfirmModalShow(false);

  };

  let confirmModalProps = {
    show: confirmModalShow,
    closeModal: handleCancel,
    title: "Leave This Page",
    submitTitle: "Confirm",
    onSubmit: handleConfirm,
    confirmModal: "confirmModal",
  };

  return (
    //  SideBar Start
    <aside className="sideBar">
      <div className="_logoInx">
        <img src={Images.LOGO2.default} alt="" />
      </div>

      <div className="menuBx_otr">
        <ul className="_simpList accordion" id="accordionExample">
          {menulist &&
            menulist?.map((item, outerIndex) => {
              if (!item?.childs?.length) {
                return (
                  item?.permissions?.view === 1 && (
                    <li className="_clk_li" key={`sidemenubar_${outerIndex}`}>
                      <NavLink
                        to={item?.link || "#"}
                        className={({ isActive }) =>
                          `_clk_a ${isActive ? "active" : ""}`
                        }
                        onClick={(event) =>
                          handleNavLinkClick(item?.link, event, outerIndex)
                        }
                      >
                        <i className={item?.icon}></i>
                        <span>{item?.label}</span>
                      </NavLink>
                    </li>
                  )
                );
              } else {
                return (
                  item?.permissions?.view === 1 && (
                    <li
                      className={`_clk_li accordion-item ${activeIndex === outerIndex ? "anvAccordian_active" : ""
                        }`}
                      key={`sidemenubar_${outerIndex}`}
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${item?.label}${outerIndex}`}
                        aria-expanded={`#${item?.label}${outerIndex}`}
                        aria-controls={`${item?.label}${outerIndex}`}
                      >
                        <i className={item?.icon}></i>
                        <span>{item?.label}</span>

                        <div className="_clk_chevron">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5"
                            height="8"
                            viewBox="0 0 5 8"
                            fill="none"
                          >
                            <path
                              d="M4.36632 3.63368L0.968818 0.236182C0.853799 0.13001 0.721084 0.0769238 0.570674 0.0769238C0.420264 0.0769238 0.287549 0.13001 0.172529 0.236182C0.0575098 0.351201 0 0.483916 0 0.634326C0 0.784736 0.0575098 0.917451 0.172529 1.03247L3.17189 4.03183L0.172529 7.03118C0.0575098 7.1462 0 7.27892 0 7.42933C0 7.57974 0.0575098 7.71245 0.172529 7.82747C0.234463 7.8894 0.294185 7.93364 0.351694 7.96019C0.409204 7.98673 0.482197 8 0.570674 8C0.65915 8 0.732144 7.98673 0.789653 7.96019C0.847163 7.93364 0.906885 7.8894 0.968818 7.82747L4.36632 4.42997C4.48134 4.31495 4.53885 4.18224 4.53885 4.03183C4.53885 3.88142 4.48134 3.7487 4.36632 3.63368Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </button>

                      <div
                        id={`${item?.label}${outerIndex}`}
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <h5 className="_clsk_head">{item?.label}</h5>
                        <div className="accordion-body">
                          <ul className="submenuUl">
                            {item?.childs &&
                              item?.childs.map(
                                (child, childIndex) =>
                                  child?.permissions?.view === 1 && (
                                    <li key={`childmenu_${childIndex}`}>
                                      <NavLink
                                        to={child?.link || "#"}
                                        className={({ isActive }) =>
                                          `${isActive ? "active" : ""}`
                                        }
                                        onClick={(event) =>
                                          handleNavLinkClick(
                                            child?.link,
                                            event,
                                            outerIndex
                                          )
                                        }
                                      >
                                        <i className={child?.icon}></i>
                                        <span>{child.label}</span>
                                      </NavLink>
                                    </li>
                                  )
                              )}
                          </ul>
                        </div>
                      </div>
                    </li>
                  )
                );
              }
            })}
        </ul>
      </div>
      {confirmModalShow && (
        <ConfirmModalFor {...confirmModalProps}>
          <>
            <p className="_confirmPrx"> Do you really want to leave this Page </p>
          </>
        </ConfirmModalFor>
      )}
    </aside>
  );
};

export default Sidebar;
