import { API, axiosInstance } from "lib";


export const addIndentApi = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_INDENT , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getMaterialApi = (data = {}) => {
    return axiosInstance
      .post(API.MATERIAL_LIST , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const indentListApi = (data) => {
    return axiosInstance
      .post(API.INDENT_LIST , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        // throw err;
      });
  }



  export const indentCountApi = (data) => {
    return axiosInstance
      .post(API.INDENT_COUNT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  }

  export const getIndentDetailsApi = (data) => {
    return axiosInstance
      .post(API.INDENT_DETAILS_API , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  }

  export const indentUpdateApi = (data) => {
    return axiosInstance
      .post(API.INDENT_UPDATE , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  }

  export const closePlanApi = (data) => {
    return axiosInstance
      .post(API.CLOSE_INDENT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  }

  export const dispatchIndentApi = (data) => {
    return axiosInstance
      .post(API.DISPATCH_INDENT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          return err?.response?.data;
        }
        throw err;
      });
  }
  
  export const updateMarketVisiblityApi = (data) => {
    return axiosInstance
      .post(API.UPDATE_MARKET_VISIBLITY , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          return err?.response?.data;
        }
        throw err;
      });
  }
  
  export const deletePlanApi = (data) => {
    return axiosInstance
      .post(API.DELETE_INTENT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  }


 