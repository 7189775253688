import { ButtonHeading } from "components";
import { QuickUploadModal } from "modals";
import React, { useState } from "react";
import { LiaTruckSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

const QuickUpload = () => {
  
  const navigate = useNavigate();
  const [quickUploadOpen, setQuickUploadOpen] = useState({
    open: false,
    title: "",
  });

  const quickProps = {
    isOpen: quickUploadOpen?.open,
    title: quickUploadOpen?.title,
    closeModal: (e) =>
      setQuickUploadOpen({
        ...quickUploadOpen,
        open: false,
      }),
    headerPart: false,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Quick Upload",
    submitTitle: "Upload",
    cancelButtonTitle: "cancel",
  };

  return (
    <>
      <ButtonHeading
        title="Quick Upload"
        // onClick={{}} buttonTitle="Reports Sharing"
      />

      <div>
        <div className="accordion" id="quickUploadAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Quick Upload
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#quickUploadAccordion"
            >
              <div className="accordion-body">
                <div className="row">
                  {/* <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView' onClick={()=>setQuickUploadOpen({
                            ...quickUploadOpen,
                            open:true,
                            title:"plan"
                          })}>Plan</button>
                          <small className='muted-text' onClick={()=>setQuickUploadOpen({
                            ...quickUploadOpen,
                            open:true,
                            title:"plan"
                          })}>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-sm-4 col-xs-6 col-md-3 col-lg-2">
                    <div className="_quickCards">
                      <div className="_quickCards_body">
                        <div className="text-center">
                          <p className="_fasTruck">
                            <LiaTruckSolid />
                          </p>
                          <button
                            className="btnView"
                            onClick={() =>
                              navigate("/quick_uploads/parle_bill")
                            }
                          >
                            Plan
                          </button>
                          <small className="muted-text">Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-6 col-md-3 col-lg-2">
                    <div className="_quickCards">
                      <div className="_quickCards_body">
                        <div className="text-center">
                          <p className="_fasTruck">
                            <LiaTruckSolid />
                          </p>
                          <button
                            className="btnView"
                            onClick={() =>
                              navigate("/quick_uploads/party_address")
                            }
                          >
                            Party Address
                          </button>
                          <small className="muted-text">Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-6 col-md-3 col-lg-2">
                    <div className="_quickCards">
                      <div className="_quickCards_body">
                        <div className="text-center">
                          <p className="_fasTruck">
                            <LiaTruckSolid />
                          </p>
                          <button
                            className="btnView"
                            onClick={() => navigate("/quick_uploads/fuel_card")}
                          >
                            Fuel Card
                          </button>
                          <small className="muted-text">Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-6 col-md-3 col-lg-2">
                    <div className="_quickCards">
                      <div className="_quickCards_body">
                        <div className="text-center">
                          <p className="_fasTruck">
                            <LiaTruckSolid />
                          </p>
                          <button className="btnView">Parle Bill</button>
                          <small className="muted-text">Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-6 col-md-3 col-lg-2">
                    <div className="_quickCards">
                      <div className="_quickCards_body">
                        <div className="text-center">
                          <p className="_fasTruck">
                            <LiaTruckSolid />
                          </p>
                          <button className="btnView">Parle Bill</button>
                          <small className="muted-text">Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Accordion 2
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#quickUploadAccordion">
              <div className="accordion-body">
              <div className="row">
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Accordion 3
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#quickUploadAccordion">
              <div className="accordion-body">
              <div className="row">
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4 col-xs-6 col-md-3 col-lg-2'>
                    <div className='_quickCards'>
                      <div className='_quickCards_body'>
                        <div className='text-center'>
                          <p className='_fasTruck'><LiaTruckSolid /></p>
                          <button className='btnView'>Parle Bill</button>
                          <small className='muted-text'>Upload</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {quickUploadOpen?.open && <QuickUploadModal {...quickProps} />}
    </>
  );
};

export default QuickUpload;
