import React, { useContext, useState } from "react";
import { CommonModal } from "components";
import { UserContext } from "context";
import { Document, Page } from '@react-pdf/renderer'; // Ensure the correct casing for 'Document' and 'Page'
import { FaFilePdf } from "react-icons/fa6";

const AttachedFileModal = (props) => {
  const { showAttachedModal } = props;
  const { masterData } = useContext(UserContext);
  const [imageUrl, setImageUrl] = useState(showAttachedModal?.data[0].path);

  const propsAttachment = {
    ...props,
  };

  // Function to determine if the file is a PDF
  const isPdfFile = (path) => path?.endsWith(".pdf");

  return (
    <>
      <CommonModal {...propsAttachment}>
        <div className="_attFile_body">
          <div className="__shwAtt_imgBx">
            {/* Display either the PDF or image based on the selected file */}
            {isPdfFile(imageUrl) ? (
              <div className="pdf-viewer">
                {/* Correct casing for Document and Page */}
                <Document file={`${masterData?.s3_url}${imageUrl}`}>
                  {/* Assuming a single page PDF for simplicity */}
                  <Page pageNumber={1} />
                </Document>
              </div>
            ) : (
              <img
                src={`${masterData?.s3_url}${imageUrl}`}
                alt="Attachment"
              />
            )}
          </div>

          <div className="img_fnex_src">
            {showAttachedModal?.data?.length &&
              showAttachedModal?.data?.map((i) => {
                return (
                  <div
                    className="pos-relative"
                    style={{ cursor: "pointer" }}
                    onClick={() => setImageUrl(i?.path)}
                    key={i?._id} // Added a unique key
                  >
                    {isPdfFile(i?.path) ? (
                      // Show PDF icon for PDFs
                      <FaFilePdf size={50} color="#d9534f"  />
                    ) : (
                      // Show image thumbnail for images
                      <img
                        className="_pdfIcon_sigxe"
                        src={`${masterData?.s3_url}${i?.path}`}
                        alt="Image Thumbnail"
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AttachedFileModal;
