// src/services/authService.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, axiosInstance } from 'lib';

export const login = createAsyncThunk(
    '/user/send_otp',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.SEND_OTP, data);
           
            return response.data;
        } catch (err) {
           
            const message =
              err.response?.data?.responseMessage ;
            return rejectWithValue({ message });
        }
    }
);

export const logout = createAsyncThunk('auth/logout',  async (data = {}, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(API.LOGOUT, data);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const OtpVerify = createAsyncThunk(
    'Send verify',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.VERIFY_OTP, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const account = createAsyncThunk(
    'add account',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.CREATE_ADMIN, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addCompany = createAsyncThunk(
    'add Company',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.CREATE_COMAPNY, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const ImageUploadAvtar = createAsyncThunk(
    'image Upload',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.UPLOAD_PROFILE_IMAGE, data,{
                headers: { "Content-Type": "multipart/form-data" },
              });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const allCitiesApi = createAsyncThunk(
    'All Cities',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ALL_CITIES_API, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const allStateApi = createAsyncThunk(
    'All State',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ALL_STATE_API, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const createRouteApi = createAsyncThunk(
    'Create Route',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.CREATE_ROUTE, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);




