// write constant string

export const constant = {
  errorMessage: {
    require: "field is required",
    string: "value must be string",
    email: "please enter valid email",
    password: "Password must be at least 6 alphanumeric characters",
  },
  authModalType: {
    LoginSignUp: "LoginSignUp",
    Login: "Login",
    Signup: "Signup",
    Forgot: "Forgot",
    ForgotConfirmPsw: "ForgotConfirmPsw",
    ForgotOtpVerify: "ForgotOtpVerify",
    Intrest: "Intrest",
    UploadProfile: "UploadProfile",
    OtpVerification: "OtpVerification",
    SelectPersonaModal: "SelectPersonaModal",
    SelectTagsModal: "SelectTagsModal",
  },
  monthList: [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ],
  dayList: [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
  ],
  evenDayList: [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
  ],
  genderArray: [
    { label: "Male", value: "1" },
    { label: "Female", value: "2" },
    // { label: "Other", value: "3" },
  ],
  communityTypeArray: [
    {
      label: "Public",
      value: "1",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      label: "Private",
      value: "2",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    // { label: "Other", value: "3" },
  ],
  followArray: [{ label: "On", value: "on" }],
  privacySafety: [{ label: "Off", value: "off" }],
  tags_PostModal: [
    { label: "twitter +", value: "1" },
    { label: "elon musk +", value: "2" },
    { label: "deadpool +", value: "3" },
    { label: "design +", value: "4" },
    { label: "product +", value: "5" },
    { label: "deadpool +", value: "6" },
    { label: "image +", value: "7" },
  ],
  followingFollowersTabs: [
    {
      label: "Following",
      value: "following",
    },
    { label: "Followers", value: "followers" },
  ],
  Receive_Pay: [
    { label: "To Pay", value: "2" },
    { label: "To Receive", value: "1" },
  ],
  dispatchPermissions: [
    { name: "lr_mandatory", label: "Add LR Mandatory" },
    { name: "eway_bill_mandatory", label: "Add Eway Bill Mandatory" },
    { name: "freight_mandatory", label: "Add Freight Mandatory" },
  ],
  chargeGST: [
    { label: "Forward charge mechanism (FCM)", value: "1" },
    { label: "Reverse charge mechanism (RCM)", value: "2" },
  ],
  listBar: [
    { label: "Party Detail", value: "partydetail" },
    { label: "Lorry Receipt", value: "lr" },
    { label: "Invoice", value: "invoice" },
    { label: "Ledger(Statement)", value: "ledger" },
    { label: "Units", value: "units" },
    { label: "Contract", value: "contact" },
    { label: "Rate", value: "rate" },
    { label: "Settings", value: "settings" },
  ],
  addPartyTabs: [
    { id: 1, title: "Address", value: "address" },
    { id: 2, title: "Tax Info", value: "tax_info" },
    { id: 3, title: "Other Details", value: "other" },
  ],
  addBranchTabs: [
    { id: 1, title: "Address", value: "address" },
    { id: 2, title: "Tax Info", value: "tax_info" },
    { id: 3, title: "Others", value: "ohters" },
  ],
  addOwnDriver: [
    { id: 1, title: "Address", value: "address" },
    { id: 2, title: "Documents", value: "documents" },
  ],
  addMarketDriver: [
    { id: 1, title: "Other", value: "other" },
    { id: 2, title: "Documents", value: "documents" },
  ],
  vehicleOwnership: [
    { label: "Own", value: "1" },
    { label: "Market", value: "2" },
    { label: "Leased", value: "3" },
  ],
  vehicleImgTabs: [
    { id: 1, title: "Address", value: "address" },
    { id: 2, title: "Tax Info", value: "tax_info" },
    { id: 3, title: "Other Details", value: "other" },
  ],
  tripDetailsTabs: [
    { id: 1, title: "Trip Details", value: "trip_details" },
    { id: 2, title: "Freight", value: "freight" },
    { id: 3, title: "Consignor Freight", value: "consignor_freight" },
    { id: 4, title: "Revenue", value: "revenue" },
  ],
  driverTabs: [
    { id: 1, title: "Own Driver", value: "own_driver" },
    { id: 2, title: "Market Driver", value: "market_driver" },
  ],
  dashboardTabs: [
    { id: 1, title: "Dashboard", value: "dashboard" },
    { id: 2, title: "Vehicle Dashboard", value: "vehicle_dashboard" },
  ],
  consignorDetailsTabs: [
    { id: 1, title: "Party Detail", value: "partydetail" },
    { id: 2, title: "Lorry Receipt", value: "lr" },
    { id: 3, title: "Invoice", value: "invoice" },
    { id: 4, title: "Ledger(Statement)", value: "ledger" },
    { id: 5, title: "Units", value: "units" },
    { id: 6, title: "Contract", value: "contact" },
    { id: 7, title: "Rate", value: "rate" },
    { id: 8, title: "Settings", value: "settings" },
    { id: 9, title: "OtherSettings", value: "OtherSetting" },
  ],
  consigneeDetailsTabs: [
    { id: 1, title: "Details", value: "details" },
    { id: 2, title: "Others", value: "others" },
  ],
  supplierDetailsTabs: [
    { id: 1, title: "Supplier Detail", value: "supplier_detail" },
    { id: 2, title: "Trips", value: "trips" },
    { id: 3, title: "Invoice", value: "invoice" },
    { id: 4, title: "Ledger(Statement)", value: "ledger" },
  ],
  unitDetailsTabs: [{ id: 1, title: "Details", value: "details" }],
  driverDetailsTabs: [
    { id: 1, title: "Driver Detail", value: "driver_detail" },
    { id: 2, title: "Ledger(Statement)", value: "ledger" },
  ],
  driverDetailsTabsForMarket: [
    { id: 1, title: "Driver Detail", value: "driver_detail" },
    // { id: 2, title: "Ledger(Statement)", value: "ledger" },
  ],
  paymentDetailsTabs: [
    { id: 1, title: "Pending", value: "pending" },
    { id: 2, title: "Hold", value: "hold" },
    { id: 3, title: "Approved", value: "approved" },
    { id: 4, title: "Rejected", value: "rejected" },
    { id: 5, title: "Completed", value: "completed" },
  ],
  activeArray: {
    0: "InActive",
    1: "Active",
  },
  dueDateArray: [
    { label: "Today", value: "Today" },
    { label: "15 days", value: 15 },
    { label: "30 days", value: 30 },
    { label: "45 days", value: 45 },
    { label: "60 days", value: 60 },
    { label: "75 days", value: 75 },
    { label: "90 days", value: 90 },
    { label: "105 days", value: 105 },
    { label: "120 days", value: 120 },
  ],
  tripDispatchDetailsTabs: [
    { id: 1, title: "All", value: "all" },
    { id: 2, title: "Draft", value: "draft" },
    { id: 3, title: "InTransit", value: "intransit" },
    { id: 4, title: "Delivered", value: "delivered" },
    { id: 5, title: "Cancelled", value: "cancelled" },
  ],
  billRaiseTabs: [
    { id: 1, title: "Total due", value: "total_due" },
    { id: 2, title: "Over due", value: "over_due" },
    { id: 3, title: "Completed", value: "complated" },
  ],
  billRaiseDetailsTabs: [
    { id: 1, title: "Summary", value: "summary" },
    { id: 2, title: "LR", value: "lr" },
  ],
  addAddressBookTabs: [
    { id: 1, title: "Address", value: "address" },
    { id: 2, title: "Other Details", value: "other" },
  ],
  vendersDetailsTabs: [
    { id: 1, title: "Details", value: "details" },
    { id: 2, title: "Ledger(Statement)", value: "ledger" },
  ],
  indentAndBidsDetailTabs: [
    { id: 1, title: "Indent Details", value: "indent_detail" },
    { id: 2, title: "Bids", value: "bids" },
  ],
  bidsStatusTabs: [
    { id: 0, title: "New Bids", value: "new_bids", count: 0 },
    { id: 1, title: "Accepted", value: "accepted_bids", count: 0 },
    { id: 3, title: "Closed", value: "closed_bids", count: 0 },
  ],
  myBidsTabs: [
    { id: 0, title: "Active", value: "active_bids" },
    { id: 1, title: "Accepted", value: "accepted_bids" },
    // { id: 2, title: "Placed", value: "placed_bids" },
    { id: 3, title: "Closed", value: "closed_bids" },
  ],
  // 0 = active, // Pending
  // 1 = accepted, // Accepted
  // 2 = Placed // Rejected
  // 3 = closed // Closed
  myConnectionTabs: [
    { id: 1, title: "All Connections", value: "all_connections" },
    { id: 2, title: "Request", value: "request" },
    { id: 3, title: "Pending", value: "pending" },
    { id: 4, title: "Suggestions", value: "suggestions" },
  ],
  rateTypeObject: {
    1: "Per KOT",
    2: "% Per Invoice",
    3: "Per KG",
    4: "Per QTY",
    5: "Per KMs",
    6: "Flat Rate",
  },
  transactionsDetailsTabs: [
    { id: 1, title: "Transactions", value: 1 },
    { id: 2, title: "Cancelled Transactions", value: 2 },
    { id: 3, title: "Under Approval", value: 3 },
  ],
  manageTripKm: [
    { label: "Mandatory", value: 1 },
    { label: "Non Mandatory", value: 2 },
  ],
  fuelOut_Average: [
    { label: "Editable", value: 1 },
    { label: "Non Editable", value: 2 },
  ],
  fuel_StationDetailsTabs: [
    { id: 1, title: "Details", value: "details" },
    { id: 2, title: "Ledger(Statement)", value: "ledger" },
  ],
  vehicle_DetailsTabs: [
    { id: 1, title: "Documents", value: "documents" },
    { id: 2, title: "Details", value: "details" },
    { id: 3, title: "Fuel Statement", value: "fuel_statement" },
  ],
  addTransactionsTabs: [
    { id: 1, title: "Documents", value: "documents" },
    { id: 2, title: "Details", value: "details" },
    { id: 3, title: "Fuel Statement", value: "fuel_statement" },
  ],

  connectionRatingsTabs: [
    { id: 1, title: "About", value: "about" },
    { id: 2, title: "Reviews", value: "reviews" },
  ],
  fuel_cardTabs: [
    { id: 1, title: "Summary", value: "summary" },
    { id: 2, title: "Transactions", value: "transactions" },
  ],
  ratingArray: [
    { label: "Any", value: "" },
    { label: "5 Star", value: "5" },
    { label: "4+ Star", value: "4" },
    { label: "3+ Star", value: "3" },
    { label: "2+ Star", value: "2" },
    { label: "1+ Star", value: "1" },
  ],
};
