import React, { useState, useEffect, useContext, useRef } from 'react'
import { CommonInput, CommonModal, CustomSelect } from 'components'
import { AddButton } from 'components/common/CommonButton'
import { AddContractModal, ContractDetailsModal } from 'modals'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import StorageService from 'utils/StorageServices/StorageServices'
import { activeInactiveContractStatus, deleteContractData, getContractListData } from '_services/accountsServices/contractServices'
import BootstrapTable from 'components/BootstrapTable'
import { UserContext } from 'context'
import { utils } from 'helper'
import LoadingBar from 'react-top-loading-bar'
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import toast from 'react-hot-toast';

const Contract = () => {

    let { id } = useParams();
    const loadingBarRef = useRef(null)
    const { masterData } = useContext(UserContext)
    const company_id = StorageService.getCompanyId();
    const [show, setShow] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isContractDetails, setIsContractDetails] = useState(false);

    const getListContractData = async (id_1, id_2) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                consignor_id: id_1,
                company_id: id_2,
                limit: 100,
                offset: 1,
                search: ''
            }
            const res = await getContractListData(object);
            if (res?.responseCode == 200) {
                setContactList(res?.data?.contract_list)
            }
        } catch (error) {
            throw error;
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        if (!show) {
            getListContractData(id, company_id);
        }
    }, [show])

    let contractPropsObject = {
        isOpen: show,
        closeModal: () => setShow(!show),
        headerPart: true,
        footerPart: true,
        modalMainClass: "_xlModal",
        modalTitle: show?._id ? "Update Contract" : "Add Contract",
    }

    let contractDetailsProps = {
        isOpen: isContractDetails,
        closeModal: () => setIsContractDetails(!isContractDetails),
        footerPart: true,
        hideFooterSumbmitBtn: true,
        modalMainClass: "_xlModal",
        modalTitle: "Contract Details",
    }

    const handleStatusEvent = async (data, ind) => {
        try {
            const payload = {
                company_id: company_id,
                consignor_id: data?.consignor_id?._id,
                contract_id: data?._id,
                active: data?.active === 1 ? 0 : 1, // active = 1 , inactive = 0
            };

            const res = await activeInactiveContractStatus(payload);
            if (res?.responseCode === 200) {
                getListContractData(id, company_id);
                // let list = [...contactList];
                // list.splice(ind, 1, { ...list[ind], active: data?.active === 1 ? 0 : 1 })
                // setContactList(list);
            } else {
                toast.error(`Invalid Request !!`);
            }
        } catch (error) {
            toast.error(`Invalid Request !!`);
        }
    };

    const deleteHandler = (item) => {
        setIsDelete(item)
    }

    const submitDeleteContract = async () => {
        setLoading(true);
        try {
            let object = {
                company_id: isDelete?.company_id,
                contract_id: isDelete?._id,
                consignor_id: isDelete?.consignor_id?._id,
            }
            const res = await deleteContractData(object);
            if (res?.responseCode == 200) {
                toast.success(`${res?.responseMessage}`);
                getListContractData(id, company_id);
                setLoading(false);
                setIsDelete(false);
            } else {
                toast.success('Error !! ');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    let contractDeleteProps = {
        isOpen: isDelete,
        closeModal: setIsDelete,
        modalTitle: "Confirmation",
        submitTitle: "Confirm",
        cancleTitle: "Close",
        onSubmit: submitDeleteContract,
        isLoadding: loading,
    }

    const column = [
        { header: "", accessor: "serial" },
        { header: "Contract No.", accessor: "contract_no" },
        { header: "Contract Name", accessor: "contract_name" },
        { header: "Party Name", accessor: "party_name" },
        { header: "Chargable On", accessor: "chargable_on" },
        { header: "Start Date", accessor: "start_date" },
        { header: "End Date", accessor: "end_date" },
        { header: "Status", accessor: "status" },
    ];

    const rowDataList = (data) => {
        const listArray = data?.map((item, i) => ({
            ...item,
            serial: <>
                <div className="signDropdown">
                    <button className="dropbtn11">
                        <BsThreeDotsVertical />
                    </button>
                    <div className="dropdownContent">

                        <Link
                            className='_avPointer'
                            onClick={() => setIsContractDetails(item)}
                            style={{ textDecoration: 'none' }}
                        >
                            <span className='_samAnchor'>
                                <span className='viewIcon_avt'>
                                    <AiOutlineEye />
                                </span>View
                            </span>
                        </Link>

                        <div
                            onClick={(e) => setShow(item)}
                            className='_avPointer'
                        >
                            <span className='_samAnchor'>
                                <span className='editIcon_avt'>
                                    <FiEdit />
                                </span>
                                Edit
                            </span>
                        </div>
                        {(item?.name !== 'Default' && item?.active !== 1) ?
                            <div
                                onClick={(e) => deleteHandler(item)}
                                className='_avPointer'
                            >
                                <span className='_samAnchor'>
                                    <span className='deleteIcon_avt'>
                                        <FaRegTrashCan color='red' />
                                    </span>
                                    Delete
                                </span>
                            </div> : <></>}
                    </div>
                </div>
            </>,
            contract_no: (
                <>
                    <Link onClick={() => setIsContractDetails(item)} style={{ textDecoration: 'none' }}>{item?.code}</Link>
                </>
            ),
            contract_name: <>{item?.name}</>,
            party_name: (
                <>
                    <div className="fBold">{item?.consignor_id?.name ? item?.consignor_id?.name : ''}</div>
                </>
            ),
            chargable_on: (
                <>
                    <div className="fBold">{masterData?.contract_chargeable_on_list?.length > 0 ? masterData?.contract_chargeable_on_list?.filter((el) => (el?.id === item?.chargeable_on) ? el : '')[0]?.name : '-'}</div>
                </>
            ),
            start_date: (
                <>
                    <div className="fBold">{item?.start_date ? utils.formateDateCalander(item?.start_date) : ''}</div>
                </>
            ),
            end_date: (
                <>
                    <div className="fBold">{item?.end_date ? utils.formateDateCalander(item?.end_date) : ''}</div>
                </>
            ),
            status: <>
                <div
                    className="_anvSwitcherNew form-check form-switch"
                    style={{ width: "125px" }}
                >
                    <input
                        className="form-check-input _pi_ter"
                        type="checkbox"
                        name="actStatus"
                        id={`actStatus-${item._id}`}
                        checked={item?.active === 1 ? true : false}
                        onChange={(e) => handleStatusEvent(item, i)}
                        disabled={item?.active === 1 ? true : false}
                    />
                    <span className={item?.active === 1 ? 'green_status' : 'red_status'}>
                        {item?.active === 1 ? "Active" : "In Active"}
                    </span>
                </div></>,
        }))
        return listArray;
    };

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <div className="contentHead">
                <div className='d-flex pt-3 pb-3 gap-3'>
                    <div>
                        <CustomSelect
                            placeholder='All Status'
                            options={[
                                { label: 'Test 1', value: 1 },
                                { label: 'Test 2', value: 2 },
                            ]}
                        />
                    </div>
                    <div>
                        <CommonInput
                            placeholder="Search"
                            label="Search"
                        />
                    </div>
                </div>
                <div className='_rSideBtn'>
                    <AddButton onClick={(e) => setShow(!show)} name='Add Contract' />
                </div>
            </div>

            <BootstrapTable
                columns={column}
                data={rowDataList(
                    contactList?.length
                        ? contactList
                        : []
                )}
            />

            {show && <AddContractModal {...contractPropsObject} />}

            {isContractDetails && <ContractDetailsModal {...contractDetailsProps} />}

            {
                isDelete?.company_id && (
                    <CommonModal {...contractDeleteProps}>
                        <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
                    </CommonModal>
                )
            }
        </div >
    )
}

export default Contract