import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

// Set the worker source globally
GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';




const PdfViewer = ({ fileUrl , width = 500, height = 500}) => {
  const [pdf, setPdf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const canvasRef = useRef(null);

console.log(fileUrl)

  useEffect(() => {
    const loadPdf = async () => {
      if (!fileUrl) {
        setError('No file URL provided');
        setLoading(false);
        return;
      }

      try {
        const loadingTask = getDocument(fileUrl);
        const pdfDocument = await loadingTask.promise;
        setPdf(pdfDocument);
        setLoading(false);
        renderPage(1); // Render the first page
      } catch (error) {
        setLoading(false);
        setError('Error loading PDF');
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, [fileUrl]);

  const renderPage = (pageNumber) => {
    if (pdf) {
      pdf.getPage(pageNumber).then((page) => {
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({ canvasContext: context, viewport });
      }).catch((err) => {
        setError('Error rendering page');
        console.error('Error rendering page:', err);
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default PdfViewer;
