import { API, axiosInstance } from "lib";

export const getAddressBookListData = (data={}) => {
    return axiosInstance
      .post(API.GET_ADDRESS_BOOK , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };
  
export const createAddressBook = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_ADDRESS_BOOK , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteAddressBook = (data={}) => {
  return axiosInstance
    .post(API.DELETE_ADDRESS_BOOK , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getAddressBookDetails = (data={}) => {
  return axiosInstance
    .post(API.GET_SINGLE_SUPPLIER , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};
// export const changeSupplierStatusData = (data={}) => {
//   return axiosInstance
//     .post(API.CHANGE_SUPPLIER_STATUS , data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       if (err) {
//         console.log(err);
//       }
//       throw err;
//     });
// };

export const getPartyAssociateApi = (data={}) => {
    return axiosInstance
      .post(API.GET_ADDRESS_ASSOCIATE , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const getAddressDetailsApi = (data={}) => {
    return axiosInstance
      .post(API.GET_ADDTESS_BOOK_DETAILS , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const updateAddressBookApi = (data={}) => {
    return axiosInstance
      .post(API.UPDATE_ADDRESS , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const createUnitAdd = (data = {}) => {
    return axiosInstance
      .post(API.CREATE_UNIT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const createConsigneeAdd = (data = {}) => {
    return axiosInstance
      .post(API.CREATE_CONSIGNEE , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };